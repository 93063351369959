<template>
    <section class="page-title">
      <div class="grid-container narrow">
        <h2 class="headline3">{{pageTitle}}</h2>
        <p>{{pageDescription}}</p>
      </div>
    </section>
</template>
<script>
export default {
    name: 'PageTitle',
    props: ['pageTitle', 'pageDescription'],
    components: {
        //SvgIcon
    },
    data() {
        return {

        }
    },
    mounted() {

    },
    methods: {

    }
}
</script>
<style lang="scss">
.page-title{
  background: linear-gradient(61deg, #00205B 13.22%, #753BBD 35.29%, #00A9CE 86.78%);
  height: 166px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  color: white;
}
</style>