var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[!_vm.isMobile ? 'grid-container no-padding' : ''],style:([
    _vm.isMobile ? { 'margin-top': '20px' } : null,
  ])},[_c('div',{staticClass:"specialoffer specialoffer-content",style:({background: _vm.specialOffer.background })},_vm._l((_vm.specialOffer.cards),function(card,i){return _c('div',{key:i,staticClass:"specialoffer-content--cards",class:[!card.image && card.background],style:([
        card.image && !_vm.isMobile
          ? {
              background: 'url(' + _vm.path+card.image + ')',
              position: 'relative',
            }
          : card.mobileimage && _vm.isMobile
          ? {
              background: 'url(' + _vm.path+card.mobileimage + ')',
              backgroundPosition: 'right',
              backgroundSize: 'cover',
            }
          : null,
      ])},[(card.image)?_c('div',{staticClass:"specialoffer-content--cards__imageContent"},[_c('div',{staticClass:"specialoffer-content--cards__imageContent-container"},[_c('div',{staticClass:"specialoffer-content--cards__imageContent-title",style:({ color: card.textColor })},[_vm._v(" "+_vm._s(card.title)+" ")]),(card.first3characterbackground)?_c('div',{staticClass:"specialoffer-content--cards__imageContent-subtitle",style:({ color: card.textColor })},[_c('span',{staticClass:"first3character",class:card.first3characterbackgroundColor,style:({ color: card.first3characterTextColor })},[_vm._v(" "+_vm._s(card.first3character)+" ")]),_vm._v(" "+_vm._s(card.subtitle)+" ")]):_c('div',{staticClass:"specialoffer-content--cards__imageContent-subtitle",style:({ color: card.textColor })},[_vm._v(" "+_vm._s(card.subtitle)+" ")]),_c('div',{staticClass:"specialoffer-content--cards__imageContent-description",style:({ color: card.textColor })},[_vm._v(" "+_vm._s(card.description)+" ")])]),(card.buttonText)?_c('a',{staticClass:"specialoffer-content--cards__imageContent-button",class:{'btn':true ,'white': card.buttonColor === 'white-gradient' ? true : false},attrs:{"href":card.buttonURL}},[_c('span',{staticClass:"specialoffer-content--cards__imageContent-button__text",style:({ color: card.buttonTextColor })},[_vm._v(" "+_vm._s(card.buttonText)+" ")])]):_vm._e()]):_c('div',{staticClass:"specialoffer-content--cards__logoContainer"},[_c('img',{style:([
            _vm.isMobile
              ? { height: '145px', width: '140px' }
              : { height: '185px', width: '180px' },
            _vm.isArabic && _vm.isMobile ? { left: '20px' } : { right: '40px' },
          ]),attrs:{"src":_vm.path+card.logo,"alt":card.title}}),_c('div',{staticClass:"specialoffer-content--cards__logoContainer--content"},[_c('div',{staticClass:"specialoffer-content--cards__logoContainer--content-container"},[_c('div',{staticClass:"specialoffer-content--cards__logoContainer--content-title",style:({ color: card.textColor })},[_vm._v(" "+_vm._s(card.title)+" ")]),(card.first3characterbackground)?_c('div',{staticClass:"specialoffer-content--cards__logoContainer--content-subtitle",style:({ color: card.textColor })},[_c('span',{staticClass:"first3character",class:card.first3characterbackgroundColor,style:({ color: card.first3characterTextColor })},[_vm._v(" "+_vm._s(card.first3character)+" ")]),_vm._v(" "+_vm._s(card.subtitle)+" ")]):_c('div',{staticClass:"specialoffer-content--cards__logoContainer--content-subtitle",style:({ color: card.textColor })},[_vm._v(" "+_vm._s(card.subtitle)+" ")]),_c('div',{staticClass:"specialoffer-content--cards__logoContainer--content-description",style:({ color: card.textColor })},[_vm._v(" "+_vm._s(card.description)+" ")])]),(card.buttonText)?_c('a',{staticClass:"specialoffer-content--cards__logoContainer--content-button",class:[card.buttonColor],attrs:{"href":card.buttonURL}},[_c('span',{staticClass:"specialoffer-content--cards__logoContainer--content-button__text",style:({ color: card.buttonTextColor })},[_vm._v(" "+_vm._s(card.buttonText)+" ")])]):_vm._e()])])])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }