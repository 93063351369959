<template>
    <div class="spotlight">
        <template v-if="spotlightData.data">
            <slick id="slick-spotlight" :options="slickOptions" v-if="spotlightData.data" @lazyLoaded="handleLazyLoaded"  @swipe="handleSwipe">
                <div class="spotlight-item" v-for="(spot, i) in spotlightData.data.slice(0,4)" :key="i+'key'" :data-text-color="spot.textDesktopColor" :data-text-color-m="spot.textMobileColor" :data-position-small="spot.textMobilePosition" :data-border="spot.borderColor" :data-border-mobile="spot.borderColorMobile" :data-box="spot.gradient" @mouseover="hover = true" @mouseleave="hover = false">
                    <img class="spotlight-image show-for-medium" :data-lazy="windowWidth > 767 ? path+spot.backgroundImage : '' " :alt="spot.title" />
                    <img class="spotlight-image hide-for-medium" :data-lazy="windowWidth < 767 ? path+spot.backgroundImageMobile : '' " :alt="spot.title" />
                    <div class="grid-container">
                        <div class="online-exclusive show-for-medium" v-if="spot.onlineExclusiveStamp" :style="{ backgroundImage: 'url(' + path+spot.onlineExclusiveStamp.desktop + ')' }">
                        </div>
                        <div class="online-exclusive hide-for-medium" v-if="spot.onlineExclusiveStamp" :style="{ backgroundImage: 'url(' + path+spot.onlineExclusiveStamp.mobile+ ')' }">
                        </div>
                        <div class="spotlight-item-content">
                            <a :href="spot.buttonLink" :aria-label="spot.buttonTitle" class="spotlight-link" @click="sendGA(spot.ga.banner.eventCategory , spot.ga.banner.eventAction, spot.ga.banner.eventLabel, spot.ga.banner.eventValue)" v-if="spot.buttonLink.length > 0 &&  spot.buttonLink != '#'"></a>
                            <div class="spotlight-item-content-inner">
                                <div :class="'online-exclusive-badge ' + spot.onlineExclusiveBadge.backgroundColor" v-if="spot.onlineExclusiveBadge">
                                    <span class="svg-bg-icon" v-lazy:background-image="path+spriteURL + spot.onlineExclusiveBadge.icon + '-usage'"></span>
                                    <p>{{spot.onlineExclusiveBadge.title}}</p>
                                </div>
                                <p class="shoulder">{{spot.topHeader}}</p>

                                <template v-if="spot.tag">
                                   <div :class="'ducares-tag ' + spot.tag.backgroundColor">
                                        <span class="svg-bg-icon" v-lazy:background-image="path+spriteURL  + spot.tag.icon + '-usage'"></span>
                                    </div>
                                </template>

                                <img class="spotlight-promoImg" v-if="spot.promoImage" :src="path+spot.promoImage.img" :style="'height:'+spot.promoImage.height+'px;'">
                                

                                <p class="headline1" v-html="spot.title"></p>
                                <p class="subtitle1" v-html="spot.subtitle"></p>
                                <div class="action">
                                    <a :href="spot.buttonLink" @click="sendGA(spot.ga.banner.eventCategory , spot.ga.banner.eventAction, spot.ga.banner.eventLabel, spot.ga.banner.eventValue)" :class="{'btn':true ,'white': spot.buttonColor === 'white' ? true : false}">{{spot.buttonTitle}}</a>
                                    <div v-if="spot.promoCode" class="spotlight-promo">
                                        <fieldset>
                                            <legend>{{spot.promoCode.name}}</legend>
                                            {{spot.promoCode.code}}
                                        </fieldset>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </slick>
            <div class="spotlight-thumb" :data-timing="spotlightData.autoplaySpeed">
                <div class="spotlight-thumb-inner">
                    <div class="container">
                        <slick id="slick-thumb" :options="slickOptions2" v-if="spotlightData.data.length">
                            <div v-for="(spot, i) in spotlightData.data.slice(0,4)" :class="{'spotlight-thumb-item': true}" :key="i+'thumbS'" @click="sendGA(spot.ga.thumb.eventCategory , spot.ga.thumb.eventAction, spot.ga.thumb.eventLabel, spot.ga.thumb.eventValue)">
                                <p class="carousel-thumb-title">{{spot.spotlightTitle}}</p>
                                <p class="carousel-thumb-subtitle">{{spot.spotlightDescription}}</p>
                                <div :class="{'spotlight-timer': true, 'hover': hover, 'animation': !hover}"></div>
                            </div>
                        </slick>
                    </div>
                </div>
            </div>            
        </template>
        <template v-else>
            <div class="spotlight-item" data-text-color="white"></div>
            <div class="spotlight-thumb">
                <div class="spotlight-thumb-inner">
                    <div class="container">
                    </div>
                </div>
            </div>    
        </template>
    </div>
</template>
<script>
import Slick from 'vue-slick';
import api from '@/api'
import '../../../node_modules/slick-carousel/slick/slick.css';
import { Analytics } from "@/analytics.js";

//SVGs
const requireAll = requireContext => requireContext.keys().map(requireContext)
const req = require.context('../', true, /\.svg$/);
requireAll(req)

export default {
    components: { Slick },
    name: 'Spotlight',
    props:{
        path: {
            type: String,
            default: process.env.VUE_APP_PATH
        }
    },
    data() {
        return {
            spotlightData: [],
            windowWidth: 0,
            hover: false,
            spriteURL: process.env.BASE_URL + "img/svg-sprite-icons.svg#icon-"
        }
    },
    computed: {
        slickOptions() {
            return {
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
                fade: true,
                asNavFor: "#slick-thumb",
                autoplay: true,
                autoplaySpeed: this.spotlightData.autoplaySpeed,
                pauseOnHover: true,
                lazyLoad: 'ondemand',
                rtl: document.getElementsByTagName('html')[0].getAttribute('dir') === 'rtl' ? true : false
            }
        },
        slickOptions2() {
            return {
                slidesToShow: 4,
                slidesToScroll: 1,
                dots: false,
                arrows: false,
                infinite: true,
                speed: 500,
                cssEase: "linear",
                focusOnSelect: true,
                asNavFor: "#slick-spotlight",
                pauseOnHover: true,
                rtl: document.getElementsByTagName('html')[0].getAttribute('dir') === 'rtl' ? true : false,
                responsive: [{
                        breakpoint: 1025,
                        settings: {
                            slidesToShow: 3,
                        }
                    },
                    {
                        breakpoint: 767,
                        settings: {
                            slidesToShow: 2,
                        }
                    }
                ]
            }
        }
    },
    mounted() {
        
        api.spotlight().then(res => { this.spotlightData = res })

        this.$nextTick(function() {
            window.addEventListener('resize', this.getWindowWidth, { passive: true });
            this.getWindowWidth()
        })
    },
    methods: {
        sendGA(eventCategory, eventAction, eventLabel, eventValue) {
            Analytics(eventCategory, eventAction, eventLabel, eventValue);
        },

        handleLazyLoaded(event, slick, image) {
            image[0].classList.add('loaded');
        },
        handleSwipe(){
            this.sendGA("Navigation swipe", "Banners", "Hero banner")
        },

        getWindowWidth() {
            var imgMob = document.querySelectorAll(".slick-current.slick-active .spotlight-image.hide-for-medium");
            var imgDsk = document.querySelectorAll(".slick-current.slick-active .spotlight-image.show-for-medium");

            this.windowWidth = document.documentElement.clientWidth;

            if (this.windowWidth > 691) {
                if ((imgDsk.length && !imgDsk[0].src) || (imgDsk.length && imgDsk[0].src === undefined)) {
                    setTimeout(function() {
                        imgDsk[0].src = imgDsk[0].dataset.lazy;
                    }, 500)
                }
            } else {
                if ((imgMob.length && !imgMob[0].src) || (imgMob.length && imgMob[0].src === undefined)) {
                    setTimeout(function() {
                        imgMob[0].src = imgMob[0].dataset.lazy;
                    }, 500)
                }

            }

        }
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.getWindowWidth);
    }
}
</script>
<style lang="scss">
.spotlight-thumb {
    .slick-list {
        padding-top: 20px;
    }
}

.spotlight {

    .slick-slider,
    .slick-list,
    .slick-track,
    .slick-slide>div,
    .spotlight-item {
        height: 100%;
    }

    .slick-list {
        overflow: hidden !important;
    }
}
</style>
<style scoped lang="scss">
.spotlight {
    position: relative;
    min-height: 530px;
    height: 530px;
    overflow: hidden;

    @media only screen and (max-width: $phone-max-size) {
        height: 460px;
        min-height: 460px;
    }

    &-promoImg {
        margin-bottom: 10px;
    }


    .container {
        max-width: 1266px;
        margin: 0 auto;
    }

    .action {
        display: flex;
        flex-wrap: wrap;
    }

    &-link {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
    }

    &-promo {
        display: inline-block;

        @media only screen and (max-width: 410px) {
            flex-basis: 100%;
        }


        fieldset {
            legend {
                display: block;
                height: 0;
                position: relative;
                top: -8px;
                margin: 0;
                line-height: 1.4;
                padding: 0 6px;
                font-size: 0.8667rem;
                font-family: $du-font-secondary-regular;

                [dir="rtl"] & {
                    font-family: $du-font-secondary-regular-ar;
                }
            }

            border: dashed #D8D8D8 1px;
            border-radius: 2px;
            display: inline-block;
            vertical-align: top;
            color: $white;
            padding: 0 15px;
            line-height: 2.8;
            font-family: $du-font-secondary-bold;
            font-size: 1.2rem;
            margin-left: 20px;

            @media only screen and (max-width: $phone-max-size) {
                line-height: 2.6;
            }

            @media only screen and (max-width: 410px) {
                margin-left: 0;
                margin-top: 30px;

                [dir="rtl"] & {
                    margin-right: 0;
                }
            }


            [dir="rtl"] & {
                margin-left: 0;
                margin-right: 20px;
                font-family: $du-font-secondary-bold-ar;
            }
        }
    }

    &-item {
        height: 100%;
        width: 100%;
        display: flex;
        overflow: hidden;

        .grid-container {
            display: flex;
            height: 100%;
            align-items: center;
            padding-bottom: 80px;
            position: relative;

            @media only screen and (max-width: $phone-max-size) {
                align-items: center;
                align-items: flex-end;
                max-height: 500px;
                padding: 15px 15px 76px 15px;

            }

        }

        .subtitle1 {
            margin-bottom: 13px;
            font-family: $du-font-secondary-regular;

            [dir="rtl"] & {
                font-family: $du-font-secondary-regular-ar;
            }

            @media only screen and (min-width: $phone-max-size) {
                margin-bottom: 22px;

            }
        }

        &-content {
            border-radius: 16px;
            color: $white;
            display: flex;
            flex-direction: column;
            justify-content: center;
            opacity: 0;
            transform: translateX(50px);
            transition: all .5s ease-in-out 0ms;
            transition-delay: .3s;
            width: 100%;

            @media only screen and (max-width:$tablet-max-size) {
                padding: 10px 20px 20px;
            }

            @media only screen and (max-width: $phone-max-size) {
                padding: 15px;
                height: 100%;
                display: flex;
            }

            @media only screen and (min-width:$tablet-max-size) {
                border: 3px solid white;
                padding: 50px;
                max-width: 1266px;
                height: 380px;
                border-radius: 10px;
            }

            [dir="rtl"] & {
                transform: translateX(-50px);
            }

            &-inner {
                @media only screen and (min-width: $tablet-max-size) {
                    max-width: 580px;
                }
            }


        }
    }

    .online-exclusive {
        transform: translateX(50px);
        transition: all .5s ease-in-out 0ms;
        transition-delay: .3s;

        [dir="rtl"] & {
            transform: translateX(-50px);
        }
    }

    &-image {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 0;
        bottom: 0;
        z-index: -1;
        background-size: auto;
        background-position: center center;
        background-repeat: no-repeat;
        max-width: 1920px;
        width: auto;
        height: auto;
        transition: .3s ease-in-out;
        object-fit: cover;
        background-color: $du-grey-70;
        opacity: 1;
        z-index: 0;

        &.slick-lazyload-error,
        &.slick-loading {
            background-color: $du-grey-30;
            width: 100%;
            height: 100%;
        }

        @media only screen and (max-width: $phone-max-size) {
            // background-size: cover;

            width: 100%;
            height: 100%;
        }

        &.slick-loading {
            opacity: .4;
        }
    }

    &-thumb {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        cursor: pointer;
        height: 81px;

        @media only screen and (min-width: $phone-max-size) {
            height: 91px;
        }

        &:before {
            position: absolute;
            content: "";
            left: 0;
            right: 0;
            bottom: 0;
            top: 20px;
            background-color: rgba(238, 238, 238, .7);

            @media only screen and (min-width: $phone-max-size) {
                border-radius: 8px 8px 0 0;
            }
        }

        .container {
            padding: 0;
        }

        &-inner {
            background-color: transparent;
            overflow: visible;
            padding: 0;
        }

        &-item {
            padding: 10px 17px;

            @media only screen and (min-width: $phone-max-size) {
                padding: 17px 30px 13px;
            }

            p {
                margin-bottom: 0;
            }
        }


        &-card {
            background-color: transparent;
        }

        &-content {
            padding: 20px 30px;

        }

        .swiper-container {
            background-color: rgba(238, 238, 238, 0);
            padding: 0;

            p {
                color: black;
                margin-bottom: 0;
            }
        }

        .spotlight-thumb-item {
            &:before {
                position: absolute;
                content: "";
                top: -15px;
                left: 50%;
                transform: translate(-50%);
                border-left: 15px solid transparent;
                border-right: 15px solid transparent;
                border-bottom: 15px solid transparent;
                transition: .3s ease-in-out;
            }
        }


        .slick-active.slick-current {

            .spotlight-thumb-item {
                background-color: $white;
                box-shadow: 0 -3px 20px 0 rgba(0, 0, 0, 0.1);
                border-radius: 2px 2px 0 0;
                position: relative;

                @media only screen and (min-width: $phone-max-size) {
                    box-shadow: 0px -5px 14px 2px rgba(0, 0, 0, 0.15);
                }

                &:before {
                    border-bottom: 15px solid white;
                }




                .spotlight-timer {
                    height: 2px;
                    width: 0%;
                    position: absolute;
                    bottom: 0;
                    background-image: linear-gradient(224deg, #753BBD 0%, #C724B1 74%);

                    &.animation {
                        animation-name: increase;
                        animation-fill-mode: forwards;
                        animation-timing-function: linear;
                    }

                    &.hover {
                        animation-play-state: paused;
                    }

                    [dir="ltr"] & {
                        left: 0;

                    }

                    [dir="rtl"] & {
                        right: 0;
                        animation-duration: 8s;
                    }

                }

                @keyframes increase {
                    100% {
                        width: 100%;
                    }
                }
            }
        }
    }

    .slick-active.slick-current {

        .spotlight-item-content,
        .online-exclusive {
            opacity: 1;
            transform: translateX(0px);
        }
    }

    .carousel-thumb-title {
        font-size: $font-desktop-body-S;
        font-family: $du-font-secondary-bold;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        [dir="rtl"] & {
            font-family: $du-font-secondary-bold-ar;
        }
    }

    .carousel-thumb-subtitle {
        font-size: $font-desktop-body-XS;
        color: $du-grey-70;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

}
</style>
