import Vue from 'vue'
import SupportCategory from './SupportCategory.vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueLazyload from 'vue-lazyload'
import Sprite from 'svg-sprite-loader/runtime/sprite.build'; 

import "@/navigation.js"
import "@/accessibility.js"

Vue.use(Sprite, VueAxios, axios)

Vue.use(VueLazyload, {
  lazyComponent: true
});

Vue.config.productionTip = false

new Vue({
    render: h => h(SupportCategory)
}).$mount('#appHelp')