<template>
<div>
    <div class="body">
        <PageTitle :page-title="pageTitle" :page-description="pageDescription" />
        <ComplaintTopicList :content="contentMisc.helpTopics" />
        <Footer />
    </div>
</div>
</template>
<script>

import ComplaintTopicList from '../components/Complaint/ComplaintTopicList';
import PageTitle from '../components/PageTitle';
import Footer from '../components/Footer';
// import Navigation from '../components/Navigation'; //Enable for dev
import apiHelp from '../api-help'

export default {
    name: 'Complaint',
    data() {
        return {
            contentMisc: [],
            pageTitle: document.getElementsByTagName('html')[0].getAttribute("dir") == 'rtl' ? "شكاوي":"Complaints",
            pageDescription: document.getElementsByTagName('html')[0].getAttribute("dir") == 'rtl' ? "اعثر على مقالات مفيدة حول المشكلات التي تواجهها وتتبع شكاويك.":"Find helpful articles about the issues you are facing and track your complaints."
        }
    },    
    mounted() {
        apiHelp.contentMisc().then(res => { this.contentMisc = res });

    },
    components: {
        PageTitle,
        ComplaintTopicList,
        Footer
    }
};
</script>
<style lang="scss">
.complaintTopics-box.img {
  padding: 0;
  -webkit-transition: 0.3s ease-out;
  transition: 0.3s ease-out;
  width: 100%;
  height: 100px;
  margin-bottom: 20px;
  border-radius: 6px;
}
.has-critical {
    top: 55px!important;
    @media only screen and (min-width: $laptop-min-size) {
        top: 32px!important;
    }
}
.banner-search{
    &.is-lower{
      @media only screen and (max-width: $phone-max-size){
        padding: 47px 0;
      }
    }
}
</style>