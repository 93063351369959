var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[!_vm.isMobile ? 'grid-container no-padding' : ''],style:([
    _vm.isMobile ? { 'margin-top': '20px' } : null,
  ])},[_c('div',{staticClass:"quickLinks text-center quickLinks-content",style:([
    _vm.isMobile
      ? { background: _vm.quickLinksData.mobileBackground }
      : { background: _vm.quickLinksData.background }
  ])},[(_vm.quickLinksData.title)?_c('div',{staticClass:"quickLinks_title show-for-medium"},[_vm._v(" "+_vm._s(_vm.quickLinksData.title)+" ")]):_vm._e(),(_vm.quickLinksData.titleMobile)?_c('div',{staticClass:"quickLinks_title hide-for-medium text-center"},[_vm._v(" "+_vm._s(_vm.quickLinksData.titleMobile)+" "+_vm._s(_vm.quickLinksData.titleMobile2)+" ")]):_vm._e(),_c('div',{staticClass:"quickLinks_iconcontainer"},_vm._l((_vm.quickLinksData.content),function(link,i){return _c('div',{key:i,staticClass:"quickLinks_iconcontainer-content"},[_c('a',{staticClass:"quickLinks_iconcontainer-product",attrs:{"href":link.url,"aria-label":link.title},on:{"click":function($event){return _vm.sendGA(
                link.ga.eventCategory,
                link.ga.eventAction,
                link.ga.eventLabel,
                link.ga.eventValue
              )}}},[_c('img',{attrs:{"src":_vm.path+link.svg.name,"alt":link.svg.name,"width":_vm.isMobile ? 32 : 48,"height":_vm.isMobile ? 32 : 48}}),_c('span',{staticClass:"quickLinks_iconcontainer-product-title"},[_vm._v(_vm._s(link.title))])])])}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }