<template>
  <div class="tt-form form-new" :class="{'tt-form-sidebar' : this.isSideBar}">
    <p :class="{ headline2: !this.isSideBar, headline5: this.isSideBar }">{{ this.isSideBar?  ttForm.titleShort: ttForm.title }}</p>
    <p v-if="!this.isSideBar">{{ ttForm.subTitle }}</p>
    <div>
      <form id="form" method="get" :action="isRTL() ? 'https://myaccount.du.ae/servlet/myaccount/ar/login_using_otp.html':'https://myaccount.du.ae/servlet/myaccount/en/login_using_otp.html'">
        <div class="du-form">
          <fieldset class="group">
            <input type="text" name="msisdn" class="control txt1" placeholder="" @keyup="validateTTForm($event)"  required />
            <label>{{ ttForm.fieldLabel }}</label>
          </fieldset>
          <fieldset class="group action">
            <input type="hidden" name="sLink" value="mya-track-complaints.html" />
            <input type="hidden" name="utm_source" value="help" />
            <input type="hidden" name="utm_medium" :value="this.isSideBar ? 'MViewTT' : 'AViewTT'" />
            <input class="btn btn-filled disabled" @click="submitTT($event)" type="button" :value="this.ttForm.btnText">
          </fieldset>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import { Analytics } from "@/analytics.js"
export default {
  name: 'TroubleTicketForm',
  props: ['isSideBar'],
  data() {
    return {
      ttForm: {
        title: document.getElementsByTagName('html')[0].getAttribute('dir') === 'rtl' ? "كيف يمكنني متابعة حالة طلب الدعم من خلال موقعنا ؟" : "How can I check the status of my complaint?",
        titleShort: document.getElementsByTagName('html')[0].getAttribute('dir') === 'rtl' ? "تتبع حالة طلب الدعم الخاصه بك" : "Track your complaint",
        subTitle: document.getElementsByTagName('html')[0].getAttribute('dir') === 'rtl' ? "عرض وتتبع حالة طلب الدعم الخاصة بك ." : "View and track the status of your complaint online.",
        fieldLabel: document.getElementsByTagName('html')[0].getAttribute('dir') === 'rtl' ? "رقم هاتفك أو حسابك" : "Your phone or account number",
        btnText: document.getElementsByTagName('html')[0].getAttribute('dir') === 'rtl' ? "متابعة" : "Track now"
      }
    }
  },
  mounted() {
  },
  methods: {
    sendGA(eventCategory, eventAction, eventLabel, eventValue) {
      Analytics(eventCategory, eventAction, eventLabel, eventValue);
    },
    validateTTForm(e) {
      let $form = document.querySelector('.tt-form');
      let reg = /^(([0-9][.]([0-9]|[0-9][.][0-9])+)|((9715)|(\+9715)|(05))[0-9]{8}|((9715)|(05))[0-9]{8}|((97183)|(083))[0-9]{10})$/g;
      let val = e.currentTarget.value;


      if (val.match(reg) != null) {
        $form.querySelector('.btn').classList.remove('disabled');
      } else {
        $form.querySelector('.btn').classList.add('disabled');
      }
    },
    isRTL: function() {
      return document.getElementsByTagName('html')[0].getAttribute("dir") == 'rtl';
    },
    submitTT(e) {
      if (e.target.classList.contains('disabled')) {
        return false;
      }

      // console.log(this.isSideBar);
      let $form = document.querySelector('.tt-form');

      this.sendGA(this.isSideBar? 'Main Help & Support' : 'Trouble Ticket Article', 'Track your Trouble Ticket', 'Next', 0);
      $form.querySelector('form').submit();
    },

  }
}
</script>
<style lang="scss">

</style>