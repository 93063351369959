<template>

    <div class="card">
        <div class="grid-y">
            <div class="cardBgImg" :style="'background-image: url(' + backgroundImage +')'"></div>
            <img class="cardIcon" :src="icon" alt="Support Icon"/>
            <h3>{{ title }}</h3>
            <p v-html="description"></p>
            <a :href="url" class="btn white">{{ btnText }}</a>
        </div>
    </div>

</template>
<script>
// import apiHelp from '../../api-help'
// import { Analytics } from "@/analytics.js";


export default {
    name: 'supportCard',
    data() {
        return {}
    },
    props: {
        backgroundImage: String,
        icon: String,
        title: String,
        description: String,
        btnText: String,
        url: String,
    },
    mounted() {
        // apiHelp.supportHelp().then(res => { this.supportHelp = res })
    },
    methods: {}
}
</script>
<style lang="scss">
.supportCards {
    padding-bottom: 90px;
    margin: 0 0 -20px;
    @media only screen and (max-width: $phone-max-size) {
        margin: 0 0 -40px;
    }

    .card {
        width: 274px;
        height: 258px;
        padding: 30px 20px 70px;
        position: relative;
        color: white;
        border-radius: 20px;
        overflow: hidden;
        margin: 0 12px 20px;
        @media only screen and (max-width: $phone-max-size) {
            width: 100%;
            height: auto;
            padding: 30px 20px;
            margin-bottom: 40px;
            font-size: 14px;
        }

        .cardBgImg {
            width: 100%;
            height: 100%;
            background-size: cover;
            position: absolute;
            left: 0;
            top: 0;
            z-index: 1;
        }

        .cardIcon {
            width: 30px;
            height: 30px;
            align-self: center;
            z-index: 2;
            @media only screen and (max-width: $phone-max-size) {
                width: 60px;
                height: 60px;
            }
        }

        h3 {
            text-align: center;
            font-family: "du-font-primary-bold";
            font-size: 20px;
            margin-top: 16px;
            margin-bottom: 8px;
            z-index: 2;

            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            [dir="rtl"] & {
                font-family: "du-font-secondary-bold-ar";
            }

            @media only screen and (max-width: $phone-max-size) {
                font-size: 20px;
                margin-top: 16px;
            }
        }

        p {
            text-align: center;
            font-size: 13px;
            line-height: 18px;
            margin: 0;
            z-index: 2;
        }

        .btn {
            min-width: 140px;
            font-size: 17px;
            line-height: 30px;
            align-self: center;
            height: 30px;
            margin-top: 10px;
            position: absolute;
            bottom: 30px;
            font-size: 13px;
            z-index: 2;
            @media only screen and (max-width: $phone-max-size) {
                position: relative;
                bottom: auto;
            }
        }
    }
}
</style>