import axios from "axios";

var prodEndpoint = "https://www.du.ae/vuesupportapi"
// var devEndpoint = "http://meyslclvweb01:9000/vuesupportapi";

var endpoint = prodEndpoint;
var isArabic = document.getElementsByTagName("html")[0].getAttribute("dir") === "rtl";

var href = window.location.pathname.split("/");
var _segment
if(href[1] == 'ar'){
 _segment = href[2] == "personal" ? "consumer" : "enterprise";
}else{
 _segment = href[1] == "personal" ? "consumer" : "enterprise";
}
// var _segment = "consumer" //Enable for dev

export default {

  // Returns Informative banner message details
  async getOutageInfo() {
    var lang_key = isArabic ? "_Ar" : "_En";
    var _isCritical = "False";

    const _msg = {
      title: "",
      link: "",
      isCritical: false,
      show: false,
    };

    return axios
      .get(endpoint)
      .then((response) => {
        
        if (response.data.length != 0) {
          const msg = response.data.find(
            (msg) => msg.segment.toLowerCase() == _segment && msg.isCritical == _isCritical
          );

          if(msg){
            _msg.title = msg["title" + lang_key];
            _msg.link = msg["link" + lang_key];
            _msg.show = true;
          }

          return _msg;
        } else {
          return _msg;
        }
      })
      .catch((error) => {
        console.log('[-]OUTAGE-API:',error);
        return _msg;
      });
  },

  // Returns critical banner message details
  async getCriticalOutageInfo(){
    var lang_key = isArabic ? "_Ar" : "_En";
    var _isCritical = "True";

    const _msg = {
      title: "",
      link: "",
      isCritical: true,
      show: false,
    };

    return axios
      .get(endpoint)
      .then((response) => {
          if(response.data.length != 0){
              const msg = response.data.find( 
                (msg) => msg.segment.toLowerCase() == _segment && msg.isCritical == _isCritical
                );

              if(msg){
                _msg.isCritical = true;
                _msg.title = msg['title' + lang_key];
                _msg.link = msg['link' + lang_key];
                _msg.show = true;
              }
              return _msg
          }else{
              return _msg
          }
    }).catch((error) => {
      console.log('[-]OUTAGE-API:',error);
      return _msg;
    });
  }


};
