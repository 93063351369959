<template>
  <div
    :class="[!isMobile ? 'grid-container no-padding' : '']"
    :style="[
      isMobile ? { 'margin-top': '20px' } : null,
    ]"
  >
    <div
      class="newPackage newPackage-content"
      :style="[ isMobile
        ? { background: newPackage.mobileBackground }
        : { background: newPackage.background } ]"
    >
      <div
        class="newPackage-offer "
        v-for="(offer, i) in newPackage.cards"
        :key="i"
      >
        <div
          class="newPackage-offer__image"
          :style="[!isMobile && { position: 'relative' }]"
        >
          <img
            :src="path+offer.image"
            :alt="offer.title"
            width="256"
            height="360"
            v-if="!isMobile && offer.image"
          />
          <img
            :src="path+offer.mobileImage"
            :alt="offer.title"
            v-if="isMobile && offer.mobileImage"
          />

          <div
            v-if="offer.isIconVisible"
            class="newPackage-offer__image--logo"
            :style="[
              !isMobile && { position: 'absolute', top: '30%', left: '15%' },
            ]"
          >
            <img
              :src="path+svg.name"
              :alt="svg.name"
              :width="24"
              :height="24"
              v-for="(svg, i) in offer.svg"
              :key="i"
            />
          </div>
        </div>

        <div class="newPackage-offer__content">
          <div class="newPackage-offer__content-container">
            <div v-if="offer.promoBanner" :class="[offer.promoBanner.theme]">
              <span class="promo-title">{{ offer.promoBanner.title }}</span>
            </div>
            <div
              class="newPackage-offer__content-title"
              :style="{ color: offer.textColor }"
            >
              {{ offer.title }}
            </div>
            <div
              class="newPackage-offer__content-subtitle"
              :style="{ color: offer.textColor }"
              v-if="offer.strikePrice != 'null'"
            >
              {{ offer.extraText }}
              <span class="strike-price">
                <span v-if="!isArabic">{{ offer.currency }}</span>
                {{ offer.strikePrice }}
              </span>
              <span v-if="!isArabic" class="price">
                {{ offer.currency }} {{ offer.price }}
              </span>
              <span v-if="isArabic" class="price">
                {{ offer.price }} {{ offer.currency }}</span
              >
            </div>
            <div
              class="newPackage-offer__content-subtitle"
              :style="{ color: offer.textColor }"
              v-else
            >
              <span v-if="!isArabic"
                >{{ offer.extraText }} {{ offer.currency }}
                {{ offer.price }}</span
              >
              <span v-if="isArabic"
                >{{ offer.extraText }} {{ offer.price }}
                {{ offer.currency }}</span
              >
            </div>
            <div
              class="newPackage-offer__content-description"
              :style="{ color: offer.textColor }"
            >
              {{ offer.description }}
            </div>
          </div>

          <a
            :href="offer.buttonURL"
            class="newPackage-offer__content-button"
            :class="{'btn':true ,'white': offer.buttonColor === 'white' ? true : false}"
            v-if="offer.buttonText"
            @click="
              sendGA(
                allDevice.ga.eventCategory,
                allDevice.ga.eventAction,
                allDevice.ga.eventLabel,
                allDevice.ga.eventValue
              )
            "
          >
            <span
              :style="{ color: offer.buttonTextColor }"
              class="newPackage-offer__content-button__text"
              >{{ offer.buttonText }}</span
            >
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api";
import { Analytics } from "@/analytics.js";

export default {
  name: "NewPackages",
    props:{
        path: {
            type: String,
            default: process.env.VUE_APP_PATH
        }
    },
  data: () => ({
    newPackage: [],
    isMobile: window.matchMedia("only screen and (max-width: 767px)").matches,
    isArabic:
      document.getElementsByTagName("html")[0].getAttribute("dir") === "rtl",
  }),
  mounted() {
    api.newPackages().then((response) => {
      this.newPackage = response;
    });

    this.$nextTick(() => {
      window.addEventListener("resize", this.checkIsMobile, { passive: true });
    });
  },
  methods: {
    checkIsMobile() {
      this.isMobile = window.matchMedia(
        "only screen and (max-width: 767px)"
      ).matches;
    },
    sendGA(eventCategory, eventAction, eventLabel, eventValue) {
      Analytics(eventCategory, eventAction, eventLabel, eventValue);
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.checkIsMobile);
  },
};
</script>

<style scoped lang="scss">

.no-padding {
  padding: 0px !important;
}
.promo-title {
  color: #ffffff;
  font-size: 15px;
  font-family: $du-font-secondary-bold;

  [dir="rtl"] & {
    font-family: $du-font-secondary-bold-ar;
  }

  @media only screen and (max-width: $phone-max-size) {
    font-size: 13px;
    line-height: 16px;
  }
}

.navy-aqua-violet {
  display: inline-flex;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 100px;
  background: linear-gradient(61deg, #00205b 0%, #753bbd 30%, #00a9ce 100%);
}
.strike-price {
  text-decoration-line: line-through;
}

.price {
  color: #c724b1;
  margin-left: 2px;
}
.newPackage {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 48px 0px;

  @media only screen and (max-width: $phone-max-size) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding: 0px;
  }

  &-content {
    display: flex;
    align-items: flex-start;
    gap: 24px;
    flex-wrap: wrap;

    @media only screen and (max-width: $phone-max-size) {
      gap: 20px;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      background: #f8f8f8;
    }
  }

  &-offer {
    display: flex;
    height: 440px;
    padding: 40px 32px 40px 0px;
    align-items: center;
    gap: 32px;
    flex: 1 0 0;
    border-radius: 12px;
    box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.02);
    background: #ffffff;
    width: 624px;

    @media only screen and (max-width: $phone-max-size) {
      flex-direction: column;
      padding: 24px 20px 32px 20px;
      gap: 20px;
      box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.02);
      border-radius: 0;
      height: auto;
      width: 100%;
    }

    &__image {
      display: flex;
      justify-content: center;
      align-items: center;

      &--logo {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 24px;
      flex: 1 0 0;

      &-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
      }

      &-title {
        font-family: $du-font-secondary-bold;
        font-size: 48px;
        line-height: 54px;

        [dir="rtl"] & {
          font-family: $du-font-secondary-bold-ar;
        }

        @media only screen and (max-width: $phone-max-size) {
          font-size: 36px;
          line-height: 44px;
        }
      }

      &-subtitle {
        font-family: $du-font-secondary-bold;
        font-size: 24px;
        line-height: 32px;

        [dir="rtl"] & {
          font-family: $du-font-secondary-bold-ar;
        }
      }

      &-description {
        font-family: $du-font-secondary-medium;
        font-size: 20px;
        line-height: 28px;

        [dir="rtl"] & {
          font-family: $du-font-secondary-medium-ar;
        }
      }

      &-button {
        display: flex;
        width: auto;
        padding: 16px 24px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 12px;
        box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);

        @media only screen and (max-width: $phone-max-size) {
          width: 100%;
        }

        &__text {
          font-family: $du-font-secondary-medium;
          font-size: 20px;
          line-height: 24px;

          [dir="rtl"] & {
            font-family: $du-font-secondary-medium-ar;
          }
        }
      }
    }
  }
}

.gradient {
  background: linear-gradient(60deg, #753bbd 0%, #c724b1 60%);
}
</style>
