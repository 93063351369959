<template>
    <section class="helpTopics" >
        <div class="helpTopics-inner">
            <div class="grid-container" :class="isMobile ? 'p0':''">
                <h2 class="headline3" :class="isMobile ? 'mb20':''" v-if="content">{{content.trendTitle}}</h2>
                <div class="row30 show-for-medium"></div>
            </div>
            <div class="grid-container full-mobile">
                <div class="grid-x grid-margin-x">

                  <div class="cell small-12 large-12">

                    <div class="grid-x grid-margin-x">

                      <div class="cell small-12 large-8">
                        <template v-if="trendArticles && content">
                            <ul class="helpTopics-box-list">
                              <li class="" v-for="(question,i) in trendArticles" v-bind:key="i">
                                <a :href="question.url" class="link underline helpTopics-box" @click="showAll = true; sendGA('Help & Support', 'Popular Articles', question.url, 0)">{{question.title}}
                                  <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                      d="M10.5858 6.34317L12 4.92896L19.0711 12L12 19.0711L10.5858 17.6569L16.2427 12L10.5858 6.34317Z"
                                      fill="#C724B1"
                                  />
                                </svg>

                                </a>

                              </li>
                            </ul>
                        </template>
                        <template v-else>
                          <div class="helpTopics-box rd-loader">
                          </div>
                        </template>
                      </div>
                      <div class="cell small-12 large-4">
                        <TroubleTicketForm :isSideBar="true" />
                      </div>
                  </div>
                  </div>
                  <div class="row30 "></div>
                    <div class="cell small-12 large-12">
                      <h2 class="headline3" v-if="content">{{content.title}}</h2>
                      <div class="row30 show-for-medium"></div>
                        <template v-if="helpTopics.topicTree && content">
                            <transition-group class="flex" name="fadeHelp" tag="div">
                                <div class="helpTopics-box img" :class="topic.topic.description && topic.topic.description.startsWith('http') ? 'no-bg':''"  v-for="(topic, index) in helpTopics.topicTree" v-bind:key="index" v-show="index < 8 || showAll">
                                    <template v-if="topic.topic.description && topic.topic.description.startsWith('http')">
                                        <a :href="topic.topic.description" @click="sendGA('Help & Support' , 'Popular Topics', topic.topic.name, 0)">
                                            <svg-icon :icon-name="getIcon(topic.topic.imageUrl)" width="40" height="40"></svg-icon>
                                          <p class="description2">{{bookAppointmentTxt}}</p>

                                            <span class="box-content">
                                               <p class="description3" style="color: #C724B1">{{topic.topic.name}}</p>
                                                <img class="chevron" src="https://www.du.ae/servlet/duaediscovery/common/km/common/static/helpandsupport/img/chevron-right.svg" />
                                            </span>
                                        </a>
                                    </template>
                                    <template v-else>
                                        <a :href="content.topicsURL+topic.topic.id+'&lang='+content.language" @click="sendGA('Help & Support' , 'Popular Topics', topic.topic.name, 0)">

                                            <svg-icon :icon-name="getIcon(topic.topic.imageUrl)" width="40" height="40"></svg-icon>
                                            <p class="description3">{{topic.topic.name}}</p>
                                          <span class="box-content">

                                            <p class="description2" v-if="topic.topic.description">{{topic.topic.description}}</p>
                                            <p class="description2" v-else>Click here for more on {{topic.topic.name}}</p>
                                              <img class="chevron" src="https://www.du.ae/servlet/duaediscovery/common/km/common/static/helpandsupport/img/chevron-right.svg" />
                                          </span>
                                        </a>                                        
                                    </template>

                                </div>
                            </transition-group>
<!--                            <div class="row20"></div>-->
<!--                            <div class="flex-right">-->
<!--                                <a v-if="!showAll" class="card-link arrow-down" @click="showAll = true; sendGA('Help & Support' , 'Popular Topics', 'View All Topics', 0)">{{content.viewAll}}-->
<!--                                </a>-->
<!--                            </div>-->
                            <div class="row20 hide-for-medium"></div>
                        </template>
                        <template v-else>
                            <div class="flex">
                                <div class="helpTopics-box img rd-loader"></div>
                                <div class="helpTopics-box img rd-loader"></div>
                                <div class="helpTopics-box img rd-loader"></div>
                                <div class="helpTopics-box img rd-loader"></div>
                                <div class="helpTopics-box img rd-loader"></div>
                                <div class="helpTopics-box img rd-loader"></div>                                
                            </div>
                        </template>
                    </div>

                </div>
            </div>
        </div>
    </section>
</template>
<script>
import apiHelp from '../../api-help'
import { Analytics } from "@/analytics.js";
import SvgIcon from '@/components/SvgIcon'
import TroubleTicketForm from "@/components/HelpSupport/TroubleTicketForm";

export default {
    name: 'HelpTopicList',
    props: ['content'],
    components: {
      TroubleTicketForm,
        SvgIcon
    },
    data() {
        return {
            helpTopics: [],
            trendArticles: [],
            showAll: true,
            isMobile: window.innerWidth < 1024,
            bookAppointmentTxt: document.getElementsByTagName('html')[0].getAttribute('dir') === 'rtl'?"تجنب طوابير الانتظار الطويلة و قم بزيارة فرع du  في الوقت الذي يناسبك":"Avoid long queues and visit the du store at your convenience."
        }
    },
    mounted() {
            apiHelp.getHelpTopics().then(res => {
              console.log('topics list',res)
              this.helpTopics = res

            });
            apiHelp.getTrendArticles().then(res => { this.trendArticles = res.data });
    },
    methods: {
        sendGA(eventCategory, eventAction, eventLabel, eventValue) {
            Analytics(eventCategory, eventAction, eventLabel, eventValue);
        },
        getIcon(icon){
            var newIcon = icon;
            newIcon.indexOf('ico-') > -1 ? newIcon = newIcon.split('ico-')[1].replace('.svg','') : newIcon = newIcon.split('/ico/')[1].replace('.svg','')
            return newIcon
        }
    }
}
</script>
<style lang="scss">
.helpTopics {
  @media only screen and (max-width: $phone-max-size) {
    padding: 20px;
  }
    background-color: $du-grey-10;

    .grid-container {
        @media only screen and (max-width: $phone-max-size) {
            padding: 0 15px;
        }
    }
    .description2{
      [dir="rtl"] & {
        text-align: right;
      }
    }
    .card-link {
        padding-right: 20px;

        [dir="ltr"] & {
            padding-right: 20px;
        }

        [dir="rtl"] & {
            padding-left: 20px;
        }

        &.arrow-down {

            &:before,
            &:after {
                position: absolute;
                content: "";
                width: 10px;
                height: 2px;
                border-radius: 1px;
                background-color: $primary-action-color;
                top: 8px;
            }

            &:before {
                [dir="ltr"] & {
                    right: 7px;
                    transform: rotate(48deg);
                }

                [dir="rtl"] & {
                    left: 7px;
                    transform: rotate(-48deg);
                }
            }

            &:after {
                [dir="ltr"] & {
                    right: 1px;
                    transform: rotate(-48deg);
                }

                [dir="rtl"] & {
                    left: 1px;
                    transform: rotate(48deg);
                }
            }
        }
    }

    .flex {
        display: flex;
        flex-wrap: wrap;

        @media only screen and (max-width: $phone-max-size) {
            flex-wrap: wrap;
        }
    }

    .flex-right {

        padding-right: 20px;

        [dir="ltr"] & {
            padding-right: 20px;
        }

        [dir="rtl"] & {
            padding-left: 20px;
        }
    }

    &-box {
        border: 1px solid $du-border-color;
        background-color: $white;
        border-radius: 10px;
        margin: 10px 20px 10px 0;
        padding: 25px;
        min-height: 300px;

      &.no-bg{
        background:none;
        border: none;
      }

        [dir="rtl"] & {
            margin: 10px 0 10px 20px;
        }

        @media only screen and (max-width: $phone-max-size) {
            margin: 5px 0;
            height: calc(100% - 10px);
        }


        a {
          &:visited {
            color: $black;
          }

          .description3 {
            display: flex;
            align-items: center;
            font-size: 18px;
            color: #333;
            margin: 5px 0;

            svg {
              flex-shrink: 0;
              height: 18px;
              width: auto;
            }
          }
        }

        &-list {
            li {
                margin-bottom: 10px;

                a {
                    font-size: $font-desktop-body-S;
                    font-family: $du-font-secondary-medium;
                }

            }
        }

        &.img {
            padding: 0;
            transition: .3s ease-out;
            width: calc(100% / 4 - 21px);
            min-height: 150px;
            // height: calc(100% -  20px);
            @media only screen and (max-width: $phone-max-size) {
                min-height: 90px;
            }

            &:nth-child(4n) {
                [dir="ltr"] & {
                    @media only screen and (min-width: $phone-max-size) {
                        margin-right: 0px;
                    }
                }

                [dir="rtl"] & {
                    @media only screen and (min-width: $phone-max-size) {
                        margin-left: 0px;
                    }
                }
            }



            //&:nth-child(odd) {
            //    [dir="ltr"] & {
            //        @media only screen and (max-width: $phone-max-size) {
            //            margin-right: 5px;
            //            margin-left: 0px;
            //        }
            //    }
            //
            //    [dir="rtl"] & {
            //        @media only screen and (max-width: $phone-max-size) {
            //            margin-left: 5px;
            //            margin-right: 0px;
            //        }
            //    }
            //}

            //&:nth-child(even) {
            //    [dir="ltr"] & {
            //        @media only screen and (max-width: $phone-max-size) {
            //            margin-right: 0px;
            //            margin-left: 5px;
            //        }
            //    }
            //
            //    [dir="rtl"] & {
            //        @media only screen and (max-width: $phone-max-size) {
            //            margin-left: 0px;
            //            margin-right: 5px;
            //        }
            //    }
            //}


            @media only screen and (max-width: $phone-max-size) {
                min-width: calc(100% - 5px);
                width: calc(100% - 5px);
            }

            .subtitle1 {
                margin-bottom: 0;
            }

            a {
                padding: 20px;
                display: block;
                color: $black;
                text-align: left;
                .svg-icon {
                  fill: url(#du-icon-gradient) $du-violet;
                }
                &:hover {
                    color: $du-violet;
                }

                @media only screen and (max-width: $phone-max-size) {
                    padding: 15px 10px;
                }

              .box-content{
                display: flex;
                align-items: center;
                justify-content: space-between;
                svg{
                  flex-shrink:0;
                  height: 18px;
                  width:auto;
                }
              }
            }

            &:hover {
                box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.15);
                border-color: transparent;

                @media only screen and (min-width: $phone-max-size) {
                    transform: translateY(-5px);
                }
            }
        }

        &-title {
            font-size: $font-desktop-body-M;
            font-family: $du-font-secondary-bold;
        }

        .svg-icon {
          width: auto;
          height: 40px;
          display: block;
          margin-bottom: 12px;

            @media only screen and (max-width: $phone-max-size) {
                width: 30px;
                height: 30px;
            }
        }
    }

  .helpTopics-box-list{
    display: flex;
    flex-flow: wrap;
    margin-top: -10px;
    > li{
      width: calc(100% / 2 - 10px);
      margin: 0 20px 0 0;
      [dir="rtl"] & {
        margin: 0 0 0 20px;
      }
      @media only screen and (max-width: $phone-max-size) {
        width: 100%;
        margin-right: 0px;
      }
      &:nth-child(2n) {
        [dir="ltr"] & {
          @media only screen and (min-width: $phone-max-size) {
            margin-right: 0px;
          }
        }

        [dir="rtl"] & {
          @media only screen and (min-width: $phone-max-size) {
            margin-left: 0px;
          }
        }
      }
      a.link{
        min-height: auto;
        display: flex;
        align-items: center;
        height: 93px;
        width: 100%;
        justify-content: space-between;
        padding: 15px;
        color: #333;
        text-decoration: none;
        @media only screen and (max-width: $phone-max-size) {
          height: 65px;
          text-decoration: none;
        }
        svg{
          flex-shrink: 0;
          [dir="rtl"] & {
              transform: scaleX(-1);
          }
        }
      }
    }
  }

    .fadeHelp-enter-active,
    .fadeHelp-leave-active {
        transition: opacity .5s;
        transition-delay: 1s;
    }

    .fadeHelp-enter,
    .fadeHelp-leave-to {
        opacity: 0;
        transition-delay: 1s;
    }

}
</style>