<template>
  <div :class="[!isMobile ? 'grid-container no-padding' : '']">
    <div
      class="extraFeature extraFeature-container"
      :style="{ background: extraFeatures.background }"
    >
      <div class="extraFeature-content">
        <div
          class="extraFeature-card"
          v-for="(card, i) in extraFeatures.cards"
          :class="card.background"
          :key="i"
        >
          <img :src="path+card.image" :alt="card.title" v-if="!isMobile" />
          <img :src="path+card.mobileImage" :alt="card.title" v-if="isMobile" />

          <div class="extraFeature-card__content">
            <div class="extraFeature-card__content--copy">
              <div
                class="extraFeature-card__content-title"
                :class="[card.isCenterAlign ? 'center-title' : '']"
                :style="{ color: card.textColor }"
              >
                {{ card.title }}
              </div>
              <div
                class="extraFeature-card__content-description"
                :style="{ color: card.textColor }"
                :class="[card.isCenterAlign ? 'center-description' : '']"
              >
                {{ card.description }}
              </div>
            </div>

            <a
              :href="card.buttonURL"
              class="extraFeature-card__content-button"
              v-if="card.buttonText"
              :class="[card.buttonColor]"
              @click="
                sendGA(
                  card.ga.eventCategory,
                  card.ga.eventAction,
                  card.ga.eventLabel,
                  card.ga.eventValue
                )
              "
            >
              <span
                :style="{ color: card.buttonTextColor }"
                class="extraFeature-card__content-button__text"
              >
                {{ card.buttonText }}
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api";
import { Analytics } from "@/analytics.js";

export default {
  name: "ExtraFeatures",
    props:{
        path: {
            type: String,
            default: process.env.VUE_APP_PATH
        }
    },
  data: () => ({
    extraFeatures: [],
    isMobile: window.matchMedia("only screen and (max-width: 767px)").matches,
    isArabic:
      document.getElementsByTagName("html")[0].getAttribute("dir") === "rtl",
  }),
  mounted() {
    api.extraFeatures().then((response) => {
      this.extraFeatures = response;
    });

    this.$nextTick(() => {
      window.addEventListener("resize", this.checkIsMobile, { passive: true });
    });
  },
  methods: {
    checkIsMobile() {
      this.isMobile = window.matchMedia(
        "only screen and (max-width: 767px)"
      ).matches;
    },
    sendGA(eventCategory, eventAction, eventLabel, eventValue) {
      Analytics(eventCategory, eventAction, eventLabel, eventValue);
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.checkIsMobile);
  },
};
</script>

<style scoped lang="scss">
// @media only screen and (max-width: $phone-max-size)

.no-padding {
    padding: 0px !important;
}

.navy-violet {
    background: linear-gradient(62deg, #00205b 0%, #753bbd 60%);
}

.aqua-violet {
    background: linear-gradient(66deg, #753bbd 0%, #00a9ce 60%);

    [dir="rtl"] & {
        background: linear-gradient(132deg, #00a9ce 10%, #753bbd 90%);
    }
}

.extraFeature {
    padding: 48px 0px 72px 0px;

    @media only screen and (max-width: $phone-max-size) {
        padding: 0;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
        align-self: stretch;
    }

    &-container {
        display: flex;
        align-items: center;
        gap: 8px;
    }

    &-content {
        display: flex;
        align-items: flex-start;
        gap: 24px;
        flex-wrap: wrap;

        @media only screen and (max-width: $phone-max-size) {
            flex-direction: column;
            gap: 20px;
            background: #f8f8f8;
        }
    }

    &-card {
        display: flex;
        height: 440px;
        align-items: center;
        flex: 1 0 0;
        border-radius: 12px;
        box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.02);

        @media only screen and (max-width: $phone-max-size) {
            flex-direction: column;
            gap: 24px;
            padding: 32px 24px;
            border-radius: 0;
            align-items: center;
            height: auto;
        }

        img {
            // width: 50%;

            @media only screen and (max-width: $phone-max-size) {
                width: 100%;
            }
        }

        &__content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            gap: 24px;
            flex: 1 0 0;
            padding: 24px;

            @media only screen and (max-width: $phone-max-size) {
                gap: 24px;
                padding-right: 0px;
            }

            &--copy {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 16px;
            }

            &-title {
                font-family: $du-font-secondary-bold;
                font-size: 48px;
                line-height: 54px;

                [dir="rtl"] & {
                    font-family: $du-font-secondary-bold-ar;
                }

                // &.center-title {
                //   @media only screen and (max-width: $phone-max-size) {
                //     top: 50%;
                //     width: 100%;
                //     text-align: center;
                //     position: relative;
                //   }
                // }

                @media only screen and (max-width: $phone-max-size) {
                    font-size: 36px;
                    line-height: 44px;
                }
            }

            &-description {
                font-family: $du-font-secondary-medium;
                font-size: 20px;
                line-height: 28px;

                [dir="rtl"] & {
                    font-family: $du-font-secondary-medium-ar;
                }

                // &.center-description {
                //   @media only screen and (max-width: $phone-max-size) {
                //     left: 5%;
                //     align-self: center;
                //     width: 80%;
                //     top: 50%;
                //   }
                // }

                @media only screen and (max-width: $phone-max-size) {
                    font-size: 20px;
                    line-height: 28px;
                }
            }

            &-button {
                display: flex;
                width: 204px;
                padding: 16px 24px;
                justify-content: center;
                align-items: center;
                gap: 8px;
                border-radius: 12px;
                box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);

                @media only screen and (max-width: $phone-max-size) {
                    width: 100%;
                    margin-top: auto;
                }

                &__text {
                    font-family: $du-font-secondary-medium;
                    font-size: 20px;
                    line-height: 24px;

                    [dir="rtl"] & {
                        font-family: $du-font-secondary-medium-ar;
                    }
                }
            }
        }
    }
}

.gradient {
    background: linear-gradient(60deg, #753bbd 0%, #c724b1 60%);
}
</style>
