import Vue from 'vue'
import Homepage from './Homepage.vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueLazyload from 'vue-lazyload'
import Sprite from 'svg-sprite-loader/runtime/sprite.build';
// import VueWaypoint from "vue-waypoint"

import "@/navigation.js"
import "@/accessibility.js"
import '@/styles/style.scss';

Vue.use(Sprite, VueAxios, axios)
// Vue.use(VueWaypoint)

Vue.use(VueLazyload, {
  lazyComponent: true
});

Vue.config.productionTip = false

new Vue({
    render: h => h(Homepage)
}).$mount('#app')