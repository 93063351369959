<template>
  <section>
    <div class="grid-container bestoffers" :style="{ background: bestOffers.background }">
      <template v-if="bestOffers.title">
        <h1 class="bestoffers-title">
          {{ bestOffers.title }}
        </h1>
      </template>

      <div
        class="card-container"
      >
        <div
          v-for="(offer, i) in bestOffers.cards"
          :key="i"
          style="cursor: pointer;"
          @click="redirectURL(offer.buttonURL)"
        >
          <template v-if="offer.isFullImageBackground">
            <div
              class="card-image-section"
              :style="[
                !isMobile
                  ? { background: 'url(' + path+offer.image + ')' }
                  : { background: 'url(' + path+offer.mobileImage + ')' },
              ]"
            >
              <div
                class="card-section-title"
                :style="[
                  { color: offer.color },
                  isArabic ? { textAlign: 'right' } : null,
                ]"
                v-html="offer.title"
              ></div>
            </div>
          </template>

          <div v-else class="card-section" :class="[offer.background]">
            <div
              class="card-section-title"
              :style="[
                { color: offer.color },
                isArabic ? { textAlign: 'right' } : null,
              ]"
              v-html="offer.title"
            ></div>

            <div v-if="offer.percentage" style="position: relative;">
              <div
                :style="{ color: offer.color }"
                class="card-section-percentage"
              >
                {{ offer.percentage }}
              </div>
              <div
                :style="{ color: offer.percentageIconColor }"
                class="card-section-percentage__icon"
              >
                {{ offer.percentageIcon }}
              </div>
            </div>

            <div
              v-if="offer.image && !isMobile"
              :class="[offer.isPaddingRequire ? 'padding-24' : '']"
            >
              <img :src="path+offer.image" :alt="offer.title" />
            </div>

            <!-- <div
              v-if="offer.mobileImage && isMobile"
            > -->
            <img
              v-if="offer.mobileImage && isMobile"
              :src="path+offer.mobileImage"
              :alt="offer.title"
              width="128"
              height="187"
              style="align-self: end"
            />
            <!-- </div> -->
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import api from "@/api";
import { Analytics } from "@/analytics.js";

export default {
  name: "BestOffers",
    props:{
      path: {
          type: String,
          default: process.env.VUE_APP_PATH
      }
    },
  data: () => ({
    bestOffers: [],
    isMobile: window.matchMedia("only screen and (max-width: 767px)").matches,
    isArabic:
      document.getElementsByTagName("html")[0].getAttribute("dir") === "rtl",
  }),
  mounted() {
    api.bestOffers().then((response) => {
      this.bestOffers = response;
    });

    this.$nextTick(() => {
      window.addEventListener("resize", this.checkIsMobile, { passive: true });
    });
  },
  methods: {
    checkIsMobile() {
      this.isMobile = window.matchMedia(
        "only screen and (max-width: 767px)"
      ).matches;
    },
    sendGA(eventCategory, eventAction, eventLabel, eventValue) {
      Analytics(eventCategory, eventAction, eventLabel, eventValue);
    },
    redirectURL(url) {
      window.location.href = url;
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.checkIsMobile);
  },
};
</script>

<style lang="scss">
//section {
//  padding: 0px !important;
//}

.card-image-section {
  width: 300px;
  height: 400px;
  padding: 24px 24px 32px 24px;
  transition: transform 0.3s ease-out;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.1);
  background-size: contain !important;

  &:hover {
    transform: translateY(-5px);
  }

  @media only screen and (max-width: $phone-max-size) {
    width: 335px;
    height: 200px;
    flex-direction: row-reverse;
    padding: 0px 16px;
    gap: 20px;
    display: flex;
  }
}
.navy-aqua {
  display: inline-flex;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 100px;
  background: linear-gradient(61deg, #00205b 0%, #753bbd 30%, #00a9ce 100%);
  border: 1px solid #fff;
  margin-bottom: 12px;

  @media only screen and (max-width: $phone-max-size) {
    padding: 1px 10px;
  }
}

.promo-title {
  font-size: 15px;
  font-family: $du-font-secondary-bold;
  line-height: 16px;

  [dir="rtl"] & {
    font-family: $du-font-secondary-bold-ar;
  }

  @media only screen and (max-width: $phone-max-size) {
    font-size: 15px;
    line-height: 16px;
    font-family: $du-font-secondary-bold;

    [dir="rtl"] & {
      font-family: $du-font-secondary-bold-ar;
    }
  }
}
.relative {
  position: relative;
}

.absolute {
  position: absolute;
  z-index: 1;
}

.promoBanner {
  padding: 24px 24px 32px 24px;
}

.font-normal {
  font-family: $du-font-secondary-regular;

  [dir="rtl"] & {
      font-family: $du-font-secondary-regular-ar;
    }
}

// .font-bold {
//   text-align: center;

//   @media only screen and (max-width: $phone-max-size) {
//     text-align: left;
//   }
// }

.strike-word {
  text-decoration-line: line-through;
}

.price {
  color: #c724b1;
}
.padding-24 {
  padding-bottom: 32px;
}
.img-container {
  padding: 0px 20px 0px 20px;
}
.font-17 {
  font-size: 17px;
  line-height: 24px;
}
.bestoffers {
  // border-top: 1px solid $du-border-color;
  display: flex;
  padding: 64px 0px 72px 0px;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  position: relative;

  @media only screen and (max-width: $phone-max-size) {
    text-align: left;
    padding: 32px 20px;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }

  &-title {
    font-family: $du-font-secondary-bold;
    font-size: 20px;
    color: $du-grey-90;

    [dir="rtl"] & {
      font-family: $du-font-secondary-bold-ar;
    }

    @media only screen and (max-width: $phone-max-size) {
      line-height: 24px;
    }
  }
}

.card-container {
  display: flex;
  align-items: flex-start;
  gap: 24px;
  // flex-wrap: wrap;

  @media only screen and (max-width: $phone-max-size) {
    gap: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}

.card-section {
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.1);
  height: 400px;
  width: 300px;
  padding: 24px 24px 0px 24px;
  transition: transform 0.3s ease-out;

  &:hover {
    transform: translateY(-5px);
  }

  @media only screen and (max-width: $phone-max-size) {
    width: 335px;
    height: 200px;
    flex-direction: row-reverse;
    // flex-direction: row;
    padding: 0px 16px;
    // align-items: flex-end;
    gap: 20px;
  }

  &-title {
    font-family: $du-font-secondary-bold;
    line-height: 26px;
    font-size: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    [dir="rtl"] & {
      font-family: $du-font-secondary-bold-ar;
    }

    @media only screen and (max-width: $phone-max-size) {
      font-size: 20px;
      line-height: 24px;
      width: 146px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
    }
  }

  &-percentage {
    font-family: $du-font-primary-bold;
    font-size: 160px;
    line-height: 160px;
    padding-bottom: 32px;
    letter-spacing: -8px;

    [dir="rtl"] & {
      font-family: $du-font-secondary-bold-ar;
      color: #C724B1;
    }

    @media only screen and (max-width: $phone-max-size) {
      font-size: 96px;
    }

    &__icon {
      font-family: $du-font-primary-bold;
      font-size: 72px;
      line-height: 60px;
      position: absolute;
      top: 50%;
      left: 75%;

      [dir="rtl"] & {
      font-family: $du-font-secondary-bold-ar;
      color: #C724B1;
    }

      @media only screen and (max-width: $phone-max-size) {
        font-size: 48px;
        top: 40%;
        left: 71%;
      }
    }
  }
}

.white-gradient {
  background: #ffffff;
}

.gradient1 {
  background: linear-gradient(24.05deg, #753bbd 9.2%, #00a9ce 52.35%);
}

.gradient2 {
  background: linear-gradient(
    69deg,
    #2478db 0%,
    #2690df 47.23%,
    #2898da 79.51%,
    #2696d8 100%
  );
}
</style>
