import $ from 'jquery';

var dataLayer = dataLayer;
(function ($) {
	menuFocus();
	mobileMenu();
	searchOpen();
	AccessibilityOpen();
	myAccountOpen();
	segmentLinksOpen();
	clearSearchdata();
	resizeTextHighlight();

	// var readCookie = function (cname) {
	// 	var name = cname + "=";
	// 	var ca = document.cookie.split(';');
	// 	for (var i = 0; i < ca.length; i++) {
	// 		var c = ca[i];
	// 		while (c.charAt(0) == ' ') {
	// 			c = c.substring(1);
	// 		}
	// 		if (c.indexOf(name) == 0) {
	// 			return c.substring(name.length, c.length);
	// 		}
	// 	}
	// 	return "";
	// };

	// var writeCookie = function (cname, cvalue, exdays) {
	// 	var d = new Date();
	// 	d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
	// 	var expires = "expires=" + d.toUTCString();
	// 	document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/;domain=.du.ae";

	// 	// This is for test environments
	// 	try {
	// 		if(window.location.href.indexOf("localhost") > -1 || window.location.href.indexOf("94.200.26.140") > -1 || window.location.href.indexOf("meyslclvweb") > -1 || window.location.href.indexOf("172.24.108.102") > -1 || window.location.href.indexOf("10.0.1.15") > -1) {
	// 			document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/;domain=localhost";
	// 			document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/;domain=94.200.26.140";
	// 			document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/;domain=meyslclvweb01";
	// 			document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/;domain=172.24.108.102";
	// 			document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/;domain=10.0.1.15";
	// 		}
	// 	} catch (e) {
	// 		console.log(e);
	// 	}
	// 	// --
	// };

	/*var $rdInfoTickerClosed, $rdInfoTickerClicked/!*, $rdInfoTickerSeen, $rdInfoTickerLoad*!/;
	$('.js-rd-info-ticker').each(function(){
		var $me = $(this), $close = $('.js-close-ticker', $me), $link = $('a', $me), $hideOnClose = $me.data('hide-on-close'), $hideOnClick = $me.data('hide-on-click');
		//$rdInfoTickerLoad = readCookie('rdInfoTicker');
		//$rdInfoTickerSeen = readCookie('rdInfoTickerSeen');
		$rdInfoTickerClicked = readCookie('rdInfoTickerClicked');
		$rdInfoTickerClosed = readCookie('rdInfoTickerClosed');

		writeCookie('rdInfoTicker',1, 365);

		if (!$hideOnClick && $rdInfoTickerClicked && !$rdInfoTickerClosed) {
			$me.css({'transition':'none'});
			$me.addClass('active');
			writeCookie('rdInfoTickerSeen',1, 365);
		}

		if (!$rdInfoTickerClosed || !$hideOnClose) {
			setTimeout(function () {
				$me.addClass('active');
				writeCookie('rdInfoTickerSeen',1, 365);
			}, 1500);
		}

		$close.click(function(){
			$me.removeClass('active is-sticky');
			$('body').css({'margin-top':0});
			writeCookie('rdInfoTickerClosed',1, 365);
			return false;
		});

		$link.click(function(){
			delayedLinkClick($(this));
			writeCookie('rdInfoTickerClicked',1, 365);
			if($hideOnClick) {
				writeCookie('rdInfoTickerClosed',1, 365);
			}
		});
	});*/

	function delayedLinkClick($t){
		var _href = $t.attr('href');
		setTimeout(function(){
			window.location = _href;
		},400);
	}

	$('.header-wrap').each(function(){
		var $me = $(this);
		// show hide subnav depending on scroll direction
		var position = $(window).scrollTop();
		var to1;

		$(window).scroll(function () {
			var scroll = $(window).scrollTop();
			clearTimeout(to1);

			/*if($('.js-rd-info-ticker').hasClass('active')) {
				if(scroll > 0) {
					$('.js-rd-info-ticker').addClass('is-sticky');
					$('body').css({'margin-top':$('.js-rd-info-ticker').innerHeight()});
				}/!*
				else {
					$('.js-rd-info-ticker').removeClass('is-sticky');
					$('body').css({'margin-top':0});
				}*!/
			}*/

			// if bottom return
			if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
				return;
			}

			to1 = setTimeout(function(){
				if (scroll > position) {
					// scrolling downwards, only here for dev purposes
					if(scroll > 100) {
						$me.addClass('minimal');
					}
				} else {
					// scrolling upwards
					$me.removeClass('minimal');
				}


				position = scroll;
				clearTimeout(to1);
			},30);

		});
	});

	// GTM / Google analytics Tracking
	var $links = $('.submenu-big a, .du-main-sec-menu > li > a');
	$links.click(function(e){

		var $t = $(this);
		var _label = [];
		var activeSegment = $('.du-segment-links:eq(0) a.active').text();


		_label.push($t.closest('.mlist').find(' > a').text());

		if ( $t.closest('.menuList').length || $t.is('.linkLast')) { //menuList
			_label.push($t.closest('.menubox').find('.title:first').text().trim());
		} else if ( $t.closest('.promoBox').length ) { //promo
			$t.closest('.menubox').find('.title').each(function(){
				_label.push($(this).text().trim());
			});
		}

		_label.push($t.text());

		if ($t.is('.menulink')) {
			_label = [];
			_label.push($t.text());
		}

		var _data = {
			'event': 'eventTracker',
			'eventCategory': 'Top Navigation Menu',
			'eventAction': activeSegment + ' Menu',
			'eventLabel': _label.join('|')
		};

		//console.log(_data);
		if (typeof window.dataLayer != "undefined") {
			window.dataLayer.push(_data);
		}

		if ($t.attr('href') == '') {
			return false;
		}

		if ($t.attr('target') == '_blank') {
			return true;
		}

		delayedLinkClick($t);

		e.preventDefault();
		return false;
	});

	// GTM for banners
	var $banners = $('.submenu-big').find('.du-banner-title a.du-btn');
	$banners.click(function(e){
		var $t = $(this);
		var _label = [];
		// var activeSegment = $('.du-segment-links:eq(0) a.active').text();
		var $title = $t.parent().find('.banner-title');

		_label.push($t.closest('.mlist').find(' > a').text());
		_label.push($title.text());


		var _data = {
			'event': 'eventTracker',
			//'eventCategory': activeSegment + ' Menu Promotions',
			'eventCategory': 'Call to Action',
			//'eventAction': 'Top Nav - '+$t.text(),
			'eventAction': 'Click',
			'eventLabel': _label.join('|')
		};

		//console.log(_data);
		if (typeof window.dataLayer != "undefined") {
			window.dataLayer.push(_data);
		}

		if ($t.attr('href') == '') {return false;}

		delayedLinkClick($t);

		e.preventDefault();
		return false;
	});

	// GTM for Quick Links
	var $utils = $('.du-new-quick-links a, .shop a, .login a, .du-profile-box-mobile a, .sicon, .du-lang-switch a');
	$utils.click(function(e){
		var $t = $(this);
		var _label = '';
		// var _activeSegment = $('.du-segment-links:eq(0) a.active').text();
		var _langText = "English to Arabic";
		if ($('html').is('[dir=rtl]')) {
			_langText = "Arabic to English";
		}

		_label = $t.clone()
			.children()
			.remove()
			.end()
			.text();

		if ($t.find('.cart-stat').length) _label = 'Cart';

		if ($t.is('.my-account')) _label = "My account";

		//if ($t.is('.my-account.active')) _label = 'My account logged-in';

		if ($t.is('.sicon')) _label = 'Search';

		if ($t.is('.du-wcag')) _label = 'Text Size';

		if ($t.is('.du-lang')) _label = _langText;

		var _data = {
			'event': 'eventTracker',
			'eventCategory': 'Top Navigation Menu',
			//'eventAction': _activeSegment + ' Menu',
			'eventAction': 'Click',
			'eventLabel': _label
		};

		//console.log(_data);
		if (typeof window.dataLayer != "undefined") {
			window.dataLayer.push(_data);
		}

		if ($t.attr('href') == '') {return false;}

		delayedLinkClick($t);

		e.preventDefault();
		return false;
	});

	// GTM for Segments
	var $segments = $('.du-segment-links a, .msli a');
	$segments.click(function(e){
		var $t = $(this);
		var _label = '';

		_label = $t.text();

		var _data = {
			'event': 'eventTracker',
			'eventCategory': 'Top Navigation Menu',
			'eventAction': 'Click',
			'eventLabel': _label
		};

		//console.log(_data);
		if (typeof window.dataLayer != "undefined") {
			window.dataLayer.push(_data);
		}

		if ($t.attr('href') == '') {return false;}

		delayedLinkClick($t);

		e.preventDefault();
		return false;
	});

	// GTM for Search
	var $search = $('.popular-search a, .suggbox a');
	$search.click(function(e){
		var $t = $(this);
		var _label = [];
		var _activeSegment = $('.du-segment-links:eq(0) a.active').text();

		if ($t.is('.thumb')) {
			_label.push($t.closest('.suggbox').find('.title').text());
			_label.push($t.find('.titlename').text());
		} else {
			_label.push($t.closest('.promo-menu').find('.title').text());
			_label.push($t.text());
		}

		var _data = {
			'event': 'eventTracker',
			'eventCategory': 'Top Search Menu',
			'eventAction': _activeSegment + ' Menu',
			'eventLabel': _label.join('|')
		};

		//console.log(_data);
		if (typeof window.dataLayer != "undefined") {
			window.dataLayer.push(_data);
		}

		if ($t.attr('href') == '') {return false;}

		delayedLinkClick($t);

		e.preventDefault();
		return false;
	});

	// GTM for Search from
	var $searchForm = $('.du-s-box form');
	$searchForm.submit(function(){
		// var $t = $(this);
		// var _label = [];
		var _activeSegment = $('.du-segment-links:eq(0) a.active').text();

		var _data = {
			'event': 'eventTracker',
			'eventCategory': 'Top Search Menu',
			'eventAction': _activeSegment + ' Menu',
			'eventLabel': 'Search Button'
		};
		if (typeof window.dataLayer != "undefined") {
			window.dataLayer.push(_data);
		}
	});

	// GTM for MyAccount menu
	var $mya = $('.du-my-account-profile a').not('.du-profile-closebtn');
	$mya.click(function(e){
		var $t = $(this);
		var _label = [];
		var _activeSegment = $('.du-segment-links:eq(0) a.active').text();

		_label.push('My Account');

		if ($t.is('.wholeLink')) {
			_label.push('dashboard');
		} else {
			_label.push($t.text());
		}

		var _data = {
			'event': 'eventTracker',
			'eventCategory': 'Top Navigation Menu',
			'eventAction': _activeSegment + ' Menu',
			'eventLabel': _label.join('|')
		};

		//console.log(_data);
		if (typeof window.dataLayer != "undefined") {
			window.dataLayer.push(_data);
		}

		if ($t.attr('href') == '') {
			return false;
		}

		if ($t.attr('target') == '_blank') {
			return true;
		}

		delayedLinkClick($t);

		e.preventDefault();
		return false;
	});

	// GTM Accessibility
	var $accTool = $('.acc-tool a').not('.accessibility-close');
	$accTool.click(function(e){
		var $t = $(this);
		var _label = [];
		var _activeSegment = $('.du-segment-links:eq(0) a.active').text();

		_label.push('Accessibility');

		if ($t.is('.accessibility-zoom-decrease')) _label.push('zoom decrease');
		if ($t.is('.accessibility-zoom-increase')) _label.push('zoom increase');
		if ($t.is('.contrast-switch-toggle')) _label.push('contrast');

		var _data = {
			'event': 'eventTracker',
			'eventCategory': 'Top Navigation Menu',
			'eventAction': _activeSegment + ' Menu',
			'eventLabel': _label.join('|')
		};

		//console.log(_data);
		if (typeof window.dataLayer != "undefined") {
			window.dataLayer.push(_data);
		}

		e.preventDefault();
		return false;
	});

})($);

// Navigation focus start
// ============================================
function menuFocus(){
	// var $nav = $(".du-newnav-main-bar"),
		var $items = $(".du-main-sec-menu > li");

		$items.find("> a").on("click", function (e) {
			e.stopPropagation();

			var $this = $(this),
				$parent = $this.parent(),
				windowSize = $(window).width(),
				// $top = $this.offset().top,
				$index = $parent.index();

				if ($parent.hasClass('hover')) {
					$parent.removeClass("hover");
					$("body").removeClass("nav-active");
				} else {

					if (windowSize <= 1024) {
						$parent.siblings().removeClass("hover");
						$parent.toggleClass("hover");
						$('.du-main-sec-menu').animate({
							scrollTop: $index * 57
						}, 50);
					}
					else {
						$parent.siblings().removeClass("hover");
						$parent.addClass("hover");
						$("body").addClass("nav-active");
					}
				}
		});

	$(document).mouseup(function(e) {
		var container = $(".du-main-nav");

		if ($(".du-main-sec-menu > li").hasClass('hover') && !container.is(e.target) && container.has(e.target).length === 0) {
			container.find(".du-main-sec-menu > li").removeClass('hover');
			$("body").removeClass("nav-active");
		}
	});
}

function navigationOff(){
	var container = $(".du-main-nav");
	container.find(".du-main-sec-menu > li").removeClass('hover');
	$("body").removeClass("nav-active");
}
// Navigation focus ends
// --------------------------------------------

// Burger menu starts
// ============================================
function mobileMenu(){
	$(".du-burger-menu").click(function(){
		$(".du-main-nav").addClass("opened");
		$("body").addClass("ov-none");
	});


	$(".du-burger-close").click(function(){
		$(".du-main-nav").removeClass("opened");
		$("body").removeClass("ov-none");
	});
}
// Burger menu ends
// --------------------------------------------


// Search popup starts
// ============================================
function searchOpen(){
	$('.sicon').on('click', function(e){
		e.stopPropagation();
		var $this = $(this);
		if ($this.hasClass('active')) {
			$('body').removeClass('search-active');
			searchCloseByClick();
		} else {
			$this.addClass('active');
			$('.du-search').addClass('opened');
			$('body').addClass('search-active');
			AccessibilityCloseByClick();
			myAccountcloseCloseByClick();
		}
	});

	searchClose();
}

function searchCloseByClick(){
	$('.sicon').removeClass('active');
	$('.du-search').removeClass('opened');
	$('body').removeClass('search-active');
}

function searchClose(){
	$(document).on('mouseup', function(e) {
		var container = $('.du-search, .sicon');
		if (!container.is(e.target) && container.has(e.target).length === 0) {

			searchCloseByClick();
		}
	});
}

$(".du-search-close").click(function(){
	searchCloseByClick();
});
// search popup ends
// --------------------------------------------


// Accessibility popup starts
// ============================================
function AccessibilityOpen(){
	$('.du-wcag').on('click', function(e){
		e.stopPropagation();
		var $this = $(this);
		if ($this.hasClass('active')) {
			AccessibilityCloseByClick();
		} else {
			$this.addClass('active');
			$('.du-access').addClass('opened');
			$('body').addClass('accessibility-active');
			searchCloseByClick();
			myAccountcloseCloseByClick();
		}
	});

	AccessibilityClose();

}

function AccessibilityClose(){
	$(document).on('mouseup', function(e) {
		var container = $('.du-access, .du-wcag');
		if (!container.is(e.target) && container.has(e.target).length === 0) {

			AccessibilityCloseByClick();
		}
	});
}

function AccessibilityCloseByClick(){
	$('.du-wcag').removeClass('active');
	$('.du-access').removeClass('opened');
	$('body').removeClass('accessibility-active');
}

$(".accessibility-close").click(function(){
	AccessibilityCloseByClick();
});
// Accessibility popup ends
// --------------------------------------------


// My Account popup starts
// ============================================
function myAccountOpen(){
	$('.account-trigger').on('click', function(e){
		e.stopPropagation();
		var $this = $(this);
		if ($this.hasClass('active')) {
			myAccountcloseCloseByClick();
		} else {
			$this.addClass('active');
			$(".du-my-account-profile").addClass('opened');
			$("body").addClass('nav-active');
			AccessibilityCloseByClick();
			searchCloseByClick();
		}
	});
	myAccountclose();
}

function myAccountclose(){
	$(document).on('mouseup', function(e) {
		var container = $(".du-my-account-profile.opened, .account-trigger.active, .submenu-big");
		if (!container.is(e.target) && container.has(e.target).length === 0) {

			myAccountcloseCloseByClick();
		}
	});
}

function myAccountcloseCloseByClick(){
	$('.du-my-account-profile').removeClass('opened');
	$('.account-trigger').removeClass('active');
	$('body').removeClass('nav-active');
}

$(".du-profile-closebtn").click(function(){
	myAccountcloseCloseByClick();
});
// My Account popup ends
// --------------------------------------------


// Segments popup starts
// ============================================
function segmentLinksOpen(){
	$(".du-btn-switch-opt").on('click', function(e){
		e.stopPropagation();
		var $this = $(this);
		if ($this.hasClass('active')) {
			segmentLinksCloseByClick();
		} else {
			$this.addClass('active');
			$(".m-switchbtn").addClass('opened');
			AccessibilityCloseByClick();
			searchCloseByClick();
		}
	});

	segmentLinksClose();
}

function segmentLinksClose(){
	$(document).on('mouseup', function(e) {
		var container = $(".m-switchbtn, .du-btn-switch-opt");
		if (!container.is(e.target) && container.has(e.target).length === 0) {

			segmentLinksCloseByClick();
		}
	});
}

function segmentLinksCloseByClick(){
	$('.m-switchbtn').removeClass('opened');
	$('.du-btn-switch-opt').removeClass('active');
}

$('.m-switchbtn-close').click(function(){
	segmentLinksCloseByClick();
});
// Segments popup ends
// --------------------------------------------


// Search clear option starts
// ============================================
function clearSearchdata() {
	// var searchForm = document.getElementById('du-searchform'),
	var	textInput = $('.sinput'),
		clearBtn = $('.scloseicon'),
		submitBtn = $('.sbtn');

	textInput.on('keyup', function(){
		if(textInput.val().length !=0){
			submitBtn.removeClass('disabled');
			submitBtn.prop("disabled", false);
			clearBtn.show();
		}else{
			submitBtn.addClass('disabled');
			submitBtn.prop("disabled", true);
			clearBtn.hide();
		}
	});

	clearBtn.on('click', function(){
		$(this).hide();
		textInput.val("");
		textInput.focus();
		submitBtn.addClass('disabled');
		submitBtn.prop("disabled", true);
	});
}
// Search clear option ends
// --------------------------------------------


// Close all popups starts
// ============================================
function closeAllPopups(){
	// searchCloseByClick();
	navigationOff();
	AccessibilityCloseByClick();
	myAccountcloseCloseByClick();
}
// Close all popups ends
// --------------------------------------------


// Press ESC close popups starts
// ============================================
$(window).on('keyup', function(e){
	if (e.keyCode == 27) {
		closeAllPopups();
		searchCloseByClick();
	}
});
// Press ESC close popups ends
// --------------------------------------------


// Font accessibility starts
// ============================================
function resizeTextHighlight(){
	var $rt = $('.du-resize-text');

	$rt.click(function(){
		$(this).addClass("active");
		$(this).siblings().removeClass("active");
	});
}
// Font accessibility ends
// --------------------------------------------


// window Resize starts
// ============================================
$(window).resize(function(){
	closeAllPopups();
});
// window Resize ends
// --------------------------------------------
