var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[!_vm.isMobile ? 'grid-container no-padding' : ''],style:([
    _vm.isMobile ? { 'margin-top': '20px' } : null,
  ])},[_c('div',{staticClass:"newPackage newPackage-content",style:([ _vm.isMobile
      ? { background: _vm.newPackage.mobileBackground }
      : { background: _vm.newPackage.background } ])},_vm._l((_vm.newPackage.cards),function(offer,i){return _c('div',{key:i,staticClass:"newPackage-offer"},[_c('div',{staticClass:"newPackage-offer__image",style:([!_vm.isMobile && { position: 'relative' }])},[(!_vm.isMobile && offer.image)?_c('img',{attrs:{"src":_vm.path+offer.image,"alt":offer.title,"width":"256","height":"360"}}):_vm._e(),(_vm.isMobile && offer.mobileImage)?_c('img',{attrs:{"src":_vm.path+offer.mobileImage,"alt":offer.title}}):_vm._e(),(offer.isIconVisible)?_c('div',{staticClass:"newPackage-offer__image--logo",style:([
            !_vm.isMobile && { position: 'absolute', top: '30%', left: '15%' },
          ])},_vm._l((offer.svg),function(svg,i){return _c('img',{key:i,attrs:{"src":_vm.path+svg.name,"alt":svg.name,"width":24,"height":24}})}),0):_vm._e()]),_c('div',{staticClass:"newPackage-offer__content"},[_c('div',{staticClass:"newPackage-offer__content-container"},[(offer.promoBanner)?_c('div',{class:[offer.promoBanner.theme]},[_c('span',{staticClass:"promo-title"},[_vm._v(_vm._s(offer.promoBanner.title))])]):_vm._e(),_c('div',{staticClass:"newPackage-offer__content-title",style:({ color: offer.textColor })},[_vm._v(" "+_vm._s(offer.title)+" ")]),(offer.strikePrice != 'null')?_c('div',{staticClass:"newPackage-offer__content-subtitle",style:({ color: offer.textColor })},[_vm._v(" "+_vm._s(offer.extraText)+" "),_c('span',{staticClass:"strike-price"},[(!_vm.isArabic)?_c('span',[_vm._v(_vm._s(offer.currency))]):_vm._e(),_vm._v(" "+_vm._s(offer.strikePrice)+" ")]),(!_vm.isArabic)?_c('span',{staticClass:"price"},[_vm._v(" "+_vm._s(offer.currency)+" "+_vm._s(offer.price)+" ")]):_vm._e(),(_vm.isArabic)?_c('span',{staticClass:"price"},[_vm._v(" "+_vm._s(offer.price)+" "+_vm._s(offer.currency))]):_vm._e()]):_c('div',{staticClass:"newPackage-offer__content-subtitle",style:({ color: offer.textColor })},[(!_vm.isArabic)?_c('span',[_vm._v(_vm._s(offer.extraText)+" "+_vm._s(offer.currency)+" "+_vm._s(offer.price))]):_vm._e(),(_vm.isArabic)?_c('span',[_vm._v(_vm._s(offer.extraText)+" "+_vm._s(offer.price)+" "+_vm._s(offer.currency))]):_vm._e()]),_c('div',{staticClass:"newPackage-offer__content-description",style:({ color: offer.textColor })},[_vm._v(" "+_vm._s(offer.description)+" ")])]),(offer.buttonText)?_c('a',{staticClass:"newPackage-offer__content-button",class:{'btn':true ,'white': offer.buttonColor === 'white' ? true : false},attrs:{"href":offer.buttonURL},on:{"click":function($event){return _vm.sendGA(
              _vm.allDevice.ga.eventCategory,
              _vm.allDevice.ga.eventAction,
              _vm.allDevice.ga.eventLabel,
              _vm.allDevice.ga.eventValue
            )}}},[_c('span',{staticClass:"newPackage-offer__content-button__text",style:({ color: offer.buttonTextColor })},[_vm._v(_vm._s(offer.buttonText))])]):_vm._e()])])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }