var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[!_vm.isMobile ? 'grid-container no-padding' : ''],style:([
    _vm.isMobile ? { 'margin-top': '20px' } : null,
  ])},[_c('div',{staticClass:"newsim newsim-content",style:({ background: _vm.newSim.background })},[(_vm.isMobile)?_c('div',{staticClass:"newsim-content__preposition-usp"},_vm._l((_vm.newSim.features),function(feature,i){return _c('div',{key:i,staticClass:"newsim-content__preposition-usp__content"},[(feature.svg)?_c('img',{attrs:{"src":_vm.path+feature.svg.name,"alt":feature.svg.name,"width":24,"height":24}}):_vm._e(),_c('div',{staticClass:"newsim-content__preposition-usp__content-title",style:({ color: _vm.newSim.textColor })},[_vm._v(" "+_vm._s(feature.title)+" ")])])}),0):_vm._e(),_c('div',{staticClass:"newsim-content__image"},[_c('img',{staticStyle:{"mix-blend-mode":"multiply"},attrs:{"src":_vm.path+_vm.newSim.image,"alt":_vm.newSim.title,"width":"514","height":"350"}})]),_c('div',{staticClass:"newsim-content__preposition"},[(!_vm.isMobile)?_c('div',{staticClass:"newsim-content__preposition-usp"},_vm._l((_vm.newSim.features),function(feature,i){return _c('div',{key:i,staticClass:"newsim-content__preposition-usp__content"},[(feature.svg)?_c('img',{attrs:{"src":_vm.path+feature.svg.name,"alt":feature.svg.name,"width":24,"height":24}}):_vm._e(),_c('div',{staticClass:"newsim-content__preposition-usp__content-title",style:({ color: _vm.newSim.textColor })},[_vm._v(" "+_vm._s(feature.title)+" ")])])}),0):_vm._e(),_c('div',{staticClass:"newsim-content__preposition-title",style:({ color: _vm.newSim.textColor })},[_vm._v(" "+_vm._s(_vm.newSim.title)+" ")]),_c('div',{staticClass:"newsim-content__preposition-description",style:({ color: _vm.newSim.textColor })},[_vm._v(" "+_vm._s(_vm.newSim.description)+" ")]),(_vm.newSim.buttonText)?_c('a',{staticClass:"newsim-content__preposition-button",class:{'btn':true ,'white': _vm.newSim.buttonColor === 'white' ? true : false},attrs:{"href":_vm.newSim.buttonURL},on:{"click":function($event){return _vm.sendGA(
            _vm.newSim.ga.eventCategory,
            _vm.newSim.ga.eventAction,
            _vm.newSim.ga.eventLabel,
            _vm.newSim.ga.eventValue
          )}}},[_c('span',{staticClass:"newsim-content__preposition-button_title",style:({ color: _vm.newSim.buttonTextColor })},[_vm._v(_vm._s(_vm.newSim.buttonText))])]):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }