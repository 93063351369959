<template>
    <div class="body">
        <BannerSearch />
        <Announcements />
        <Footer />
        <Gradients />
    </div>
</template>
<script>
    
import BannerSearch from '../components/HelpSupport/BannerSearch';
import Announcements from '../components/HelpSupport/Announcements';
import Footer from '../components/Footer';
import Gradients from '../components/Gradients';

export default {
    name: 'SupportAnnouncements',
    data() {
        return {
        }
    },
    components: {
        BannerSearch,
        Announcements,
        Footer,
        Gradients,

    }
};
</script>
