<template>
    <section class="cardsList getHelp" v-if="getHelp.title">
        <div class="grid-container">
            <div class="grid-x grid-margin-x">
                <div class="cell">
                    <h2 class="headline3">{{getHelp.title}}</h2>
                    <div class="row20 show-for-medium"></div>
                </div>
            </div>
        </div>
        <div class="grid-container full-mobile">
            <div class="grid-x">
                <div class="cell">
                    <slick id="slick-addlife" :options="slickHelp" v-if="getHelp.cards">
                        <a :href="card.url" @click="sendGA('Help & Support' , 'Need more help', card.title, 0)" class="cardsList-card" v-for="(card, i) in getHelp.cards" :key="i+'card223'">
                            <div class="cardsList-card-image" :title="card.title" v-lazy:background-image="card.image">
                            </div>
                            <div class="cardsList-card-content">
                                <div>
                                    <h4 class="subtitle2">{{card.title}}</h4>
                                    <p class="link">{{card.description}}</p>
                                </div>
<!--                                <a :href="card.url" class="card-link" @click="sendGA('Help & Support' , 'Need more help', card.title, 0)">-->
<!--                                    {{card.link}}-->
<!--                                    <svg-icon icon-name="arrow" width="16" height="12"></svg-icon>-->
<!--                                </a>-->
                            </div>
                        </a>
                    </slick>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import Slick from 'vue-slick';
import apiHelp from '../../api-help'
import { Analytics } from "@/analytics.js";
// import SvgIcon from '@/components/SvgIcon'

export default {

    name: 'GetHelp',
    components: { Slick },
    data() {
        return {
            getHelp: [],
            slickHelp: {
                slidesToShow: 3,
                slidesToScroll: 1,
                dots: true,
                arrows: false,
                autoplay: false,
                speed: 500,
                infinite: true,
                cssEase: "linear",
                autoplaySpeed: 4000,
                pauseOnHover: true,
                centerMode: true,
                focusOnSelect: true,
                swipeToSlide: document.getElementsByTagName('html')[0].getAttribute('dir') === 'rtl' ? false : true,
                rtl: document.getElementsByTagName('html')[0].getAttribute('dir') === 'rtl' ? true : false,
                responsive: [{
                        breakpoint: 1024,
                        settings: {
                            slidesToShow: 2,
                            centerMode: true,
                        }
                    },
                    {
                        breakpoint: 767,
                        settings: {
                            slidesToShow: 1,
                            centerMode: true,
                            centerPadding: '20px'
                        }
                    },
                ]
            },
        }
    },
    mounted: function() {
        apiHelp.getHelp().then(res => { this.getHelp = res })
    },
    methods: {
        sendGA(eventCategory, eventAction, eventLabel, eventValue) {
            Analytics(eventCategory, eventAction, eventLabel, eventValue);
        }
    }
}
</script>
<style lang="scss">
.cardsList {
    

    &.getHelp {
        overflow: hidden;
        &:after {
            display: none;
        }

        .slick-slide {
            padding: 0;
            overflow: hidden;
        }
    }

    .slick-track {
        display: flex;

        .slick-slide {
            margin: 20px 10px;
            display: flex;
            height: auto;
            align-items: center;
            justify-content: center;
            transition: $transition;
            border-radius: 8px;
            background-color: $white;
            border: 1px solid $du-border-color;
            

            @media only screen and (min-width: $phone-max-size) {

                &:first-of-type {
                    [dir="ltr"] & {
                        margin-left: 0;
                    }

                    [dir="rtl"] & {
                        margin-right: 0;
                    }
                }

                &:last-of-type {
                    [dir="ltr"] & {
                        margin-right: 0;
                    }

                    [dir="rtl"] & {
                        margin-left: 0;
                    }
                }
            }

            &:hover {
                box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.15);
                border-color: transparent;

                @media only screen and (min-width: $phone-max-size) {
                    transform: translateY(-5px);
                }
            }

            @media only screen and (max-width: $phone-max-size) {
                margin-bottom: 30px;
            }

            &>div {
                //height: 100%;
                width: 100%;
            }
        }
    }

    .slick-list {
        @media only screen and (min-width: $phone-max-size) {
            padding: 0 !important;
            overflow: visible;
        }
    }

    &-card {
        //height: 100%;

        &-image {
            height: 160px;
            background-size: cover;
            background-position: center;
            background-color: $du-grey-70;
        }

        &-content {
            padding: 20px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            color: #333;
            //height: calc(100% - 230px);

            @media only screen and (max-width: $phone-max-size) {
                padding: 20px;
            }

            .subtitle1 {
                margin-bottom: 15px;
                line-height: 1.25;

                @media only screen and (max-width: $phone-max-size) {
                    font-size: $font-desktop-body-L;
                }
            }

            .subtitle2{
              font-size: 17px;
            }

            .link {
                margin-bottom: 0;
              font-size: 15px;
            }
        }
    }
}
</style>