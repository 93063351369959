<template>
    <section class="topicView bg-grey" style="padding-top: 0">
        <div class="topicView-inner">
          <div class="page-title">
            <div class="grid-container narrow">
              <div class="grid-x">
                <div class="cell small-12">
                  <div class="breadcrumb">
                    <ul class="breadcrumb-list" v-if="topicDetails.topic && content">
                      <li class="breadcrumb-item">
                        <a :href="content.helpURL" class="link du-magenta">{{this.helpText}}</a>
                      </li>
                      <li class="breadcrumb-item" v-if="parentTopic != null && content">
                        <a :href="content.topicsURL+parentTopicId+content.language" class="link du-magenta">{{parentTopic}}</a>
                      </li>
                      <li class="breadcrumb-item">
                        <a>{{topicDetails.topic.name}}</a>
                      </li>
                    </ul>
                  </div>
                  <h3 class="headline2">{{topicDetails.topic.name}}</h3>
                </div>
              </div>
            </div>
          </div>

          <div class="tab-wrapper" v-if="!isMobile">
            <div class="grid-container narrow">
              <div class="grid-x">
                <div class="cell small-12 d-flex flex-center flex-middle">
                  <a href="" @click.prevent="scrollRight()" v-if="horizScroll" class="scroll-right">
                    <img src="https://www.du.ae/servlet/duaediscovery/common/km/common/static/helpandsupport/img/chevron-left.svg" />
                  </a>
            <ul class="topicView-tab">
              <li  :class="{'topicView-tab-nav':true, 'active': isActive === topic.topic.id}" v-for="(topic,i) in topicDetails.topicTree" :key="i+'topic'" :data-id="topic.topic.id">
                <span @click="getSubtopic(topic.topic.id, topic.topic.name)">{{topic.topic.name}}</span>
              </li>
            </ul>
                  <a href="#" @click.prevent="scrollLeft()" v-if="horizScroll" class="scroll-light">
                    <img src="https://www.du.ae/servlet/duaediscovery/common/km/common/static/helpandsupport/img/chevron-right.svg" />
                  </a>
                </div>
              </div>
            </div>
          </div>


            <div class="grid-container narrow">
                <div class="grid-x">
                    <div class="cell small-12">
                        <div class="align-center">
                            <div v-if="topicDetails.topic && content">
                            <!--  is desktop  -->
                              <div v-if="!isMobile">
                                <div class="topicView-tab-content card p-0" v-if="checkLength()">
                                  <div class="topicView-tab-item active">
<!--                                      <span class="topicView-item-link subtitle2">{{topicDetails.topic.name}}</span>-->

                                        <ul class="p-0">
                                          <li v-for="(subtopic, i) in subTopics" :key="i+'subtopics'">
                                            <a class="link black underline" :href="content.articleURL+subtopic.alternateId+content.language" @click="sendGA('Help & Support', 'Article', subtopic.name, 0);">
                                              {{subtopic.name}}
                                              <img class="chevron" src="https://www.du.ae/servlet/duaediscovery/common/km/common/static/helpandsupport/img/chevron-right.svg" />
                                            </a>
                                          </li>
                                        </ul>

                                  </div>

                                </div>
                                <div v-else>
                                  <div class="topicView-tab-content card p-0">
                                    <div :class="{'topicView-tab-item':true, 'active': isActive === topic.topic.id}" v-for="(topic,i) in topicDetails.topicTree" :key="i+'topic'" :data-id="topic.topic.id">

                                        <ul class="p-0">
                                          <template v-if="subTopics.length > 0">
                                            <template v-for="subtopic in subTopics">
                                              <template v-if="subtopic.id === topic.topic.id">
                                                <li v-for="(element, i) in subtopic.elements" :key="i+topic.topic.id">
                                                  <template v-if="element.topic">
                                                    <a class="link" :href="content.topicsURL+element.topic.id+content.language+'&ptn='+topicDetails.topic.name+'&ptid='+topicDetails.topic.id" @click="sendGA('Help & Support', 'SubTopic', element.topic.name, 0);">
                                                      {{element.topic.name}}
                                                      <img class="chevron" src="https://www.du.ae/servlet/duaediscovery/common/km/common/static/helpandsupport/img/chevron-right.svg" />
                                                    </a>
                                                  </template>
                                                  <template v-else>
                                                    <a class="link" :href="content.articleURL+element.alternateId+content.language" @click="sendGA('Help & Support', 'Article', element.name, 0);">
                                                      {{element.name}}
                                                      <img class="chevron" src="https://www.du.ae/servlet/duaediscovery/common/km/common/static/helpandsupport/img/chevron-right.svg" />

                                                    </a>
                                                  </template>
                                                </li>
                                              </template>
                                            </template>
                                          </template>
                                          <template v-else>
                                            <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
                                          </template>
                                        </ul>

                                    </div>
                                  </div>
                                </div>

                              </div>
                            <!--  is mobile  -->
                              <div v-else>
                                <ul class="topicView-list" v-if="checkLength()">
                                    <li class="topicView-item active">
                                        <span class="topicView-item-link subtitle2">{{topicDetails.topic.name}}</span>
                                        <div class="topicView-item-list active">
                                            <ol>
                                                <li v-for="(subtopic, i) in subTopics" :key="i+'subtopic1'">
                                                    <a class="link black underline" :href="content.articleURL+subtopic.alternateId+content.language" @click="sendGA('Help & Support', 'Article', subtopic.name, 0);">
                                                        {{subtopic.name}}
                                                    </a>
                                                </li>
                                            </ol>
                                        </div>
                                        <span class="topicView-item-icon"></span>
                                    </li>
                                </ul>
                                <ul class="topicView-list" v-else>
                                    <li :class="{'topicView-item':true, 'active': isActive === topic.topic.id}" v-for="(topic,i) in topicDetails.topicTree" :key="i+'topic'" :data-id="topic.topic.id">
                                        <span class="topicView-item-link subtitle2" @click="getSubtopic(topic.topic.id, topic.topic.name)">{{topic.topic.name}}</span>
                                        <div :class="{'topicView-item-list':true, 'active': isActive === topic.topic.id}">
                                            <ol>
                                                <template v-if="subTopics.length > 0">
                                                    <template v-for="subtopic in subTopics">
                                                        <template v-if="subtopic.id === topic.topic.id">
                                                            <li v-for="(element, i) in subtopic.elements" :key="i+topic.topic.id">
                                                                <template v-if="element.topic">
                                                                    <a class="link black underline 2" :href="content.topicsURL+element.topic.id+content.language+'&ptn='+topicDetails.topic.name+'&ptid='+topicDetails.topic.id" @click="sendGA('Help & Support', 'SubTopic', element.topic.name, 0);">
                                                                        {{element.topic.name}}
                                                                    </a>
                                                                </template>
                                                                <template v-else>
                                                                    <a class="link black underline" :href="content.articleURL+element.alternateId+content.language" @click="sendGA('Help & Support', 'Article', element.name, 0);">
                                                                        {{element.name}}
                                                                    </a>
                                                                </template>
                                                            </li>
                                                        </template>
                                                    </template>
                                                </template>
                                                <template v-else>
                                                    <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
                                                </template>
                                            </ol>
                                        </div>
                                        <span class="topicView-item-icon"></span>
                                    </li>
                                </ul>
                              </div>
                            </div>
                            <div v-else>
                                <p class="headline2 rd-loader half">Topic Details</p>
                                <ul class="topicView-list">
                                    <li class="topicView-item">
                                        <span class="topicView-item-link subtitle2 rd-loader">Loader</span>
                                    </li>
                                    <li class="topicView-item">
                                        <span class="topicView-item-link subtitle2 rd-loader">Loader</span>
                                    </li>
                                    <li class="topicView-item">
                                        <span class="topicView-item-link subtitle2 rd-loader">Loader</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import apiHelp from '../../api-help'
import { Analytics } from "@/analytics.js";

export default {
    name: 'TopicView',
    props: ['content'],
    data() {
        return {
            id: new URL(location.href).searchParams.get('topicid'),
            parentTopic: new URL(location.href).searchParams.get('ptn'),
            parentTopicId: new URL(location.href).searchParams.get('ptid'),
            topicDetails: [],
            subTopics: [],
            isActive: 0,
            helpText: document.getElementsByTagName('html')[0].getAttribute('dir') === 'rtl' ? "المساعدة" : "Help",
            isLoaded: false,
            isMobile: window.innerWidth < 1024,
            horizScroll: false
        }
    },
    mounted() {
        if (this.id) {
                apiHelp.topicDetails(this.id).then(res => {
                  this.topicDetails = res.topicTree[0]
                    const subTopicId = this.topicDetails.topicTree[0].topic.id
                    this.getSubtopic(subTopicId)
                    // apiHelp.getSubTopics(this.topicDetails.topicTree[0].topic.id).then(response => {
                    //
                    //   if (response.article.length > 0) {
                    //     this.subTopics.push({ "id": this.topicDetails.topicTree[0].topic.id, "elements": response.article })
                    //   } else {
                    //     apiHelp.topicDetails(this.topicDetails.topicTree[0].topic.id).then(response => {
                    //
                    //       this.subTopics.push({ "id": this.topicDetails.topicTree[0].topic.id, "elements": response.topicTree[0].topicTree })
                    //     });
                    //   }
                    // })

                })
        }
      setTimeout(() => {
        this.checkScroll()
        console.log(this.horizScroll)
      },1000)
    },
    methods: {
        sendGA(eventCategory, eventAction, eventLabel, eventValue) {
            Analytics(eventCategory, eventAction, eventLabel, eventValue);
        },
        getSubtopic(id, name) {
            this.isActive === id ? this.isActive = 0 : this.isActive = id;
            this.sendGA('Help & Support', 'Topic', name, 0);

            if (!this.isLoaded) {
                var $this = this;

                this.topicDetails.topicTree.forEach(function(el) {

                    apiHelp.getSubTopics(el.topic.id).then(res => {
                      // console.log('topic details', res)
                        if (res.article.length > 0) {
                            $this.subTopics.push({ "id": el.topic.id, "elements": res.article })
                            // console.log('subtopic',$this.subTopics)
                        } else {
                            apiHelp.topicDetails(el.topic.id).then(res => {
                                $this.subTopics.push({ "id": el.topic.id, "elements": res.topicTree[0].topicTree })
                            });
                        }
                    })
                })
            }
            this.isLoaded = true;

        },
        checkLength() {
            if (JSON.parse(JSON.stringify(this.topicDetails.topicTree)).length === 0) {
                apiHelp.getSubTopics(this.topicDetails.topic.id).then(res => { this.subTopics = res.article })
                return true;
            } else {
                return false
            }
        },
      scrollLeft(){
        document.querySelector(".topicView-tab").scrollLeft += 200;
      },
      scrollRight(){
        document.querySelector(".topicView-tab").scrollLeft += -200;
      },
      checkScroll(){
        var inner = document.querySelector(".topicView-tab").offsetWidth
        var outer = document.querySelector(".tab-wrapper .grid-x").offsetWidth
        console.log('checkscroll', inner > outer)
        if(inner >= outer){
          this.horizScroll = true
        }
        else{
          this.horizScroll = false
        }

      }
    }
}
</script>
<style lang="scss">
body{
  img.chevron{
      [dir="rtl"] &{
        transform: scaleX(-1);
    }
  }
}
.topicView {
    .grid-x.gutter-20 {
      width: calc(100% + 40px) !important;
    }
    .p-0{
      padding: 0 !important;
    }
    .grid-container {
        display: flex;
        position: relative;
    }

    &-item {
        border: 1px solid $du-border-color;
        margin: 35px 0;
        border-radius: 10px;
        position: relative;
        overflow: hidden;
        background: white;
        &-link {
            display: block;
            padding: 30px;
            cursor: pointer;
        }

        &-list {
            overflow: hidden;
            max-height: 0px;
            // opacity: 0;
            transition: max-height .3s ease-out;

            &.active {
                max-height: 100vh;
                // opacity: 1;
                transition: max-height .3s ease-out;
                overflow: auto;
            }

            ol {
                padding: 30px !important;
                border-top: 1px solid $du-border-color;
                position: relative;
                min-height: 100px;


                @media only screen and (min-width: $phone-max-size) {
                    columns: 2;
                }

                a {
                    margin-bottom: 15px;
                    display: block;
                }
            }
        }

        &.active {
            .topicView-item-icon:after {
                height: 0;
            }
        }

        &-icon {
            position: absolute;
            height: 30px;
            width: 30px;
            border: 2px solid $primary-action-color;
            top: 45px;
            border-radius: 50%;
            transform: translateY(-50%);
            pointer-events: none;

            [dir="ltr"] & {
                right: 30px;
            }

            [dir="rtl"] & {
                left: 30px;
            }

            &:before {
                position: absolute;
                content: "";
                width: 12px;
                height: 2px;
                top: 50%;
                left: 50%;
                background-color: $primary-action-color;
                transform: translate(-50%, -50%);
                border-radius: 2px;
            }

            &:after {
                transition: .3s ease-out;
                position: absolute;
                content: "";
                width: 2px;
                height: 12px;
                top: 50%;
                left: 50%;
                background-color: $primary-action-color;
                transform: translate(-50%, -50%);
                border-radius: 2px;
            }
        }
    }

    .fadeHelp-enter-active,
    .fadeHelp-leave-active {
        transition: opacity .5s;
        transition-delay: 1s;
    }

    .fadeHelp-enter,
    .fadeHelp-leave-to {
        opacity: 0;
        transition-delay: 1s;
    }
.scroll-right{
  [dir="rtl"] &{
    order: 3;
  }
}
  .scroll-left{
    [dir="rtl"] &{
      order: 1;
    }
  }
  .topicView-tab{
    display: flex;
    margin-bottom: 0;
    overflow-x: auto;
    padding: 0 20px !important;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    [dir="rtl"] &{
      order: 2;
    }
    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }
    li{
      span{
        padding: 25px 16px;
        display: block;
      }
      &.active{
        span{
          color: $primary-action-color
        }
      }
    }
  }
  li.topicView-tab-nav{
    white-space: nowrap;
    cursor: pointer;
    &.active {
      position: relative;
      &:before{
        content: "";
        background: $primary-action-color;
        width: 100%;
        height: 4px;
        position: absolute;
        left: 0;
        bottom: 0;
      }
    }
  }

  .page-title{
    background: #fff;
    padding: 40px 0;
    color: #333;
  }
  .tab-wrapper{
    background: #fff;
    border-top: 1px solid #eee;
    a{
      flex-shrink: 0;
    }
  }
  .topicView-tab-content{
    margin-top: 30px;
    .topicView-tab-item{
      display: none;
      &.active {
        display: block;
        padding: 0 25px;
        > ul{
          padding: 0;
          li{
            a{
              padding: 25px 0;
              display: flex;
              justify-content: space-between;
              color: black;
            }
            & + li{
              border-top: 1px solid #ddd;
            }
          }

        }
      }
    }

  }

}
</style>