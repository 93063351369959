var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[!_vm.isMobile ? 'grid-container no-padding' : ''],style:([
    _vm.isMobile ? { 'margin-top': '20px' } : null,
  ])},[_c('div',{staticClass:"allDevice-content allDevice",style:({ background: _vm.allDevice.background })},[_c('div',{staticClass:"allDevice-usp"},_vm._l((_vm.allDevice.features),function(feature,i){return _c('div',{key:i,staticClass:"allDevice-usp--features"},[_c('img',{attrs:{"src":_vm.path+feature.svg.name,"alt":feature.svg.name,"width":feature.svg.width,"height":feature.svg.height}}),_c('div',{staticClass:"allDevice-usp--features_title",style:({ color: _vm.allDevice.color })},[_vm._v(" "+_vm._s(feature.title)+" ")])])}),0),_c('div',{staticClass:"cell"},[(_vm.allDevice.cards)?_c('slick',{attrs:{"id":"slick-alldevice","options":_vm.slickOptionsCards}},_vm._l((_vm.allDevice.cards),function(device,i){return _c('div',{key:i + 'key',staticClass:"allDevice-devices__device",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.redirectToDevice(device.url)}}},[_c('div',{staticClass:"allDevice-devices__device-title"},[_c('span',[_vm._v(_vm._s(device.title))]),_c('span',[_vm._v(_vm._s(device.subtitle))])]),(device.image)?_c('div',{staticClass:"allDevice-devices__device-image"},[_c('img',{attrs:{"src":_vm.path+device.image,"alt":device.title}})]):_vm._e(),_c('div',{staticClass:"allDevice-devices__device-price"},[_c('span',[_vm._v(_vm._s(device.startingText))]),(!_vm.isArabic)?_c('span',[_vm._v(_vm._s(device.currency)+" "+_vm._s(device.price)+" "+_vm._s(device.priceDetailsMode))]):_vm._e(),(_vm.isArabic)?_c('span',[_vm._v(" "+_vm._s(device.price)+" "+_vm._s(device.currency)+" "+_vm._s(device.priceDetailsMode)+" ")]):_vm._e()])])}),0):_vm._e()],1),(_vm.allDevice.buttonText)?_c('a',{staticClass:"allDevice-button",class:{'btn':true ,'white': _vm.allDevice.buttonColor === 'white' ? true : false},attrs:{"href":_vm.allDevice.buttonURL},on:{"click":function($event){return _vm.sendGA(
          _vm.allDevice.ga.eventCategory,
          _vm.allDevice.ga.eventAction,
          _vm.allDevice.ga.eventLabel,
          _vm.allDevice.ga.eventValue
        )}}},[_c('span',{staticClass:"allDevice-button-text",style:({ color: _vm.allDevice.buttonTextColor })},[_vm._v(" "+_vm._s(_vm.allDevice.buttonText)+" ")])]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }