var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[!_vm.isMobile ? 'grid-container no-padding' : ''],style:([
    _vm.isMobile ? { 'margin-top': '20px' } : null,
  ])},[_c('div',{staticClass:"latest-content latest-container",style:([_vm.latestDevice.background ? { backgroundColor: _vm.latestDevice.background } : null])},[_c('div',{staticClass:"latestDevice-content"},[(!_vm.isMobile)?_c('div',{staticClass:"latestDevice-content--feature",style:({ color: _vm.latestDevice.color })},_vm._l((_vm.latestDevice.features),function(feature,i){return _c('div',{key:i,staticClass:"feature-content"},[_c('img',{attrs:{"src":_vm.path+feature.svg.name,"alt":feature.svg.name,"width":feature.svg.width,"height":feature.svg.height}}),_c('div',{staticClass:"latestDevice-content--feature-title"},[_vm._v(" "+_vm._s(feature.title)+" ")])])}),0):_vm._e(),(_vm.latestDevice.title)?_c('div',{staticClass:"latestDevice-content--heading"},[_vm._v(" "+_vm._s(_vm.latestDevice.title)+" ")]):_vm._e(),(_vm.latestDevice.description)?_c('div',{staticClass:"latestDevice-content--description",domProps:{"innerHTML":_vm._s(_vm.latestDevice.description)}}):_vm._e(),(_vm.latestDevice.price && !_vm.isArabic)?_c('div',{staticClass:"latestDevice-content--price"},[_vm._v(" "+_vm._s(_vm.latestDevice.currency)+" "+_vm._s(_vm.latestDevice.price)+" "+_vm._s(_vm.latestDevice.priceDetailsMode)+" ")]):_vm._e(),(_vm.latestDevice.price && _vm.isArabic)?_c('div',{staticClass:"latestDevice-content--price"},[_vm._v(" "+_vm._s(_vm.latestDevice.price)+" "+_vm._s(_vm.latestDevice.priceDetailsMode)+" "+_vm._s(_vm.latestDevice.currency)+" ")]):_vm._e(),(_vm.latestDevice.buttonText)?_c('a',{class:{'btn':true ,'white': _vm.latestDevice.buttonColor === 'white' ? true : false},style:([_vm.isMobile ? {minWidth: '100%'} : null]),attrs:{"href":_vm.latestDevice.buttonURL},on:{"click":function($event){return _vm.sendGA(
              _vm.latestDevice.ga.eventCategory,
              _vm.latestDevice.ga.eventAction,
              _vm.latestDevice.ga.eventLabel,
              _vm.latestDevice.ga.eventValue
            )}}},[_c('span',{staticClass:"latestDevice-content--button-text",style:({ color: _vm.latestDevice.buttonTextColor })},[_vm._v(" "+_vm._s(_vm.latestDevice.buttonText)+" ")])]):_vm._e()]),(_vm.latestDevice.image)?_c('div',{staticClass:"latestDevice-image"},[(!_vm.isMobile)?_c('img',{attrs:{"src":_vm.path+_vm.latestDevice.image,"alt":"latest-device","width":"580","height":"420"}}):_vm._e(),(_vm.isMobile)?_c('img',{attrs:{"src":_vm.path+_vm.latestDevice.image,"alt":"latest-device","width":"337","height":"244"}}):_vm._e()]):_vm._e(),(_vm.isMobile)?_c('div',{staticClass:"latestDevice-content--feature",style:({ color: _vm.latestDevice.color })},_vm._l((_vm.latestDevice.features),function(feature,i){return _c('div',{key:i,staticClass:"feature-content",class:[_vm.isMobile && _vm.isArabic ? 'ml-auto' : 'mr-auto']},[_c('img',{attrs:{"src":_vm.path+feature.svg.name,"alt":feature.svg.name,"width":feature.svg.width,"height":feature.svg.height}}),_c('div',{staticClass:"latestDevice-content--feature-title"},[_vm._v(" "+_vm._s(feature.title)+" ")])])}),0):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }