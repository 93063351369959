<template>
    <section class="notification">
        <div class="grid-container narrow">
            <div class="grid-x">
                <div class="cell small-12">
                    <div class="breadcrumb" v-if="announcementsList.title">
                        <ul class="breadcrumb-list">
                            <li class="breadcrumb-item">
                                <a :href="announcementsList.helpURL" class="link du-magenta">{{announcementsList.helpTitle}}</a>
                            </li>
                            <li class="breadcrumb-item">
                                <a>{{announcementsList.title}}</a>
                            </li>
                        </ul>
                        <p class="headline2">{{announcementsList.title}}</p>
                        <div class="row30"></div>
                        <div class="notification-box" v-for="(message,i) in announcementsList.messages" v-bind:key="i+'notification'">
                            <svg-icon :class="'notification-box-icon ' + message.iconFill" :icon-name="message.icon"></svg-icon>
                            <div class="notification-box-message">
                                <p class="description1">{{message.title}}</p>
                                <p class="description2">{{message.time}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import apiHelp from '../../api-help'
import SvgIcon from '@/components/SvgIcon'

export default {
    name: 'Announcements',
    components: { SvgIcon },
    data() {
        return {
            announcementsList: [],
        }
    },
    mounted() {
        apiHelp.announcementsList().then(res => { this.announcementsList = res })
    }
}
</script>
<style lang="scss">
.notification {

    &-box {
        border: 1px solid $du-border-color;
        border-radius: 10px;
        margin-bottom: 20px;
        padding: 30px;

        @media only screen and (max-width: $phone-max-size) {
            padding: 15px;
        }

        &-icon,
        &-message {
            display: inline-block;
            vertical-align: middle;

            p {
                margin-bottom: 0;
            }
        }

        &-message {
            max-width: 80%;
        }

        &-icon {
            [dir="ltr"] & {
                margin-right: 15px;
            }

            [dir="rtl"] & {
                margin-left: 15px;
            }

            &.magenta {
                fill: $primary-action-color;
            }
        }

        .description2 {
            color: $du-grey-50;
        }
    }
}
</style>