<template>
    <section class="complaintView">
            <div class="grid-container narrow">
                      <div class="breadcrumb">
                        <ul class="breadcrumb-list" >
                          <li class="breadcrumb-item">
                            <a :href="isRTL ? '/ar/personal/support/complaints' :'/personal/support/complaints'" class="link du-magenta">{{isRTL ? 'شكاوي':'Complaints'}}</a>
                          </li>
                          <li class="breadcrumb-item">
                            <a>{{breadcrumb}}</a>
                          </li>
                        </ul>
                      </div>

                      <template v-if="topicDetails.length > 0">

                        <ul class="tabs">
                          <transition-group class="flex" name="fadeHelp" tag="li">
                          <li class="tab" :class="isActive === item.topic.id ? 'active':''" v-for="(item, index) in topicDetails" :key="index" :data-id="item.topic.id">
<!--                            <input type="radio" :id="'chck'+index" name="topicDetails">-->
<!--                            :for="'chck'+index"-->
                            <label class="tab-label"  @click="getSubTopic(item.topic.id)">{{item.topic.name}}</label>
                            <div class="cell small-12 tab-content pl30 pr30">
                                <ul v-if="childLength && selectedTab == item.topic.id">
                                  <li class="child-tab" :class="isActiveChild === article.id ? 'active':''"  v-for="(article, index) in subArticles" :key="index" :data-id="article.id">
                                    <label class="child-tab-label" @click="getArticleDetails(article.id)" >{{article.name}}</label>
                                    <div class="cell small-12 child-tab-content">
                                      <div v-if="articleData && selectedArticle == article.id">
                                            <div class="topicView-content" v-if="getArticle">
<!--                                              <h2 class="headline4">{{articleName}}</h2>-->
                                              <p v-html="getArticle"></p>
                                              <Feedback :articleId="articleId" />
                                            </div>
                                        <div class="cell small-12" v-else>
                                          <div class="text-center lds-ring-container">
                                            <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="cell small-12" v-else>
                                        <div class="text-center lds-ring-container">
                                          <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
                                        </div>
                                      </div>
                                    </div>

                                  </li>
                                </ul>



                                <div v-else-if="!childLength && selectedArticle == item.topic.id">

                                  <div class="topicView-content" v-if="getArticle">
                                    <h2 class="headline4">{{articleName}}</h2>
                                    <p v-html="getArticle"></p>
                                    <Feedback :articleId="articleId"/>
                                  </div>
                                  <div class="cell small-12" v-else>
                                    <div class="text-center lds-ring-container">
                                      <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
                                    </div>
                                  </div>
                                </div>
                              <div class="cell small-12" v-else>
                                <div class="text-center lds-ring-container">
                                  <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
                                </div>
                              </div>

                            </div>
                          </li>
                          </transition-group>
                        </ul>

                      </template>
                      <template v-else>
                        <div class="flex">
                          <div class="complaintTopics-box img rd-loader"></div>
                          <div class="complaintTopics-box img rd-loader"></div>
                          <div class="complaintTopics-box img rd-loader"></div>
                          <div class="complaintTopics-box img rd-loader"></div>
                        </div>
                      </template>
                    </div>
      <div class="text-center margin-top-50" :class="isMobile ? 'pl20 pr20':''">
        <button class="btn border btn-sm" @click.prevent="showChatbot()">{{issueBtnTxt}}</button>
      </div>
      <div id="rdbothelpBox" data-toggler=".open" class="rdhelp">
        <span class="closeChat" @click="closeChat()"></span>
        <iframe sandbox="allow-modals allow-same-origin allow-scripts allow-popups allow-forms" name="webchat"
                id="webchat" class="webchat" marginwidth="0" marginheight="0" scrolling="No" frameborder="0" hspace="0"
                vspace="0" src="https://www.du.ae/servlet/duaediscovery/common/build/index.html?locale=en"></iframe>
      </div>
    </section>




</template>
<script>
import apiHelp from '../../api-help'
import {Analytics} from '../../analytics';
// import axios from "axios";
import Feedback from "../Feedback"

export default {
    name: 'TopicView',
      components: {
        Feedback
      },
    props: ['content'],

    data() {
        return {
            id: new URL(location.href).searchParams.get('topicid'),
            parentTopic: new URL(location.href).searchParams.get('ptn'),
            parentTopicId: new URL(location.href).searchParams.get('ptid'),
            isRTL: document.getElementsByTagName('html')[0].getAttribute("dir") == 'rtl',
            topicDetails: [],
            getArticle: "",
            subArticles:[],
            articleName : "",
            isActive: 0,
            isActiveChild: 0,
            helpText: document.getElementsByTagName('html')[0].getAttribute('dir') === 'rtl' ? "المساعدة" : "Help",
            issueBtnTxt: document.getElementsByTagName('html')[0].getAttribute('dir') === 'rtl' ? "لدي مشكلة أخرى":"My issue isn't listed here",
            isLoaded: false,
            breadcrumb:"",
            articleData: false,
            childLength: false,
            articleId:'',
            selectedTab:'',
            selectedArticle:'',
            isMobile: window.innerWidth < 1024,


        }
    },
    mounted() {
        if(this.id){
          apiHelp.complaintTopicDetails(this.id).then(response => {

            this.breadcrumb = response.topicTree[0].topic.name
            this.topicDetails = response.topicTree[0].topicTree
            this.$emit("passData", { pageTitle: response.topicTree[0].topic.name, pageDescription: response.topicTree[0].topic.description });
          })
        }
    },

    methods: {
        sendGA(eventCategory, eventAction, eventLabel, eventValue) {
            Analytics(eventCategory, eventAction, eventLabel, eventValue);
        },
      showChatbot() {
        var _id = document.getElementById('rdbothelpBox');
        if ( _id.classList.contains('open') ) {
          _id.classList.remove('open');
        } else {
          _id.classList.add('open');
        }
      },
      closeChat(){
        var _id = document.getElementById('rdbothelpBox');
        _id.classList.remove('open');
      },
      getSubTopic(id) {

          this.isActive === id ? this.isActive = 0 : this.isActive = id;
          apiHelp.complaintTopicDetails(id).then(res => {
            if (res.topicTree[0].topicTree.length > 0) {
              this.childLength = true
              const subComplaintArticles = res.topicTree[0].topicTree
              this.subArticles = []
              subComplaintArticles.forEach(art => {
                  console.log(art.topic.parentTopicId)
                  this.selectedTab = art.topic.parentTopicId
                  this.subArticles.push(art.topic)
              })
            } else {
              this.childLength = false
              this.getArticleDetails(id)
            }


          })

      },
        getArticleDetails(id){
            // this.feedbackForm.isVisible = false
            apiHelp.getComplaintSubTopics(id).then(response => {

              this.isActiveChild === id ? this.isActiveChild = 0 : this.isActiveChild = id;
              // this.breadcrumb = response.article[0].topicBreadcrumb[0].topic[0].name
              this.selectedArticle = id
              this.articleId = response.article[0].alternateId
              this.getArticle = response.article[0].content.split('[profile:&lt;Consumer&gt;]').pop().split('&lt;/profile&gt;')[0].split('[profile:&lt;chatbot&gt;]')[0].split('[profile:&lt;Consumer&gt;,&lt;Enterprise&gt;]').pop()
              this.articleName = response.article[0].name

              if (this.getArticle) {
                this.articleData = true
              } else {
                this.articleData = false
              }
            })

        },


    }
}
</script>
<style lang="scss">

.complaintView {
  background: #F8F8F8;
  [dir=ltr] .body & {
    ul {
      padding-left: revert
    }
  }
  .breadcrumb-list{
    padding-left:0 !important;
  }

  [dir=rtl] .body & {
    ul {
      padding-right: revert
    }
  }

  &-item {
    border: 1px solid $du-border-color;
    margin: 35px 0;
    border-radius: 10px;
    position: relative;
    overflow: hidden;

    &-link {
      display: block;
      padding: 30px;
      cursor: pointer;
    }

    &-list {
      overflow: hidden;
      max-height: 0px;
      transition: max-height .3s ease-out;

      &.active {
        max-height: 100vh;
        transition: max-height .3s ease-out;
        overflow: auto;
      }

      ol {
        padding: 30px !important;
        border-top: 1px solid $du-border-color;
        position: relative;
        min-height: 100px;


        @media only screen and (min-width: $phone-max-size) {
          columns: 2;
        }

        a {
          margin-bottom: 15px;
          display: block;
        }
      }
    }

    &.active {
      .topicView-item-icon:after {
        height: 0;
      }
    }

    &-icon {
      position: absolute;
      height: 30px;
      width: 30px;
      border: 2px solid $primary-action-color;
      top: 45px;
      border-radius: 50%;
      transform: translateY(-50%);
      pointer-events: none;

      [dir="ltr"] & {
        right: 30px;
      }

      [dir="rtl"] & {
        left: 30px;
      }

      &:before {
        position: absolute;
        content: "";
        width: 12px;
        height: 2px;
        top: 50%;
        left: 50%;
        background-color: $primary-action-color;
        transform: translate(-50%, -50%);
        border-radius: 2px;
      }

      &:after {
        transition: .3s ease-out;
        position: absolute;
        content: "";
        width: 2px;
        height: 12px;
        top: 50%;
        left: 50%;
        background-color: $primary-action-color;
        transform: translate(-50%, -50%);
        border-radius: 2px;
      }
    }
  }

  .fadeHelp-enter-active,
  .fadeHelp-leave-active {
    transition: opacity .5s;
    transition-delay: 1s;
  }

  .fadeHelp-enter,
  .fadeHelp-leave-to {
    opacity: 0;
    transition-delay: 1s;
  }

  .tabs {
    padding: 0 !important;
    .tab {
      width: 100%;
      overflow: hidden;
      background: #FFFFFF;
      border: 1px solid #DDDDDD;
      border-radius: 6px;
      margin-bottom: 24px;
      padding-left: 0;
      cursor: pointer;

      input[type=radio] {
        display: none;
      }

      &-label {
        display: flex;
        justify-content: space-between;
        font-weight: bold;
        cursor: pointer;
        height: 76px;
        display: flex;
        align-items: center;
        padding: 0 24px;
        font-size: 20px;
        /* Icon */
        &:hover {
          background: #f1f1f1;
        }

        &::after {
          content: "\2039";
          width: 1em;
          height: 1em;
          text-align: center;
          transition: all .35s;
          color: $primary-action-color;
          transform: rotate(90deg);
          font-size: 30px;

          [dir='ltr'] &{
            transform: rotate(-90deg);
          }
        }
      }

      .child-tab {
        .child-tab-label {
          cursor: pointer;
          border: none;
          height: 74px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-top: 1px solid #eee;
          font-weight: 700;

          &:hover {
            color: $primary-action-color;
          }

          &::after {
            content: "+";
            color: #000;
            font-weight: bold;
            font-size: 24px;
            width: 30px;
          }
        }

        &.active {
          .child-tab-label::after {
            content: "-";
            font-size: 30px
          }

          .child-tab-content {
            max-height: 100vh;
            overflow: auto;
          }
        }
      }


      &-content, .child-tab-content {
        max-height: 0;
        background: white;
        //transition: all .35s;
        overflow: hidden;

        > ul {
          padding: 0;
          li{
            padding: 2px 0 !important;
          }
        }

        > div {
          background: #f8f8f8;
          border-radius: 5px;
          padding: 20px;
          margin-bottom: 30px;
        }
      }

      &-close {
        display: flex;
        justify-content: flex-end;
        padding: 1em;
        font-size: 0.75em;
        background: #ddd;
        cursor: pointer;

        &:hover {
          background: #333;
        }
      }

      &.active {
        .tab-label {
          width: 100%;
          display: flex;

          &::after {
            transform: rotate(-90deg);
            [dir='ltr'] & {
              transform: rotate(90deg)
            }
          }
        }

        .tab-content {
          max-height: 100vh;
          overflow: auto;
        }
      }
    }
  }
}
</style>