<template>
    <div class="body">
        <PageTitle :page-title="personInfo.pageTitle" :page-description="personInfo.pageDescription" />
        <ComplaintView @passData="GetData($event)"/>
        <Footer />
    </div>
</template>
<script>
import PageTitle from "@/components/PageTitle";
import ComplaintView from '../components/Complaint/ComplaintView';
import Footer from '../components/Footer';

import apiHelp from '../api-help'

export default {
    name: 'ComplaintCategory',
    data() {
        return {
            contentMisc: [],
            pageTitle:'',
            pageDescription:'',
            personInfo:''
        }
    },
    mounted() {
        apiHelp.contentMisc().then(res => { this.contentMisc = res });
        this.GetData()
    },
    components: {
      PageTitle,
      ComplaintView,
      Footer
    },
  methods: {
    GetData(data) {
      this.personInfo = data;
    },
  },
};
</script>
<style lang="scss">
.complaintTopics-box.img {
  padding: 0;
  -webkit-transition: 0.3s ease-out;
  transition: 0.3s ease-out;
  width: 100%;
  height: 100px;
  margin-bottom: 20px;
  border-radius: 6px;
}
</style>