import $ from 'jquery';

var AccessibilityController = (function () {

    var init = function () {

        initializeControls();

        accessibilityCheck();
        accessibilityZoomCheck();
    };

    var initializeControls = function () {

        $('.accessibility-toggle').click(function () {
            $('.accessibility-pane').toggleClass('open');
            toggleAccessibilityLinkOpen();

            if ($('.accessibility-pane').hasClass('open')) {
                $('.searchbox-arrow').removeClass('open');
                $('.top-search-area').removeClass('open');
            }
        });

        $('.accessibility-toggle-mobile').click(function () {
            $('.accessibility-pane').toggleClass('open');
            toggleAccessibilityLinkOpen();

            if ($('.accessibility-pane').hasClass('open')) {
                $('.searchbox-arrow').removeClass('open');
                $('.top-search-area').removeClass('open');
            }

            $('#toggleMenuPane').trigger('click');
        });

        $('.accessibility-pane').on('click', '.close-button', function () {

            $(this).closest('.accessibility-pane').removeClass('open');
            toggleAccessibilityLinkOpen();
        });

        $('.accessibility-zoom-decrease').on('click', accessibilityDecreaseZoom);

        $('.accessibility-zoom-increase').on('click', accessibilityIncreaseZoom);

        $('.contrast-switch-toggle').on('click', function () {

            accessibilityToggle('ColorInversion');
            accessibilityCheck();

        })

    };

    function readCookie(cname) {
        var name = cname + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    function writeCookie(cname, cvalue, exdays) {
        var d = new Date();
        d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
        var expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    /*function addClassByElemName(elementName, className) {
        document.getElementsByTagName(elementName)[0].className += ' ' + className;
    }

    var removeClassByElemName = function (elementName, className) {

        document.getElementsByTagName(elementName)[0].className = document.getElementsByTagName(elementName)[0].className.replace(new RegExp('\\b' + className + '\\b'), '');
    }

    var addClassByElemId = function (elementId, className) {
        if (!document.getElementById(elementId)) {
            return;
        }

        if (document.getElementById(elementId).className.indexOf(className) < 0) {
            document.getElementById(elementId).className += ' ' + className;
        }
    }

    var removeClassByElemId = function (elementId, className) {
        if (!document.getElementById(elementId)) {
            return;
        }

        document.getElementById(elementId).className = document.getElementById(elementId).className.replace(new RegExp('\\b' + className + '\\b'), '');
    }*/

    var toggleAccessibilityLinkOpen = function () {
        if ($('.accessibility-pane').hasClass('open') && !$("a.top-pane-link.accessibility-toggle").hasClass('open')) {
            $("a.top-pane-link.accessibility-toggle").addClass('open');
        } else if (!$('.accessibility-pane').hasClass('open') && $("a.top-pane-link.accessibility-toggle").hasClass('open')) {
            $("a.top-pane-link.accessibility-toggle").removeClass('open');
        }
    };

    var accessibilityCheck = function () {
        var var_Accessibility = readCookie('Accessibility');

        switch (var_Accessibility) {
            case 'ColorInversion':
                {
                    //addClassByElemId('mainNav', 'colorInversion');
                    $('body').addClass('colorInversion');
                    /*addClassByElemId('accountPane', 'colorInversion');
                    addClassByElemId('topBar', 'colorInversion');
                    addClassByElemId('content', 'colorInversion');*/
                    //document.body.style.backgroundColor = '#000';
                }
                break;
            default:
                {
                    //removeClassByElemId('mainNav', 'colorInversion');
                    $('body').removeClass('colorInversion');
                    /*removeClassByElemId('accountPane', 'colorInversion');
                    removeClassByElemId('topBar', 'colorInversion');
                    removeClassByElemId('content', 'colorInversion');*/
                    //document.body.style.backgroundColor = 'transparent';
                }
        }
    }

    var accessibilitySetup = function (schemeName) {
        writeCookie('Accessibility', schemeName, 7);
    }

    var accessibilityToggle = function (schemeName) {
        var var_SchemeName = readCookie('Accessibility');

        if (var_SchemeName != schemeName) {
            accessibilitySetup(schemeName);
        } else {
            accessibilitySetup('None');
        }
    }

    var accessibilityZoomCheck = function () {
        var zoomLevel = readCookie('AccessibilityZoom');

        if (zoomLevel) {
            accessibilityZoom(zoomLevel);
        }
    }

    var getCurrentZoom = function () {
        var result = 100;
        if ($.trim(document.body.style.zoom).length > 0) {
            var zoomMatch = document.body.style.zoom.match('[0-9]+');
            if (zoomMatch.length > 0) {
                result = parseInt(zoomMatch[0]);
            }
        }

        return result;
    }

    var accessibilityIncreaseZoom = function () {
        var zoom = getCurrentZoom() + 15;

        accessibilityZoom(zoom);
    }

    var accessibilityDecreaseZoom = function () {
        var zoom = getCurrentZoom() - 15;

        accessibilityZoom(zoom);
    }

    var accessibilityZoom = function (zoomLevel) {
        var zoom = Math.max(100, Math.min(130, zoomLevel));

        $('.accessibility-zoom-decrease').removeClass('disabled');
        $('.accessibility-zoom-increase').removeClass('disabled');
        if (zoom === 100) {
            $('.accessibility-zoom-decrease').addClass('disabled');
        } else if (zoom === 130) {
            $('.accessibility-zoom-increase').addClass('disabled');
        }

        writeCookie('AccessibilityZoom', zoom, 7);

        document.body.style.zoom = zoom + '%';
    }

    return {
        init: init,
    }
})();
$(function () {
    AccessibilityController.init();
});
