<template>
  <div
     :class="[!isMobile ? 'grid-container no-padding' : '']" 
    :style="[
      isMobile ? { 'margin-top': '20px' } : null,
    ]"
  >
    <!-- <div class="latest-container"> -->
      <div class="latest-content latest-container" :style="[latestDevice.background ? { backgroundColor: latestDevice.background } : null]">
        <div class="latestDevice-content">
          <div
            class="latestDevice-content--feature"
            :style="{ color: latestDevice.color }"
            v-if="!isMobile"
          >
            <div
              v-for="(feature, i) in latestDevice.features"
              :key="i"
              class="feature-content"
            >
              <img
                :src="path+feature.svg.name"
                :alt="feature.svg.name"
                :width="feature.svg.width"
                :height="feature.svg.height"
              />
              <div class="latestDevice-content--feature-title">
                {{ feature.title }}
              </div>
            </div>
          </div>

          <div class="latestDevice-content--heading" v-if="latestDevice.title">
            {{ latestDevice.title }}
          </div>

          <div
            class="latestDevice-content--description"
            v-if="latestDevice.description"
            v-html="latestDevice.description"
          ></div>

          <div
            class="latestDevice-content--price"
            v-if="latestDevice.price && !isArabic"
          >
            {{ latestDevice.currency }} {{ latestDevice.price }}
            {{ latestDevice.priceDetailsMode }}
          </div>

          <div
            class="latestDevice-content--price"
            v-if="latestDevice.price && isArabic"
          >
            {{ latestDevice.price }} {{ latestDevice.priceDetailsMode }}
            {{ latestDevice.currency }}
            <!-- {{ latestDevice.currency }} {{ latestDevice.price }} {{ latestDevice.priceDetailsMode }} -->
          </div>

          <a
            :href="latestDevice.buttonURL"
            :style="[isMobile ? {minWidth: '100%'} : null]"
            :class="{'btn':true ,'white': latestDevice.buttonColor === 'white' ? true : false}"
            v-if="latestDevice.buttonText"
            @click="
              sendGA(
                latestDevice.ga.eventCategory,
                latestDevice.ga.eventAction,
                latestDevice.ga.eventLabel,
                latestDevice.ga.eventValue
              )
            "
          >
            <span
              :style="{ color: latestDevice.buttonTextColor }"
              class="latestDevice-content--button-text"
            >
              {{ latestDevice.buttonText }}
            </span>
          </a>
        </div>

        <div class="latestDevice-image" v-if="latestDevice.image">
          <img
            v-if="!isMobile"
            :src="path+latestDevice.image"
            alt="latest-device"
            width="580"
            height="420"
          />
          <img
            v-if="isMobile"
            :src="path+latestDevice.image"
            alt="latest-device"
            width="337"
            height="244"
          />
        </div>

        <div
          class="latestDevice-content--feature"
          :style="{ color: latestDevice.color }"
          v-if="isMobile"
        >
          <div
            v-for="(feature, i) in latestDevice.features"
            :key="i"
            class="feature-content"
            :class="[isMobile && isArabic ? 'ml-auto' : 'mr-auto']"
          >
            <img
              :src="path+feature.svg.name"
              :alt="feature.svg.name"
              :width="feature.svg.width"
              :height="feature.svg.height"
            />
            <div class="latestDevice-content--feature-title">
              {{ feature.title }}
            </div>
          </div>
        </div>
      </div>
    <!-- </div> -->
  </div>
</template>

<script>
import api from "@/api";
import { Analytics } from "@/analytics.js";

export default {
  name: "LatestDevice",
    props:{
        path: {
            type: String,
            default: process.env.VUE_APP_PATH
        }
    },
  data: () => ({
    latestDevice: [],
    isMobile: window.matchMedia("only screen and (max-width: 767px)").matches,
    isArabic:
      document.getElementsByTagName("html")[0].getAttribute("dir") === "rtl",
  }),
  mounted() {
    api.latestDevice().then((response) => {
      this.latestDevice = response;
    });

    this.$nextTick(() => {
      window.addEventListener("resize", this.checkIsMobile, { passive: true });
    });
  },
  methods: {
    checkIsMobile() {
      this.isMobile = window.matchMedia(
        "only screen and (max-width: 767px)"
      ).matches;
    },
    sendGA(eventCategory, eventAction, eventLabel, eventValue) {
      Analytics(eventCategory, eventAction, eventLabel, eventValue);
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.checkIsMobile);
  },
};
</script>

<style scoped lang="scss">
.ml-auto {
  margin-left: auto;
}

.mr-auto {
  margin-right: auto;
}

.no-padding {
  padding: 0px !important;
}

.latest-content {
  display: flex;
  justify-content: center;
  align-items: center;
  // width: 90%;
  gap: 24px;
  border-radius: 12px;

  @media only screen and (max-width: $phone-max-size) {
    justify-content: center;
    align-items: flex-start;
    flex-direction: column-reverse;
    gap: 20px;
    width: 100%;
    border-radius: 0px;
  }
}
.latestDevice {
  border-radius: 12px;
  padding: 72px;
  gap: 32px;
  box-shadow: 0px 6px 12px 0px #00000005;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: $phone-max-size) {
    padding: 24px 20px 32px 20px;
    gap: 20px;
    flex-direction: column;
    align-items: center;
  }

  &-image {
    width: 50%;
    @media only screen and (max-width: $phone-max-size) {
      width: 100%;
    }
  }

  &-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    flex: 1 0 0;
    width: 50%;

    @media only screen and (max-width: $phone-max-size) {
      gap: 20px;
      width: 100%;
    }

    &--feature {
      display: flex;
      flex-direction: row;
      gap: 48px;

      @media only screen and (max-width: $phone-max-size) {
        justify-content: space-between;
        align-items: flex-start;
        gap: 24px;
        width: 100%;
      }

      &-title {
        font-family: $du-font-secondary-regular;
        font-size: 17px;
        line-height: 24px;

        [dir="rtl"] & {
          font-family: $du-font-secondary-regular-ar;
        }
      }
    }

    &--heading {
      font-family: $du-font-secondary-bold;
      font-size: 47px;
      line-height: 54px;

      [dir="rtl"] & {
        font-family: $du-font-secondary-bold-ar;
      }

      @media only screen and (max-width: $phone-max-size) {
        font-size: 36px;
        line-height: 44px;
      }
    }

    &--description {
      font-family: $du-font-secondary-medium;
      font-size: 20px;
      line-height: 28px;

      [dir="rtl"] & {
        font-family: $du-font-secondary-medium-ar;
      }
    }

    &--price {
      font-family: $du-font-secondary-bold;
      font-size: 24px;
      line-height: 32px;

      [dir="rtl"] & {
        font-family: $du-font-secondary-bold-ar;
      }
    }

    &--button {
      width: 240px;
      padding: 16px 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border-radius: 12px;
      box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);

      @media only screen and (max-width: $phone-max-size) {
        width: 100%;
      }

      &-text {
        font-family: $du-font-secondary-medium;
        font-size: 20px;
        line-height: 24px;

        [dir="rtl"] & {
          font-family: $du-font-secondary-medium-ar;
        }
      }
    }
  }
}

.latest-container {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  padding: 72px;
  gap: 32px;
  box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.02);
  // background: #ffffff;

  @media only screen and (max-width: $phone-max-size) {
    // width: 375px;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column-reverse;
    padding: 24px 20px 32px 20px;
    border-radius: 0px;
  }
}

.feature-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;

  @media only screen and (max-width: $phone-max-size) {
    // margin-left: auto;
  }
}
.gradient {
  background: linear-gradient(60deg, #753bbd 0%, #c724b1 60%);
}

section {
  padding-top: 0;
  padding-bottom: 0;
}
</style>
