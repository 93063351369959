<template>
  <svg :class="svgClass" aria-hidden="true" :width="width" :height="height" >
    <use :xlink:href="name"></use>
  </svg>

</template>

<script>
const requireAll = requireContext => requireContext.keys().map(requireContext)
const req = require.context('../', true, /\.svg$/);

// console.log(requireAll(req))
requireAll(req);

import * as axios from 'axios'
let isIE11 = !!window.MSInputMethodContext && !!document.documentMode
let url = `${process.env.BASE_URL}img/svg-sprite-icons.svg`

if (isIE11) {
  axios.get(url)
    .then(function (response) {
      console.log("IE11: ", response)
      var div = document.createElement('div')
      div.innerHTML = response.data
      div.style.display = 'none'
      document.body.insertBefore(div, document.body.childNodes[0])
      // add .ie to <body>
      document.body.classList.add('ie')
    })
}

export default {
  name: 'SvgIcon',
   data() {
        return {
          baseURL: process.env.BASE_URL
        }
      },
  props: {
    iconName: {
      // icon filename
      type: String,
      required: true,
    },
    className: {
      // css class name
      type: String,
      default: '',
    },
     width: {
      // css class name
      default: '40px',
    },
     height: {
      // css class name
      default: '40px',
    }
  },
  computed: {
    name() {
      let icon = this.iconName
      // return icon ? `/svg-sprite-icons.svg#icon-${icon}-usage` : ''
      return icon && !isIE11 ? `${this.baseURL}img/svg-sprite-icons.svg#icon-${icon}-usage` : '#icon-'+icon

    },
    svgClass() {
      let className = this.className
      return className ? `svg-icon ${className}` : 'svg-icon'
    },
  },
}
</script>

<style>
.svg-icon {
  vertical-align: middle;
  overflow: hidden;
  transition: .3s ease-out;
  fill: black;
}
</style>