<template>
  <div
    :class="[!isMobile ? 'grid-container no-padding' : '']"
    :style="[
      isMobile ? { 'margin-top': '20px' } : { 'margin-top': '72px' },
    ]"
  >
      <div
        class="duAppContainer duapp"
        :style="[
          { backgroundColor: duApp.background },
          isMobile
            ? {
                background: 'url(' + path+duApp.backgroundURLMobile + ') center / cover no-repeat'
              }
            : {
                background: 'url(' + path+duApp.backgroundURL + ') center / cover no-repeat'
              },
        ]"
      >
        <div class="duapp-content">
          <div class="duapp-content__container">
            <div
              class="duapp-content__title"
              :style="{ color: duApp.textColor }"
            >
              {{ duApp.title }}
            </div>
            <div
              class="duapp-content__description"
              :style="{ color: duApp.textColor }"
            >
              {{ duApp.description }}
            </div>
          </div>

          <div class="duapp-content__store">
            <a
              v-for="(store, i) in duApp.stores"
              :key="i"
              :href="store.url"
              @click="
                sendGA(
                  store.ga.eventCategory,
                  store.ga.eventAction,
                  store.ga.eventLabel,
                  store.ga.eventValue
                )
              "
            >
              <span
                class="svg-bg-icon"
                :title="store.title"
                :style="[ !isMobile ? {
                  backgroundImage: 'url(' + path+store.image + ')',
                } : {
                  backgroundImage: 'url(' + path+store.mobileImage + ')',
                }]"
              ></span>
            </a>
          </div>
        </div>
      </div>

    <div class="grid-container full-mobile rating">
      <div class="grid-x">
        <div class="cell" v-if="duApp.rating">
          <slick id="slick-duapp" :options="slickOptionsCards">
            <div
              class="rating-content"
              v-for="(rating, i) in duApp.rating"
              :key="i + 'key'"
            >
              <div class="rating-content__title">{{ rating.name }}</div>

              <div class="rating-content__star">
                <img
                  src="@/assets/star.svg"
                  alt="rating star"
                  height="24"
                  width="24"
                  v-for="i in parseInt(rating.rating)"
                  :key="i"
                />
              </div>
              <div class="rating-content__comment" style="margin-bottom: 20px;">
                {{ rating.comment }}
              </div>
            </div>
          </slick>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api";
import { Analytics } from "@/analytics.js";
import Slick from "vue-slick";

export default {
  name: "DuApp",
  components: { Slick },
    props:{
        path: {
            type: String,
            default: process.env.VUE_APP_PATH
        }
    },
  data: () => ({
    duApp: [],
    isMobile: window.matchMedia("only screen and (max-width: 767px)").matches,
    spriteURL: process.env.BASE_URL + "img/svg-sprite-icons.svg#icon-",
    isArabic:
      document.getElementsByTagName("html")[0].getAttribute("dir") === "rtl",
    slickOptionsCards: {
      slidesToShow: 4.2,
      arrows: false,
      slidesToScroll: 4,
      centerMode: false,
      dots: true,
      infinite: false,
      cssEase: "linear",
      rtl:
        document.getElementsByTagName("html")[0].getAttribute("dir") === "rtl"
          ? true
          : false,
      responsive: [
        {
          breakpoint: 1057,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 660,
          settings: {
            slidesToShow: 1.5,
            slidesToScroll: 1,
          },
        },
      ],
    },
  }),
  mounted() {
    api.duApp().then((response) => {
      this.duApp = response;
    });

    this.$nextTick(() => {
      window.addEventListener("resize", this.checkIsMobile, { passive: true });
    });
  },
  methods: {
    checkIsMobile() {
      this.isMobile = window.matchMedia(
        "only screen and (max-width: 767px)"
      ).matches;
    },
    sendGA(eventCategory, eventAction, eventLabel, eventValue) {
      Analytics(eventCategory, eventAction, eventLabel, eventValue);
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.checkIsMobile);
  },
};
</script>

<style scoped lang="scss">
.no-padding {
    padding: 0px !important;
}

.duAppContainer {
    padding: 72px 0px 8px 0px;

    @media only screen and (max-width: $laptop-min-size) {
        padding: 0px;
    }
}

.duapp {
    height: 546px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: start;
    border-radius: 12px;

    @media only screen and (max-width: $laptop-min-size) {
        padding: 31px 17px;
        height: 616px;
        border-radius: 0;
        align-items: flex-start;
    }

    &-content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
        width: 520px;
        padding-left: 72px;
        padding-right: 0px;

        [dir="rtl"] & {
            padding-right: 72px;
            padding-left: 0px;
        }

        @media only screen and (max-width: $laptop-min-size) {
            padding-left: 0 !important;
            padding-right: 0 !important;
            width: 100%;
        }

        &__container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 16px;
        }

        @media only screen and (max-width: $laptop-min-size) {
            // width: 100%;
            gap: 20px;
            justify-content: center;
            align-items: flex-start;
            text-align: center;
        }

        &__title {
            font-family: $du-font-secondary-bold;
            font-size: 48px;
            line-height: 54px;

            [dir="rtl"] & {
                font-family: $du-font-secondary-bold-ar;
            }

            @media only screen and (max-width: $laptop-min-size) {
                font-size: 36px;
                line-height: 44px;
            }
        }

        &__description {
            font-family: $du-font-secondary-medium;
            font-size: 20px;
            line-height: 28px;
            color: #f4d3ef !important;

            [dir="rtl"] & {
                font-family: $du-font-secondary-medium-ar;
            }
        }

        &__store {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 16px;

            @media only screen and (max-width: $laptop-min-size) {
                gap: 8px;
            }
        }
    }

    &-imagecontainer {
        position: relative;

        @media only screen and (max-width: $laptop-min-size) {
            position: absolute;
            height: 100%;
        }

        &-image1 {
            position: absolute;
            right: 50%;
            top: 19%;
            z-index: 0;

            @media only screen and (max-width: $laptop-min-size) {
                top: 55%;
            }
        }

        &-image2 {
            position: relative;
            z-index: 1;

            @media only screen and (max-width: $laptop-min-size) {
                top: 43%;
                left: 15%;
            }
        }
    }
}

.svg-bg-icon {
    width: 136px;
    height: 40px;
    display: inline-block;
    vertical-align: middle;

    @media only screen and (max-width: $laptop-min-size) {
        width: 110px !important;
        height: 32px !important;
    }
}

.rating {
    position: relative;
    bottom: 150px;
    margin-bottom: -110px;
    z-index: 2;

    &::v-deep .slick-track {
        display: flex;
        gap: 24px;

        .slick-slide {
            margin-bottom: 24px;
            display: flex;
            height: auto;
            align-items: center;
            justify-content: center;
            transition: $transition;
            border-radius: 8px;
            background-color: $white;
            overflow: hidden;
            border-radius: 12px;
            box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.1);
            width: 240px;
            height: 200px;

            @media only screen and (min-width: $phone-max-size) {
                &:first-of-type {
                    [dir="ltr"] & {
                        margin-left: 0;
                    }

                    [dir="rtl"] & {
                        margin-right: 0;
                    }
                }

                &:last-of-type {
                    [dir="ltr"] & {
                        margin-right: 0;
                    }

                    [dir="rtl"] & {
                        margin-left: 0;
                    }
                }
            }

            @media only screen and (max-width: $laptop-min-size) {
                margin-bottom: 30px;
            }

            & > div {
                //height: 100%;
                width: 100%;
                border-radius: 12px;
            }
        }
    }

    .slick-list {
        @media only screen and (min-width: $laptop-min-size) {
            padding: 0 !important;
            overflow: visible;
        }
    }

    &-content {
        display: flex !important;
        height: 240px !important;
        padding: 40px 16px;
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
        border-radius: 12px;
        background: #ffffff;
        box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.1);

        @media only screen and (max-width: $laptop-min-size) {
            width: 100% !important;
        }

        &__title {
            font-family: $du-font-secondary-bold;
            font-size: 20px;
            line-height: 24px;

            [dir="rtl"] & {
                font-family: $du-font-secondary-bold-ar;
            }
        }

        &__star {
            display: flex;
            align-items: flex-start;
            gap: 8px;
        }

        &__comment {
            font-family: $du-font-secondary-regular;
            font-size: 17px;
            line-height: 24px;

            [dir="rtl"] & {
                font-family: $du-font-secondary-regular-ar;
            }
        }
    }
}
</style>
