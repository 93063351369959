<template>
  <div
    :class="[!isMobile ? 'grid-container no-padding' : '']"
    :style="[
      isMobile ? { 'margin-top': '20px' } : null,
    ]"
  >
    <div
      class="specialoffer specialoffer-content"
      :style="{background: specialOffer.background }"
    >
      <div
        class="specialoffer-content--cards"
        v-for="(card, i) in specialOffer.cards"
        :key="i"
        :class="[!card.image && card.background]"
        :style="[
          card.image && !isMobile
            ? {
                background: 'url(' + path+card.image + ')',
                position: 'relative',
              }
            : card.mobileimage && isMobile
            ? {
                background: 'url(' + path+card.mobileimage + ')',
                backgroundPosition: 'right',
                backgroundSize: 'cover',
              }
            : null,
        ]"
      >
        <div
          v-if="card.image"
          class="specialoffer-content--cards__imageContent"
        >
          <div class="specialoffer-content--cards__imageContent-container">
            <div
              class="specialoffer-content--cards__imageContent-title"
              :style="{ color: card.textColor }"
            >
              {{ card.title }}
            </div>
            <div
              class="specialoffer-content--cards__imageContent-subtitle"
              :style="{ color: card.textColor }"
              v-if="card.first3characterbackground"
            >
              <span
                class="first3character"
                :class="card.first3characterbackgroundColor"
                :style="{ color: card.first3characterTextColor }"
              >
                {{ card.first3character }}
              </span>
              {{ card.subtitle }}
            </div>
            <div
              class="specialoffer-content--cards__imageContent-subtitle"
              :style="{ color: card.textColor }"
              v-else
            >
              {{ card.subtitle }}
            </div>
            <div
              class="specialoffer-content--cards__imageContent-description"
              :style="{ color: card.textColor }"
            >
              {{ card.description }}
            </div>
          </div>

          <a
            :href="card.buttonURL"
            class="specialoffer-content--cards__imageContent-button"
            v-if="card.buttonText"
            :class="{'btn':true ,'white': card.buttonColor === 'white-gradient' ? true : false}"
          >
            <span
              :style="{ color: card.buttonTextColor }"
              class="specialoffer-content--cards__imageContent-button__text"
            >
              {{ card.buttonText }}
            </span>
          </a>
        </div>

        <div v-else class="specialoffer-content--cards__logoContainer">
          <img
            :src="path+card.logo"
            :alt="card.title"
            :style="[
              isMobile
                ? { height: '145px', width: '140px' }
                : { height: '185px', width: '180px' },
              isArabic && isMobile ? { left: '20px' } : { right: '40px' },
            ]"
          />
          <div class="specialoffer-content--cards__logoContainer--content">
            <div
              class="specialoffer-content--cards__logoContainer--content-container"
            >
              <div
                class="specialoffer-content--cards__logoContainer--content-title"
                :style="{ color: card.textColor }"
              >
                {{ card.title }}
              </div>
              <div
                class="specialoffer-content--cards__logoContainer--content-subtitle"
                :style="{ color: card.textColor }"
                v-if="card.first3characterbackground"
              >
                <span
                  class="first3character"
                  :class="card.first3characterbackgroundColor"
                  :style="{ color: card.first3characterTextColor }"
                >
                  {{ card.first3character }}
                </span>
                {{ card.subtitle }}
              </div>
              <div
                class="specialoffer-content--cards__logoContainer--content-subtitle"
                :style="{ color: card.textColor }"
                v-else
              >
                {{ card.subtitle }}
              </div>
              <div
                class="specialoffer-content--cards__logoContainer--content-description"
                :style="{ color: card.textColor }"
              >
                {{ card.description }}
              </div>
            </div>

            <a
              :href="card.buttonURL"
              class="specialoffer-content--cards__logoContainer--content-button"
              v-if="card.buttonText"
              :class="[card.buttonColor]"
            >
              <span
                :style="{ color: card.buttonTextColor }"
                class="specialoffer-content--cards__logoContainer--content-button__text"
              >
                {{ card.buttonText }}
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api";
import { Analytics } from "@/analytics.js";

export default {
  name: "SpecialOffer",
    props:{
        path: {
            type: String,
            default: process.env.VUE_APP_PATH
        }
    },
  data: () => ({
    specialOffer: [],
    isMobile: window.matchMedia("only screen and (max-width: 767px)").matches,
    isArabic:
      document.getElementsByTagName("html")[0].getAttribute("dir") === "rtl",
  }),
  mounted() {
    api.specialOffer().then((response) => {
      this.specialOffer = response;
    });

    this.$nextTick(() => {
      window.addEventListener("resize", this.checkIsMobile, { passive: true });
    });
  },
  methods: {
    checkIsMobile() {
      this.isMobile = window.matchMedia(
        "only screen and (max-width: 767px)"
      ).matches;
    },
    sendGA(eventCategory, eventAction, eventLabel, eventValue) {
      Analytics(eventCategory, eventAction, eventLabel, eventValue);
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.checkIsMobile);
  },
};
</script>

<style scoped lang="scss">
.no-padding {
  padding: 0px !important;
}
.specialoffer {
  display: flex;
  padding: 48px 0px;
  // flex-direction: column;
  align-items: center;
  gap: 8px;

  @media only screen and (max-width: $phone-max-size) {
    // padding: 24px 20px 32px 20px;
    padding: 0px;
    gap: 24px;
  }

  &-content {
    display: flex;
    align-items: flex-start;
    gap: 24px;
    width: 100%;

    @media only screen and (max-width: $phone-max-size) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 20px;
    }

    &--cards {
      height: 440px;
      flex: 1 0 0;
      border-radius: 12px;
      padding: 40px;
      gap: 48px;
      width: 40%;
      display: flex;
      justify-content: center;
      align-items: center;

      @media only screen and (max-width: $phone-max-size) {
        flex: none;
        width: 100%;
        padding: 24px 20px 32px 20px;
        align-items: flex-end;
        border-radius: 0;
      }

      &__imageContent {
        display: inline-flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
        width: 55%;
        position: absolute;
        right: 50px;

        @media only screen and (max-width: $phone-max-size) {
          width: 100%;
          gap: 8px;
          right: auto;
          position: relative;
        }

        &-container {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 16px;
        }

        &-title {
          font-family: $du-font-secondary-bold;
          font-size: 24px;
          line-height: 32px;

          [dir="rtl"] & {
            font-family: $du-font-secondary-bold-ar;
          }
        }

        &-subtitle {
          font-family: $du-font-secondary-bold;
          font-size: 48px;
          line-height: 54px;
          direction: ltr;

          [dir="rtl"] & {
            font-family: $du-font-secondary-bold-ar;
          }

          @media only screen and (max-width: $phone-max-size) {
            font-size: 36px;
            line-height: 44px;
          }
        }

        &-description {
          font-family: $du-font-secondary-medium;
          font-size: 20px;
          line-height: 28px;

          [dir="rtl"] & {
            font-family: $du-font-secondary-medium-ar;
          }
        }

        &-button {
          display: flex;
          padding: 16px 24px;
          width: 264px;
          justify-content: center;
          align-items: center;
          gap: 8px;
          border-radius: 12px;
          box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);

          @media only screen and (max-width: $phone-max-size) {
            width: 100%;
            margin-top: 24px;
          }

          &__text {
            font-family: $du-font-secondary-medium;
            font-size: 20px;
            line-height: 24px;

            [dir="rtl"] & {
              font-family: $du-font-secondary-medium-ar;
            }
          }
        }
      }

      &__logoContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 48px;

        @media only screen and (max-width: $phone-max-size) {
          position: relative;
          padding: 0px;
          height: 345px;
          width: 375px;
        }

        img {
          @media only screen and (max-width: $phone-max-size) {
            position: absolute;
            // right: 40px;
            top: 0px;
          }
        }

        &--content {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          gap: 24px;
          flex: 1 0 0;
          width: 100%;

          @media only screen and (max-width: $phone-max-size) {
            gap: 8px;
            position: absolute;
            bottom: 0;
          }

          &-container {
            display: flex;
            flex-direction: column;
            gap: 16px;
            align-items: flex-start;
          }

          &-title {
            font-family: $du-font-secondary-bold;
            font-size: 24px;
            line-height: 32px;

            [dir="rtl"] & {
              font-family: $du-font-secondary-bold-ar;
            }
          }

          &-subtitle {
            font-family: $du-font-secondary-bold;
            font-size: 48px;
            line-height: 54px;

            [dir="rtl"] & {
              font-family: $du-font-secondary-bold-ar;
            }

            @media only screen and (max-width: $phone-max-size) {
              font-size: 36px;
              line-height: 44px;
            }
          }

          &-description {
            font-family: $du-font-secondary-medium;
            font-size: 20px;
            line-height: 28px;

            [dir="rtl"] & {
              font-family: $du-font-secondary-medium-ar;
            }
          }

          &-button {
            display: flex;
            padding: 16px 24px;
            width: 264px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            border-radius: 12px;
            box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);

            @media only screen and (max-width: $phone-max-size) {
              width: 100%;
              margin-top: 24px;
            }

            &__text {
              font-family: $du-font-secondary-medium;
              font-size: 20px;
              line-height: 24px;

              [dir="rtl"] & {
                font-family: $du-font-secondary-medium-ar;
              }
            }
          }
        }
      }
    }
  }
}

.first3character {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  border-radius: 6px;
}

.textgradient {
  background: linear-gradient(24.05deg, #753bbd 9.2%, #00a9ce 52.35%);
}

.gradient {
  background: linear-gradient(60deg, #753bbd 0%, #c724b1 60%);
}

.golden-gardient {
  background: linear-gradient(
    214deg,
    #b18d33 0%,
    #bc9536 19.93%,
    #dbb75f 72%,
    #c19a3c 100%
  );
}

.white-gradient {
  background: $white;
}
</style>
