<template>
    <section class="videos" v-if="howToVideos.title">
        <div class="videos-inner">
            <div class="grid-container narrow">
                <div class="breadcrumb">
                    <ul class="breadcrumb-list">
                        <li class="breadcrumb-item"><a :href="helpURL" class="link du-magenta">{{this.helpText}}</a></li>
                        <li class="breadcrumb-item"><a>{{howToVideos.title}}</a></li>
                    </ul>
                </div>
                <h2 class="headline2">{{howToVideos.title}}</h2>
                <div class="row20 show-for-medium"></div>
                <div class="grid-x">
                    <div class="cell">
                        <div class="cardsList-all">
                            <div class="cardsList-card" v-for="(video, i) in howToVideos.videos" :key="i+'video'">
                                <div class="videos-box" @click="openVideo(video)">
                                    <div class="videos-box-image" v-lazy:background-image="video.img">
                                        <img class="videos-box-play" alt="video-du" :src="require('../../assets/play.png')">
                                    </div>
                                    <div class="videos-box-text">
                                        <p class="description3">{{video.title}}</p>
                                    </div>
                                </div>
                            </div>                     
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <transition name="modal">
            <div class="modal-mask" v-if="showModal">
                <div class="modal-wrapper" @click="closeVideo()">
                    <div class="modal-header">
                        <button class="modal-default-button">X</button>
                    </div>
                    <div class="modal-container">
                        <div class="modal-body">
                            <iframe width='800px' height='400px' :src="'https://www.youtube.com/embed/' + videoFrame + '?modestbranding=1&autoplay=1&controls=1&fs=0&loop=1&rel=0&showinfo=0&disablekb=1'" frameborder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' allowfullscreen></iframe>"
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </section>
</template>
<script>
import apiHelp from '../../api-help'
import { Analytics } from "@/analytics.js";

export default {
    name: 'VideosAll',
    data() {
        return {
            howToVideos: [],
            helpURL : 'support',
            showModal: false,
            helpText: document.getElementsByTagName('html')[0].getAttribute('dir') === 'rtl' ? "المساعدة" : "Help",
            videoFrame: "",
        }
    },
    mounted() {
        apiHelp.howToVideos().then(res => { this.howToVideos = res })
    },
    methods: {
        sendGA(eventCategory, eventAction, eventLabel, eventValue) {
            Analytics(eventCategory, eventAction, eventLabel, eventValue);
        },
        openVideo(video) {
            this.videoFrame = video.videoId;
            this.showModal = true;
            document.getElementsByTagName('body')[0].classList.add('modal-open');
            this.sendGA('Help & Support', 'How to Videos', video.title, 0);
        },
        closeVideo() {
            this.showModal = false;
            document.getElementsByTagName('body')[0].classList.remove('modal-open');
        }
    }
}
</script>
<style lang="scss">
//.videos {
//
//    .cardsList-all {
//        @media only screen and (min-width: $phone-max-size) {
//            display: flex;
//            flex-wrap: wrap;
//            justify-content: flex-start;
//        }
//    }
//
//    .cardsList-card {
//        margin: 20px 0px;
//        display: flex;
//        transition: $transition;
//        border-radius: 8px;
//        background-color: $white;
//        border: 1px solid $du-border-color;
//        overflow: hidden;
//        text-align: center;
//        flex-basis: calc(100% / 3 - 20px);
//        @media only screen and (min-width: $phone-max-size) {
//            margin: 20px 10px;
//        }
//    }
//
//    &-box {
//        border-radius: 10px;
//        cursor: pointer;
//        transition: .3s ease-out;
//        width: 100%;
//
//        &:hover {
//            p {
//                color: $primary-action-color;
//            }
//        }
//
//        p {
//            margin-bottom: 0;
//            font-size: 18px;
//            text-align: left;
//        }
//
//        &-play {
//            position: absolute;
//            pointer-events: none;
//            width: 110px;
//            left: 50%;
//            top: 50%;
//            transform: translate(-50%, -50%);
//            transition: .3s ease-out;
//        }
//
//        &-image {
//            height: 160px;
//            background-size: cover;
//            border-bottom: 1px solid $du-border-color;
//            position: relative;
//            background-position: center;
//        }
//
//        &-text {
//            padding: 15px;
//        }
//
//
//        .svg-icon {
//            width: 50px;
//            height: 50px;
//            display: block;
//            margin: 0 auto 10px;
//        }
//    }
//
//}
</style>