<template>
  <Transition name="slide-fade">
    <section
      class="critical-banner"
      :class="{ hide: !message.show }"
      v-if="message.show && message.isCritical"
      :dir="{ rtl: isArabic, ltr: !isArabic }"
    >
      <div class="banner-content">
        <div class="message">
          {{ message.title }}
          &nbsp;
          <a v-if="isArabic" class="link" :href="message.link">لمعرفة المزيد</a>
          <a v-else class="link" :href="message.link">Read more</a>

        </div>
        <div class="close-icon" @click="closeBanner">
          <img class="inner-icon" src="../../assets/icons/grey-cross.png" alt="x" />
        </div>
      </div>
    </section>
  </Transition>
</template>

<script>
import OutageInfoService from "../../services/outage-api";

export default {
  name: "criticalBanner",

  data() {
    return {
      message: {
        title: "",
        link: "",
        show: false,
        isCritical: false,
      },
    };
  },
  mounted() {
    OutageInfoService.getCriticalOutageInfo().then((res) => {
      this.message = res;
      if(this.message.show){
        document.querySelectorAll('.du-new-nav')[0].classList.add('has-critical');
        document.querySelectorAll('.banner-search')[0].classList.add('is-lower')
      }
    });
  },
  methods: {
    closeBanner(){
      this.message.show = !this.message.show;
      document.querySelectorAll('.du-new-nav')[0].classList.remove('has-critical')
      document.querySelectorAll('.banner-search')[0].classList.remove('is-lower')
    }
  },
  computed: {
    isArabic() {
      return (
        document.getElementsByTagName("html")[0].getAttribute("dir") === "rtl"
      );
    },
  },
};
</script>

<style lang="scss">
.critical-banner {
  z-index: 1;
  width: 100%;
  margin: 0 auto;
  padding: 1px 20px;
  position: fixed;
  background: #deeff3;
  display: flex;
  justify-content: space-between;
  top: 0;

  .banner-content {
    width: 1200px;
    max-height: 55px;
    margin: 0 auto;
    align-items: center;
    display: flex;
    justify-content: space-between;

    .message {
      width: 100%;
      max-height: 55px;
      text-align: center;
      text-overflow: clip;
      .link {
        color: black;
        text-decoration: underline;
      }

      @media only screen and (max-width: $phone-max-size){
        padding: 0 20px;
      }
    }

    .close-icon {
      width: 30px;
      height: 30px;
      cursor: pointer;

      .inner-icon, img {
        width: 12px;
        height: 12px;
      }
      .svg-icon {
        width: 10px;
        display: block;
        margin: 0 auto;
      }
    }
  }

  @media only screen and (max-width: $phone-max-size){
    height: 55px;
  }
}
.slide-fade-enter-active {
  transition: all 0.5s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(-40px);
  opacity: 0;
}

</style>
