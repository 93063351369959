var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[!_vm.isMobile ? 'grid-container no-padding' : ''],style:([
    _vm.isMobile ? { 'margin-top': '20px' } : { 'margin-top': '72px' },
  ])},[_c('div',{staticClass:"duAppContainer duapp",style:([
        { backgroundColor: _vm.duApp.background },
        _vm.isMobile
          ? {
              background: 'url(' + _vm.path+_vm.duApp.backgroundURLMobile + ') center / cover no-repeat'
            }
          : {
              background: 'url(' + _vm.path+_vm.duApp.backgroundURL + ') center / cover no-repeat'
            },
      ])},[_c('div',{staticClass:"duapp-content"},[_c('div',{staticClass:"duapp-content__container"},[_c('div',{staticClass:"duapp-content__title",style:({ color: _vm.duApp.textColor })},[_vm._v(" "+_vm._s(_vm.duApp.title)+" ")]),_c('div',{staticClass:"duapp-content__description",style:({ color: _vm.duApp.textColor })},[_vm._v(" "+_vm._s(_vm.duApp.description)+" ")])]),_c('div',{staticClass:"duapp-content__store"},_vm._l((_vm.duApp.stores),function(store,i){return _c('a',{key:i,attrs:{"href":store.url},on:{"click":function($event){return _vm.sendGA(
                store.ga.eventCategory,
                store.ga.eventAction,
                store.ga.eventLabel,
                store.ga.eventValue
              )}}},[_c('span',{staticClass:"svg-bg-icon",style:([ !_vm.isMobile ? {
                backgroundImage: 'url(' + _vm.path+store.image + ')',
              } : {
                backgroundImage: 'url(' + _vm.path+store.mobileImage + ')',
              }]),attrs:{"title":store.title}})])}),0)])]),_c('div',{staticClass:"grid-container full-mobile rating"},[_c('div',{staticClass:"grid-x"},[(_vm.duApp.rating)?_c('div',{staticClass:"cell"},[_c('slick',{attrs:{"id":"slick-duapp","options":_vm.slickOptionsCards}},_vm._l((_vm.duApp.rating),function(rating,i){return _c('div',{key:i + 'key',staticClass:"rating-content"},[_c('div',{staticClass:"rating-content__title"},[_vm._v(_vm._s(rating.name))]),_c('div',{staticClass:"rating-content__star"},_vm._l((parseInt(rating.rating)),function(i){return _c('img',{key:i,attrs:{"src":require("@/assets/star.svg"),"alt":"rating star","height":"24","width":"24"}})}),0),_c('div',{staticClass:"rating-content__comment",staticStyle:{"margin-bottom":"20px"}},[_vm._v(" "+_vm._s(rating.comment)+" ")])])}),0)],1):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }