<template>
  <section class="helpList" v-if="dataHelp.title && dataSupport" :class="[!isOldFlow ? 'grey-background': '']" :style="[isMobile && !isOldFlow ? {'margin-top': '20px'}: null]">
      <div :class="[ !isMobile ? 'grid-container' : '']">
      <div class="grid-x grid-margin-x">
        <div class="cell" style="position: relative;">
          <h2 :class="[isOldFlow ? 'headline3' : 'helpList-title']">
            {{ dataHelp.title }}
          </h2>
          <h3 class="subtitle3" v-if="isOldFlow">{{ dataHelp.subtitle }}</h3>
        </div>
      </div>
    </div>
    <div :class="[ !isMobile ? 'grid-container' : '']">
      <div class="helpList-list">
        <slick
          id="slick-help"
          :options="slickHelp"
          v-if="dataHelp.cards.length"
        >
          <div
            v-for="(card, i) in dataHelp.cards"
            class="helpList-item"
            :key="i + 'card'"
            @click="
              sendGA(
                card.ga.eventCategory,
                card.ga.eventAction,
                card.ga.eventLabel,
                card.ga.eventValue
              )
            "
          >
            <svg-icon :icon-name="card.svg" width="40" height="40"></svg-icon>
            <p class="helpList-item-title">{{ card.title }}</p>
            <div class="helpList-item-inner"></div>
          </div>
        </slick>
      </div>
    </div>
    <div :class="[ !isMobile ? 'grid-container' : '']">
      <div class="grid-x grid-margin-x">
        <div class="cell" style="position: relative;">
          <slick
            id="slick-help-content"
            :options="slickHelpContent"
            v-if="dataHelp.cards.length"
          >
            <div v-for="(card, i) in dataHelp.cards" :key="i + 'card1'">
              <ul class="helpList-links">
                <li v-for="(link, i) in card.links" :key="i + 'card2'">
                  <a
                    :href="link.url"
                    @click="
                      sendGA(
                        link.ga.eventCategory,
                        link.ga.eventAction,
                        link.ga.eventLabel,
                        link.ga.eventValue
                      )
                    "
                    >{{ link.title }}</a
                  >
                </li>
              </ul>
            </div>
          </slick>
        </div>
      </div>
    </div>
    <section class="supportCards" v-if="isOldFlow">
      <div class="grid-container">
        <div class="grid-x">
          <supportCard
            v-for="(data, i) in dataSupport"
            :key="i"
            :background-image="data.backgroundImage"
            :icon="data.icon"
            :title="data.title"
            :description="data.description"
            :btnText="data.btnText"
            :url="data.url"
          />
        </div>
      </div>
    </section>

    <div class="supportCardsNew"  :class="[ !isMobile ? 'grid-container' : '']" v-if="!isOldFlow">
      <div
        class="supportCardsNew-cards"
        v-for="(data, i) in dataSupport"
        :key="i"
      >
        <div class="supportCardsNew-cards--title">{{ data.title }} <img v-if="data.chatBtn" class="title-icon" :src="data.icon"></div>
        <div
          class="supportCardsNew-cards--description"
          v-html="data.description"
        ></div>
        <div class="d-flex">
          <button v-if="data.chatBtn" @click="toggleChat()" class="supportCardsNew-cards--button" :class="isRtl? 'ml10':'mr10'">
            <span class="supportCardsNew-cards--button-text">{{data.chatBtn}}</span>
          </button>
          <a :href="data.url" class="supportCardsNew-cards--button">
            <span class="supportCardsNew-cards--button-text">{{
              data.btnText
            }}</span>
          </a>
        </div>
      </div>
    </div>
    <div id="rdbothelpBox" class="rdhelp" :class="showChat ? 'open':''">
      <span class="closeChat" data-toggle="rdbothelpBox" @click="toggleChat()"></span>
      <iframe sandbox="allow-modals allow-same-origin allow-scripts allow-popups allow-forms" name="webchat"
              id="webchat" class="webchat" marginwidth="0" marginheight="0" scrolling="No" frameborder="0" hspace="0"
              vspace="0" src="https://www.du.ae/servlet/duaediscovery/common/build/index.html?locale=en">
      </iframe>
    </div>
    <TipOfTheDay v-if="isOldFlow" />
    
    <!--        <div class="helpList-footer">-->
    <!--            <div class="grid-container">-->
    <!--                <div class="grid-x grid-margin-x">-->
    <!--                    <div class="cell">-->
    <!--                        <div class="helpList-footer-inner">-->
    <!--                            <a :href="dataHelp.footer.buttonUrl" @click="sendGA(dataHelp.footer.ga.eventCategory , dataHelp.footer.ga.eventAction, dataHelp.footer.ga.eventLabel, dataHelp.footer.ga.eventValue)" class="btn border">{{dataHelp.footer.button}}</a>-->
    <!--                            <div class="helpList-footer-social">-->
    <!--                                <p>{{dataHelp.footer.description}}</p>-->
    <!--                                <template v-for="(link, i) in dataHelp.footer.links">-->
    <!--                                    <a target="_blank" rel="noreferrer" @click="sendGA(link.ga.eventCategory , link.ga.eventAction, link.ga.eventLabel, link.ga.eventValue)" :href="link.url" class="helpList-footer-link" v-bind:key="i+'icon'">-->
    <!--                                         <svg-icon :icon-name="link.icon.title" :width="link.icon.width" :height="link.icon.height"></svg-icon>-->
    <!--                                        {{link.title}}-->
    <!--                                    </a>-->
    <!--                                </template>-->
    <!--                            </div>-->
    <!--                        </div>-->

    <!--                    </div>-->
    <!--                </div>-->
    <!--            </div>-->
    <!--        </div>-->
  </section>
</template>

<script>
import Slick from "vue-slick";
import api from "@/api";
import { Analytics } from "@/analytics.js";
import SvgIcon from "@/components/SvgIcon";
import TipOfTheDay from "@/components/HelpSupport/ChatBot";
import supportCard from "@/components/HelpSupport/supportCard";

export default {
  name: "HelpSection",
  components: { Slick, SvgIcon, TipOfTheDay, supportCard },
  props: {
    isOldFlow: {
      type: Boolean,
      default: false,
    },
      path: {
          type: String,
          default: process.env.VUE_APP_PATH
      }
  },
  data() {
    return {
      dataSupport: null,
      dataHelp: [],
      showChat: false,
      isMobile: window.matchMedia("only screen and (max-width: 767px)").matches,
      isRtl: document.getElementsByTagName("html")[0].getAttribute("dir") === "rtl",
      slickHelp: {
        slidesToShow: 4.8,
        slidesToScroll: 1,
        dots: false,
        arrows: false,
        autoplay: false,
        speed: 500,
        infinite: true,
        cssEase: "linear",
        asNavFor: "#slick-help-content",
        focusOnSelect: true,
        swipeToSlide:
          document.getElementsByTagName("html")[0].getAttribute("dir") === "rtl"
            ? false
            : true,
        rtl:
          document.getElementsByTagName("html")[0].getAttribute("dir") === "rtl"
            ? true
            : false,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3.5,
            },
          },
          {
            breakpoint: 800,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              centerMode: true,
            },
          },
        ],
      },
      slickHelpContent: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
        arrows: false,
        autoplay: false,
        speed: 500,
        infinite: true,
        focusOnSelect: true,
        autoplaySpeed: 4000,
        pauseOnHover: true,
        fade: true,
        asNavFor: "#slick-help",
        rtl:
          document.getElementsByTagName("html")[0].getAttribute("dir") === "rtl"
            ? true
            : false,
      },
      supportCardsDataEn: [
        {
          backgroundImage: require("../../assets/1.png"),
          icon: require("../../assets/icons/self-service-icon.svg"),
          title: "Online self-services",
          description: this.isOldFlow
            ? "You may experience longer than normal wait times in our call centres right now. However, you can also access our service online."
            : "Experiencing longer than normal wait-times in our call centres? Access our services online.",
          btnText: this.isOldFlow ? "Learn more" : "Online self-services",
          url: "https://www.du.ae/du-selfservice",
        },
        {
          backgroundImage: require("../../assets/2.png"),
          icon: require("../../assets/icons/helpNsupport.svg"),
          title: "Help & support",
          description: this.isOldFlow
            ? "We have created a page for you to find answers about our products and services or to watch our How-to videos."
            : "Find answers about our products and services, or watch our how-to videos.",
          btnText: "Visit our help page",
          url: "https://www.du.ae/personal/support",
        },
        {
          backgroundImage: require("../../assets/3.png"),
          icon: require("../../assets/icons/DCNR.svg"),
          title: "Report Unwanted calls",
          description: this.isOldFlow
            ? "Take control with the Do Not Call Registry feature. To report a spam call, visit our <a style='color: white; text-decoration: underline' href='https://myaccount.du.ae/servlet/myaccount/en/mya-voice-spam-report-a-number.html'>online complaint page.</a>"
            : "Take control with the Do Not Call Registry feature. To report a spam call, visit our <a style='color: #333333; text-decoration: underline' href='https://myaccount.du.ae/servlet/myaccount/en/mya-voice-spam-report-a-number.html'>online complaint page.</a>",
          btnText: "Learn more",
          url: "https://www.du.ae/do-not-call-registry",
        },
        {
          backgroundImage: require("../../assets/chatbot.svg"),
          icon: require("../../assets/chat-icons.svg"),
          title: "Get help from Blu",
          description: this.isOldFlow
              ? "Need 24/7 support? Ask our chatbot Blu on du website or WhatsApp."
              : "Need 24/7 support? Ask our chatbot Blu on du website or WhatsApp.",
          chatBtn: "Blu chat",
          btnText: "Whatsapp",
          url: "https://wa.me/971582428268"
        },
      ],
      supportCardsDataAr: [
        {
          backgroundImage: require("../../assets/3.png"),
          icon: require("../../assets/icons/self-service-icon.svg"),
          title: "الخدمة الذاتية عبر الإنترنت",
          description: this.isOldFlow
            ? "قد تواجه أوقات انتظار أطول من المعتاد في مراكز الاتصال لدينا حالياً، لذلك يمكنك الحصول على المساعدة عبر زيارة خدماتنا عبر الإنترنت"
            : "هل تواجه أوقات انتظار أطول من المعتاد في مراكز الاتصال لدينا حالياً؟ يمكنك الحصول على المساعدة عبر زيارة خدماتنا عبر الإنترنت.",
          btnText: this.isOldFlow ? "المزيد" : "الخدمة الذاتية عبر الإنترنت",
          url: "https://www.du.ae/du-selfservice-ar",
        },
        {
          backgroundImage: require("../../assets/2.png"),
          icon: require("../../assets/icons/helpNsupport.svg"),
          title: "المساعدة والدعم الفني",
          description: this.isOldFlow
            ? "قمنا بإنشاء صفحة لتجد فيها إجابات حول منتجاتنا وخدماتنا أو لتقوم بمشاهدة مقاطع فيديو إرشادية"
            : "احصل على إجابات حول منتجاتنا وخدماتنا، أو شاهد مقاطع فيديو إرشادية.",
          btnText: "تفضل بزيارة صفحة المساعدة",
          url: "https://www.du.ae/ar/personal/support",
        },
        {
          backgroundImage: require("../../assets/1.png"),
          icon: require("../../assets/icons/DCNR.svg"),
          title: "إبلاغ عن المكالمات غير مرغوب فيها",
          description: this.isOldFlow
            ? "قُم بالمبادرة وتحكّم بالأمور من خلال خدمة سجل عدم الاتصال. للتبليغ عن أي مكالمات غير مرغوب فيها، تفضّل بزيارة <a style='color: white; text-decoration: underline' href='https://myaccount.du.ae/servlet/myaccount/ar/mya-voice-spam-report-a-number.html'>صفحتنا للشكاوى أونلاين</a>."
            : "قُم بالمبادرة وتحكّم بالأمور من خلال خدمة سجل عدم الاتصال. للتبليغ عن أي مكالمات غير مرغوب فيها، تفضّل بزيارة <a style='color: #333333; text-decoration: underline' href='https://myaccount.du.ae/servlet/myaccount/ar/mya-voice-spam-report-a-number.html'>صفحتنا للشكاوى أونلاين</a>.",
          btnText: "المزيد",
          url: "https://www.du.ae/ar/do-not-call-registry",
        },
        {
          backgroundImage: require("../../assets/chatbot.svg"),
          icon: require("../../assets/chat-icons.svg"),
          title: "احصل على المساعدة من Blu",
          description: this.isOldFlow
              ? "بحاجة لمساعدة 24/7؟ اسأل مساعدنا الافتراضي Blu عبر موقعنا أو واتساب."
              : "بحاجة لمساعدة 24/7؟ اسأل مساعدنا الافتراضي Blu عبر موقعنا أو واتساب.",
          chatBtn: "du موقع",
          btnText: "Whatsapp",
          url: "https://wa.me/971582428268"
        },
      ],
    };
  },
  mounted() {
    (this.dataSupport =
      document.getElementsByTagName("html")[0].getAttribute("dir") === "rtl"
        ? this.supportCardsDataAr
        : this.supportCardsDataEn),
      api.helpData().then((res) => {
        this.dataHelp = res;
      });

      this.$nextTick(() => {
      window.addEventListener("resize", this.checkIsMobile, { passive: true });
    });
  },
  methods: {
    checkIsMobile() {
      this.isMobile = window.matchMedia("only screen and (max-width: 767px)").matches;
    },
    toggleChat(){
      this.showChat = !this.showChat;
    },
    sendGA(eventCategory, eventAction, eventLabel, eventValue) {
      Analytics(eventCategory, eventAction, eventLabel, eventValue);
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.checkIsMobile);
  },
};
</script>

<style lang="scss">

.grey-background {
    background: #F8F8F8;
}

.helpList-list {
    .slick-track {
        display: flex;

        .slick-slide {
            margin: 0 5px;
            display: flex;
            height: auto;
            align-items: center;
            justify-content: center;
            background: $du-grey-10;
            border: 1px solid $du-border-color;
            border-radius: 6px;
            font-size: $font-desktop-body-L;
            color: $black;
            text-align: center;
            padding: 24px 24px 20px;
            position: relative;
            transition: $transition;
            cursor: pointer;

            &:before {
                position: absolute;
                content: "";
                bottom: -15px;
                opacity: 0;
                left: 50%;
                transform: translate(-50%);
                border-left: 15px solid transparent;
                border-right: 15px solid transparent;
                border-top: 15px solid white;
                z-index: 99;
                transition: $transition;
            }

            &:hover {
                color: $du-violet;
                box-shadow: 0px 16px 18px 0 rgba(0, 0, 0, 0.15);
                @media only screen and (min-width: $phone-max-size) {
                    transform: translateY(10px);
                }

                .svg-icon {
                    fill: url(#du-icon-gradient) $du-violet;
                }

                &:before {
                    opacity: 1;
                }
            }

            @media only screen and (max-width: $phone-max-size) {
                height: 150px;
            }
        }
    }

    .slick-slide.slick-current.slick-active {
        box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.15);
        color: $white;
        transform: translateY(10px);
        z-index: 1;
        background-color: $white;

        .svg-icon {
            color: $white;
            fill: $white;
            // background-image: -webkit-linear-gradient($white, $white) !important;
        }

        &:hover {
            &:before {
                display: none;
            }
        }

        .helpList-item-inner {
            opacity: 1;
        }

        &:after {
            opacity: 1;
            transition: 0s;
            transition-delay: 0.2s;
        }
    }
}

.slick-list {
    overflow: visible;
}

.slick-slide {
    &:after {
        content: "";
        position: absolute;
        display: block;
        width: 0;
        z-index: 1;
        bottom: -10px;
        left: 0;
        right: 0;
        margin: auto;
        width: 20px;
        height: 20px;
        transform: rotate(45deg);
        z-index: -9;
        background-image: $card-gradient-after;
        opacity: 0;
    }

    &.slick-active.slick-current {
        .helpList-links li a {
            pointer-events: all;
        }
    }
}

.helpList {
    overflow: hidden;
    padding-bottom: 0;

    @media only screen and (max-width: $phone-max-size) {
        // display: flex;
        padding: 24px 20px 32px 20px !important;
        // flex-direction: column;
        // align-items: flex-start;
    }

    &-title {
        font-family: $du-font-secondary-bold;
        font-size: 48px;
        line-height: 54px;

        @media only screen and (max-width: $phone-max-size) {
            font-size: 36px;
            line-height: normal;
            text-align: left;
        }
    }

    &-list {
        margin: 10px 0 50px;
    }

    &-item {
        &-inner {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background-image: $card-gradient;
            border-radius: 5px;
            z-index: -1;
            opacity: 0;
            transition: $transition;
        }

        &-title {
            line-height: 1.2;
            // font-size: $font-desktop-body-M;
        }

        .svg-icon {
            transition: $transition;
            display: block;
            margin: 0 auto;
            margin-bottom: 10px;
            width: 40px;
            height: 40px;
        }

        img {
            margin: 0 auto;
            display: block;
            width: 40px;
            height: 40px;
            margin-bottom: 10px;
        }

        p {
            margin-bottom: 0;
        }
    }

    &-links {
        text-align: left;

        @media only screen and (min-width: $phone-max-size) {
            margin-left: 60px;
        }

        [dir="rtl"] & {
            text-align: right;

            @media only screen and (min-width: $phone-max-size) {
                margin-left: 0px;
                margin-right: 60px;
            }
        }

        li {
            margin-bottom: 16px;

            a {
                color: $black;
                text-decoration: underline;
                font-family: $du-font-secondary-medium;
                font-size: $font-desktop-body-M;
                pointer-events: none;

                [dir="rtl"] & {
                    font-family: $du-font-secondary-medium-ar;
                }

                &:hover {
                    color: $primary-action-color;
                }
            }

            &:last-child {
                margin-bottom: 27px;

                a {
                    color: $primary-action-color;
                    text-decoration: none;
                    font-family: $du-font-secondary-regular;
                    position: relative;

                    [dir="rtl"] & {
                        font-family: $du-font-secondary-regular-ar;
                    }

                    &:hover {
                        &:before {
                            [dir="ltr"] & {
                                right: -20px;
                            }

                            [dir="rtl"] & {
                                left: -20px;
                            }
                        }
                    }

                    &:before {
                        position: absolute;
                        content: "\203A";
                        font-size: 28px;
                        bottom: 3px;
                        line-height: 15px;
                        transition: $transition;
                        font-family: $du-font-secondary-bold;

                        [dir="ltr"] & {
                            right: -15px;
                        }

                        [dir="rtl"] & {
                            font-family: $du-font-secondary-bold;
                            left: -15px;
                            bottom: 6px;
                        }
                    }
                }
            }
        }
    }

    &-footer {
        border-top: 1px solid $du-border-color;
        border-bottom: 1px solid $du-border-color;
        background-color: $du-grey-10;

        &-link {
            color: $black;
            font-family: $du-font-secondary-medium;

            [dir="rtl"] & {
                font-family: $du-font-secondary-medium-ar;
            }

            .svg-icon {
                display: inline-block;
                margin: 0 auto;
                vertical-align: middle;
                transition: $transition;

                [dir="ltr"] & {
                    margin-right: 5px;
                }

                [dir="rtl"] & {
                    margin-left: 5px;
                }

                @media only screen and (max-width: $phone-max-size) {
                    margin-bottom: 5px;
                }
            }
        }

        &-inner {
            padding: 30px 0;
            display: flex;
            flex-direction: column;
            text-align: left;
            justify-content: space-between;
            align-items: center;

            [dir="rtl"] & {
                text-align: right;
            }

            @media only screen and (min-width: $tablet-max-size) {
                flex-direction: row;
                padding: 30px 0;
            }

            .btn.border {
                line-height: 2.4;
            }
        }

        svg {
            @media only screen and (max-width: $tablet-max-size) {
                display: block;
                margin: 0 auto 10px;
            }
        }

        &-social {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

            p {
                margin-bottom: 0;
                margin-right: 10px;

                [dir="rtl"] & {
                    margin-right: 0;
                    margin-left: 10px;
                }

                @media only screen and (max-width: $tablet-max-size) {
                    flex: 0 0 100%;
                    margin-top: 20px;
                    margin-bottom: 20px;
                    text-align: center;
                }
            }

            a {
                margin: 0 10px;

                @media only screen and (max-width: $tablet-max-size) {
                    text-align: center;
                    flex: 1;
                    width: 100%;
                }

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}

.supportCards {
    .grid-x {
        justify-content: center;
    }
}

.supportCardsNew {
    display: flex;
    align-items: flex-start;
    gap: 24px;
    margin-bottom: 72px;

    @media only screen and (max-width: $phone-max-size) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
        align-self: stretch;
        margin-bottom: 0;
        //text-align: left;
    }

    &-cards {
        display: flex;
        padding: 20px;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 12px;
        border-radius: 12px;
        border: 1px solid #dddddd;
        background: #f8f8f8;
        width: 411px;
        flex-direction: column;

        @media only screen and (max-width: $phone-max-size) {
            display: flex;
            padding: 20px;
            justify-content: center;
            align-items: flex-start;
            gap: 12px;
            align-self: stretch;
            width: 100%;
        }

        &--title {
            color: #333333;
            font-family: $du-font-secondary-bold;
            font-size: 17px;
            line-height: 22px;
            width: 100%;

            [dir="rtl"] & {
                font-family: $du-font-secondary-bold-ar;
            }
        }

        &--description {
            color: #333333;
            font-family: $du-font-secondary-regular;
            font-size: 13px;
            line-height: 18px;
          height: 55px;

            [dir="rtl"] & {
                font-family: $du-font-secondary-regular-ar;
            }
        }

        &--button {
            display: flex;
            padding: 9px 16px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            border-radius: 8px;
            border: 1px solid #c724b1;

            &-text {
                font-family: $du-font-secondary-medium;
                font-size: 15px;
                line-height: 18px;
                color: #c724b1;
            }
        }
      .title-icon{
        float: right;
        margin-top: -7px;
        [dir="rtl"] & {
          float: left;
        }
      }
    }
}
</style>
