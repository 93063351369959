<template>
  <div class="feedback-bar">
    <div class="grid-x">
      <div class="cell large-10">
        <div class="ctas">
          <span>{{thumbsUpCount}} {{thumbsText}}</span>

          <a href="#" @click.prevent="articleThumbsup($event)" class="icon icon-like"><img alt="unlike" :src="require('../assets/icons/icon-like.png')"></a>
          <a href="#" @click.prevent="articleThumbsdown($event)" class="icon icon-unlike"><img alt="unlike" :src="require('../assets/icons/icon-unlike.png')"></a>
        </div>
        <div :class="{hide: !feedbackForm.isVisible}">
          <div class="feedback-form" :class="{hide: !feedbackForm.isForm}">
            <form name="fdForm" method="post" >
              <p class="body1">{{ feedbackForm.title }}</p>
              <fieldset class="large-5 cell card">
                <h5 class="subtitle1">{{reasonTitleTxt}}</h5>
                <div class="card-list" v-for="(radioValue, index) in feedbackReason" :key="index">
                  <input  type="radio" name="radioValue" @change="radioSelect(index)" :value="index" :id="'reason-'+index" required>
                  <label :for="'reason-'+index">{{radioValue}}</label>
                </div>
                <div class="group" :class="showTextArea?'':'hide'">
                  <textarea  v-model="message" type="text" @input="detailReason($event.target.value)" name="otherFeedback" class="control txt1" id="formfeedbackid" placeholder="" required></textarea>
                  <!--                              <label>{{ this.feedbackForm.fieldLabel }}</label>-->
                  <p :class="{help: true, 'text-danger': remaining===0}">{{instruction}}</p>
                </div>
              </fieldset>

              <fieldset class="group action" v-if="feedbackForm.isForm">
                <input name="submitFeedback" v-if="!showLoader" class="btn btn-filled btn-sm" :class="formIsValid ? '':'disabled'" @click="submitFeedback()" type="button" :value="feedbackForm.btnText">
              </fieldset>

            </form>
          </div>
          <div class="thankyou-msg" :class="{hide: feedbackForm.isForm}" v-if="!showLoader">
            <p class="headline5">{{ this.feedbackForm.successMsg }}</p>
          </div>
          <div class="cell small-12" v-else>
            <div class="text-center lds-ring-container" style="min-height: 50px">
              <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
            </div>
          </div>
        </div>
      </div>
      <div class="cell large-2 flex-right">
        <button type="button" class="btn border btn-sm" style="align-items: baseline;"  @click="showChatbot()">{{chatBoxBtnTxt}}</button>
      </div>

    </div>
    <div class="survey-iframe" id="survey-form" style="display:none">
      <div class="iframe-container">
        <button class="close-button" @click="closeSurvey()" data-close aria-label="Close reveal" type="button">
          <span aria-hidden="true">&times;</span>
        </button>
        <iframe v-if="showSurvey" :src="'https://surveys.du.ae/EFM/se/38D19C331CF79DA1?source='+this.isMobile+'-du.ae-'+isBusiness+'-Support-ThumbsUp-Article-'+this.id" ></iframe>
      </div>
    </div>
    <div id="rdbothelpBox" data-toggler=".open" class="rdhelp">
      <span class="closeChat" @click="closeChat()"></span>
      <iframe sandbox="allow-modals allow-same-origin allow-scripts allow-popups allow-forms" name="webchat"
              id="webchat" class="webchat" marginwidth="0" marginheight="0" scrolling="No" frameborder="0" hspace="0"
              vspace="0" src="https://www.du.ae/servlet/duaediscovery/common/build/index.html?locale=en"></iframe>
    </div>
  </div>
</template>
<script>

// import ComplaintTopicList from '../components/Complaint/ComplaintTopicList';

import apiHelp from "@/api-help";
import axios from "axios";
import {Analytics} from "@/analytics";

export default {
  name: 'Feedback',
  props:['articleId'],
  data() {
    return {
      showLoader:false,
      showSurvey:false,
      thumbsUpCount:0,
      contactTitle: document.getElementsByTagName('html')[0].getAttribute('dir') === 'rtl' ? "هل لا زلت بحاجة الى مساعدة؟" : "Need further assistance ?",
      thumbsText: document.getElementsByTagName('html')[0].getAttribute('dir') === 'rtl' ? "وجدوا ذلك مفيداً" : "found this useful",
      answerTitle: document.getElementsByTagName('html')[0].getAttribute('dir') === 'rtl' ? "هل كان هذا مفيدا؟" : "Was this article helpful?",
      shareTitle: document.getElementsByTagName('html')[0].getAttribute('dir') === 'rtl' ? "المشاركة" : "Share via",
      thumbsupSuccess:false,
      reasonTitleTxt: document.getElementsByTagName('html')[0].getAttribute('dir') === 'rtl' ? "أخبرنا ما الذي يجب فعله لتحسين الجواب":"Why wasn't this article helpful?",
      chatBoxBtnTxt: document.getElementsByTagName('html')[0].getAttribute('dir') === 'rtl' ? "دردش مع بلو" : "Chat with Blu",
      feedbackForm: {
        isVisible:false,
        isForm:true,
        title: document.getElementsByTagName('html')[0].getAttribute('dir') === 'rtl' ? "!نعتذر كون المقال لم يساعدك" : "We are sorry to hear that. Please tell us how to improve this page.",
        fieldLabel: document.getElementsByTagName('html')[0].getAttribute('dir') === 'rtl' ? "أخبرنا ما الذي يجب فعله لتحسين الجواب" : "We are sorry that you didn't find the article helpful. Please tell us how we can improve it. Write your feedback here.",
        btnText: document.getElementsByTagName('html')[0].getAttribute('dir') === 'rtl' ? "أرسل رأيك" : "Submit feedback",
        successMsg: document.getElementsByTagName('html')[0].getAttribute('dir') === 'rtl' ? "شكراً. تم أرسال اقتراحك" : "Thanks for your feedback. We’ll start looking into it.",
      },
      feedbackReason: document.getElementsByTagName('html')[0].getAttribute('dir') === 'rtl' ?
          [
            "المعلومات غير كافية",
            "لم أجد ما أبحث عنه",
            "لم أفهم المقال",
            "لم أجد حل لمشكلتي",
            "سبب آخر"
          ]:[
            "Not enough information.",
            "This isn't what I was looking for.",
            "The article is confusing.",
            "The article didn't solve the problem.",
            "Other"
          ]
      ,
      formIsValid: false,
      showTextArea: false,
      limit: 100,
      message:''
    }
  },
  mounted() {
    apiHelp.positiveCount().then(res => {
      res.forEach(id => {
        if(this.id === id.prod_article_id){
          this.thumbsUpCount = id.count
        }
      })
    })

  },
  computed:{
    instruction: function() {
      return this.isRTL ? this.message===''? 'ساعدنا في معرفة ذلك في '+this.limit+' حرف': this.remaining+' حرف متبقي' : this.message===''?'Describe your feedback in '+this.limit+' characters': 'remaining '+this.remaining+' characters'
    },
    remaining: function() {
      return this.limit-this.message.length;
    }
  },
  components: {
  },
  methods:{
    sendGA(eventCategory, eventAction, eventLabel, eventValue) {
      Analytics(eventCategory, eventAction, eventLabel, eventValue);
    },
    getArticleid() {
      return this.articleId
    },
    getPortalid() {
      let isArabic = document.getElementsByTagName('html')[0].getAttribute('dir') === 'rtl';
      let portalID = "201600000001013";
      let portalID2 = "201600000001017";
      return isArabic ? portalID2 : portalID;
    },
    getLocale() {
      let isArabic = document.getElementsByTagName('html')[0].getAttribute('dir') === 'rtl';
      return isArabic ? "ar-SA" : "en-US";
    },
    getCookie(cname) {
      var name = cname + "=";
      var decodedCookie = decodeURIComponent(document.cookie);
      var ca = decodedCookie.split(';');
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },

    setCookie(cname, cvalue) {
      var d = new Date();
      d.setTime(d.getTime() + (5 * 60 * 1000));
      var expires = "expires=" + d.toGMTString();
      document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    },
    closeSurvey() {
      var surveyForm = document.getElementById("survey-form");
      surveyForm.style.display = "none";

    },
    articleThumbsup(e) {
      this.showSurvey = true;
      var _data = {
        'event': 'eventTracker',
        'eventCategory': 'Help & Support',
        'eventAction': 'Useful Icons – Thumbs Up',
        'eventLabel': this.id
      };

      if (typeof window.dataLayer != "undefined") {
        window.dataLayer.push(_data);
      }

      let surveyForm = document.getElementById("survey-form");
      surveyForm.style.display = "flex";
      this.showSurvey = true;
      if (e.currentTarget.classList.contains('disabled') || e.currentTarget.classList.contains('active')) {
        return false;
      }
      let varLocale = this.getLocale();
      e.currentTarget.classList.add('active')

      this.sendGA('help_support', 'article_detail', 'helpful-' + this.getArticleid(), 0);
      document.querySelector('.icon-unlike').classList.add('disabled');

      axios.put("https://help.du.ae/system/ws/v11/ss/article/rating/" + this.articleId + "?$lang=" + varLocale + '&usertype=customer&score=1&portalId=201600000001013', {
        'portalId': 201600000001013,
        'score': 1,
        'usertype': 'customer'
      }, {
        headers: {
          'X-egain-session': this.getCookie('egainsessionmain')
        }
      }).then(() => {
        // console.log('success'+ response);
        this.thumbsupSuccess = true;
      })
          .catch((error) => {
            console.log('something went wrong in thumbs up article: ' + error);
          })


    },
    radioSelect(e) {
      const totalRadioBtns = this.feedbackReason.length
      const selectValue = e + 1
      if (totalRadioBtns == selectValue) {
        this.showTextArea = true
        this.formIsValid = false
      } else {
        this.showTextArea = false
        this.formIsValid = true
      }

    },
    detailReason(value) {
      this.message = this.message.substr(0, this.limit)
      this.formIsValid = false
      if (value.length > 10) {
        this.formIsValid = true
      }
    },
    articleThumbsdown(e) {
      var _data = {
        'event': 'eventTracker',
        'eventCategory': 'Help & Support',
        'eventAction': 'Useful Icons – Thumbs Down',
        'eventLabel': this.id
      };

      if (typeof window.dataLayer != "undefined") {
        window.dataLayer.push(_data);
      }
      if (e.currentTarget.classList.contains('active')) {
        return false;
      }
      let varLocale = this.getLocale();
      e.currentTarget.classList.add('active')

      this.sendGA('help_support', 'article_detail', 'unhelpful-' + this.articleId, 0);


      axios.put("https://help.du.ae/system/ws/v11/ss/article/rating/" + this.articleId + "?$lang=" + varLocale + "&usertype=customer&score=0&portalId=201600000001013", {
        'portalId': 201600000001013,
        'score': 0,
        'usertype': 'customer'
      }, {
        headers: {
          'X-egain-session': this.getCookie('egainsessionmain')
          //'Access-Control-Allow-Origin': '*'
        }
      }).then(() => {
        // console.log('success'+ response);

        this.feedbackForm.isVisible = true;
        this.thumbsupSuccess = false;
        let iconLike = document.querySelector('.icon-like');
        if (!iconLike.classList.contains('active')) {
          document.querySelector('.icon-like').classList.add('disabled');
        }

        setTimeout(function () {
          var $form = document.querySelector('.feedback-form');
          $form.querySelector('.txt1').focus();
        }, 50);


      })
          .catch((error) => {
            console.log('something went wrong in thumbs down article: ' + error);
          })

    },
    submitFeedback() {
      this.showLoader = true
      const selectedReason = document.querySelector('input[name="radioValue"]').value
      const feedbackDetails = document.getElementById('formfeedbackid').value

      console.log(selectedReason, feedbackDetails)

      this.articleSuggestMain(selectedReason, feedbackDetails);
      var _data = {
        'event': 'eventTracker',
        'eventCategory': 'Help & Support',
        'eventAction': 'Send Feedback Thumbs Up',
        'eventLabel': this.id - document.getElementById('formfeedbackid').value
      };

      if (typeof window.dataLayer != "undefined") {
        window.dataLayer.push(_data);
      }

    },
    articleSuggestMain(artfeedback) {

      axios.get("https://help.du.ae/system/ws/v11/ss/article/" + this.getArticleid() + "?portalId=" + this.getPortalid() + "&usertype=customer", {
        'portalId': this.getPortalid(),
        'score': 0,
        'usertype': 'customer'
      }, {
        headers: {
          'X-egain-session': this.getCookie('egainsessionmain')
          //'Access-Control-Allow-Origin': '*'
        }
      }).then((response) => {
        this.articleSuggest(response.data.article[0].name, artfeedback);
      })
          .catch((error) => {
            console.log('something screwed up in suggest article: ' + error);
          })
    },
    articleSuggest(artname, artfeedback) {
      //console.log(artname,artfeedback);

      axios.post("https://help.du.ae/system/ws/v11/ss/suggestion?portalId=" + this.getPortalid() + "&$lang=" + this.getLocale() + "&article.name=" + artname + "-" + this.getArticleid() + "&article.description=Missing Important information-Answer is incorrect or inaccurate&article.content=" + artfeedback + "&usertype=customer", {
        'portalId': this.getPortalid(),
        '$lang': this.getLocale(),
        'article.name': artname + "-" + this.getArticleid(),
        'article.description': "Missing Important information-Answer is incorrect or inaccurate",
        'article.content': artfeedback,
        'usertype': 'customer'
      }, {
        headers: {
          'Accept': 'application/json',
          'X-egain-session': this.getCookie('egainsessionmain')
          //'Access-Control-Allow-Origin': '*'
        }
      }).then(() => {
        this.feedbackForm.isForm = false;
        this.showLoader = false
      })
          .catch((error) => {
            console.log('something screwed up in recently viewed article: ' + error);
          })

    },
    shareThis() {
      var _data = {
        'event': 'eventTracker',
        'eventCategory': 'Help & Support',
        'eventAction': 'Useful Icons – Thumbs Up',
        'eventLabel': 'copylink - ' + this.id
      };

      if (typeof window.dataLayer != "undefined") {
        window.dataLayer.push(_data);
      }
      this.sendGA('help_support', 'share', 'share_article', 0);
      navigator.clipboard.writeText(window.location.href);
    },
    sharefb() {
      var _data = {
        'event': 'eventTracker',
        'eventCategory': 'Help & Support',
        'eventAction': 'Useful Icons – Thumbs Up',
        'eventLabel': 'Facebook - ' + this.id
      };

      if (typeof window.dataLayer != "undefined") {
        window.dataLayer.push(_data);
      }
      this.sendGA('help_support', 'home', 'facebook', 0);
      var url = "https://www.facebook.com/sharer/sharer.php?u=" + window.location.href;
      window.open(url, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600');
    },
    sharetw() {
      var _data = {
        'event': 'eventTracker',
        'eventCategory': 'Help & Support',
        'eventAction': 'Useful Icons – Thumbs Up',
        'eventLabel': 'Twitter - ' + this.id
      };

      if (typeof window.dataLayer != "undefined") {
        window.dataLayer.push(_data);
      }
      this.sendGA('help_support', 'home', 'twitter', 0);
      var url = "https://twitter.com/intent/tweet?url=" + window.location.href + "&text='Checkout this article!";
      window.open(url, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600');
    },
    sharewhatsapp() {
      this.sendGA('help_support', 'home', 'whatsapp', 0);
      var url = "whatsapp://send?text=Checkout this article! " + window.location.href;
      window.open(url, '_blank');
    },
    showChatbot() {
      var _id = document.getElementById('rdbothelpBox');
      if ( _id.classList.contains('open') ) {
        _id.classList.remove('open');
      } else {
        _id.classList.add('open');
      }
    },
    closeChat(){
      var _id = document.getElementById('rdbothelpBox');
      _id.classList.remove('open');
    }
  }
};
</script>
<style lang="scss">
.closeChat{
  position: absolute;
  width:63px;
  height: 63px;
  background: transparent;
  right: 0;
  top: 0;
  cursor: pointer;
}
.survey-iframe{
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  justify-content:center;
  align-items: center;
  background: rgba(0,0,0,0.8);
  z-index: 9999;
  .iframe-container{
    //max-width:900px;
    //height: auto;
    position: relative;
    overflow: hidden;
    max-width: 900px;
    width: 100%;
    height: auto;
    min-height: 550px;
    //padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */

    button.close-button {
      top: 0;
      position: absolute;
      right: 0;
      z-index: 9;
      span {
        margin: 6px;
        font-size: 33px;
      }
    }
    iframe{
      //width:900px;
      //height: 520px;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      border: 0;
      border-radius: 10px;
      //@media only screen and (max-width: $phone-max-size) {
      //  width:360px;
      //  height: 520px;
      //}
    }
  }


}
.feedback-bar {
  margin-top: 40px;
  > .grid-x{
    align-items: flex-start;
  }
  .success-confirm {
    padding: 1.25rem;
    @media only screen and (min-width: $phone-max-size) {
      padding: 1.5rem;
    }

    .contact {
      display: block;
      position: relative;
      padding: 15px 20px;
      padding-right: 50px;
      border: #00A9CE solid 1px;
      border-radius: 12px;
      background: #fff;
      font-family: $du-font-secondary-regular;
      font-size: 1rem;
      line-height: 1em;
      color: #00A9CE;
    }
  }

  .ctas {
    display: flex;
    align-items: center;
    @media only screen and (max-width: $phone-max-size) {
      margin-bottom: 20px
    }
    span{
      margin-right: 10px;
      [dir="rtl"] & {
        margin-right: 0;
        margin-left:10px;
      }
    }
    a {
      display: inline-block;
      vertical-align: top;
      &.disabled img {
        opacity: .4;
      }

    }
    img {
      max-width: 40px;
      margin-right: 10px;
      border-radius: 50%;
      [dir="rtl"] & {
        margin-right: 0;
        margin-left: 10px;
      }
      small{
        display: block;
        text-align: center;
        padding-right: 10px;
        margin-top: 3px;
        color: #333;
      }
    }
  }

  .feedback-form {
    padding: 30px 0;
    max-width: 400px;
    p.body1 {
      font-size: 1rem;
      margin-bottom: 10px;
    }
    .group {
      position: relative;
      border: 0;
      padding: 0;
      margin: 20px 0 0;
      textarea {
        border: 1px solid #ddd;
        border-radius: 4px;
        width: 100%;
        min-height: 100px;
        padding: .5rem;
        :focus{
          border-color: #00A9CE
        }
      }
      label {
        color: #333;
        font-size: 18px;
        position: absolute;
        top: 9px;
        left: 10px;
        [dir="rtl"] & {
          left: auto;
          right: 10px;
        }
        transition: all .2s ease-in;
      }
      textarea:focus ~ label, textarea:valid ~ label {
        top: -20px;
        font-size: 14px;
        color: #09c;
        left: 0;
        [dir="rtl"] & {
          left: auto;
          right: 0;
        }
      }
      .btn {
        border: 0;
        cursor: pointer;
        &.disabled {
          opacity: 0.4;
        }
      }

    }
  }
  .thankyou-msg {
    padding-top: 20px;
    border-top: 1px solid #ddd;
    margin-top: 20px;
  }
}

</style>