<template>
    <div class="grid-x gutter-20">
      <div class="cell large-7">
        <h1 class="subtitle1 margin-bottom-10">{{title}}</h1>
        <p>{{description}}</p>
        <a :href="link" class="btn border btn-sm">{{button}}</a>
      </div>
      <div class="cell large-5">
        <div class="card-icon">
          <img :src="'/servlet/duaediscovery/common/km/common/static/helpandsupport/img/'+icon" :alt="title"/>
        </div>
      </div>
    </div>
</template>
<script>
import { Analytics } from "@/analytics.js"
export default {
  name: 'UsefulLinks',
  props: ['isSideBar', 'title', 'description', 'icon', 'button', 'link'],
  data() {
    return {

    }
  },
  mounted() {
  },
  methods: {
    sendGA(eventCategory, eventAction, eventLabel, eventValue) {
      Analytics(eventCategory, eventAction, eventLabel, eventValue);
    },
  }
}
</script>
<style lang="scss">
.card-icon{
  background: #F2F4F7;
  border-radius: 10px;
  max-width: 135px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>