<template>
    <div class="body">
        <BannerSearch />
        <VideosAll />
        <Footer />
    </div>
</template>
<script>
import BannerSearch from '../components/HelpSupport/BannerSearch';
import VideosAll from '../components/HelpSupport/VideosAll';
import Footer from '../components/Footer';

export default {
    name: 'SupportVideos',
    data() {
        return {
        }
    },
    components: {
        BannerSearch,
        VideosAll,
        Footer,
    }
};
</script>