<template>
    <div class="body">
        <BannerSearch />
        <TopicView :content="contentMisc.helpTopics"  />
        <ChatBot />
        <GetHelp />
        <Footer  />
        <Gradients />
    </div>
</template>
<script>
import BannerSearch from '../components/HelpSupport/BannerSearch';
import TopicView from '../components/HelpSupport/TopicView';
import ChatBot from '../components/HelpSupport/ChatBot';
import GetHelp from '../components/HelpSupport/GetHelp';
import Footer from '../components/Footer';
import Gradients from '../components/Gradients';

import apiHelp from '../api-help'

export default {
    name: 'SupportCategory',
    data() {
        return {
            contentMisc: []
        }
    },
    mounted() {
        apiHelp.contentMisc().then(res => { this.contentMisc = res });

    },
    components: {
        BannerSearch,
        TopicView,
        ChatBot,
        GetHelp,
        Footer,
        Gradients,

    }
};
</script>