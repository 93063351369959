var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('div',{staticClass:"grid-container bestoffers",style:({ background: _vm.bestOffers.background })},[(_vm.bestOffers.title)?[_c('h1',{staticClass:"bestoffers-title"},[_vm._v(" "+_vm._s(_vm.bestOffers.title)+" ")])]:_vm._e(),_c('div',{staticClass:"card-container"},_vm._l((_vm.bestOffers.cards),function(offer,i){return _c('div',{key:i,staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.redirectURL(offer.buttonURL)}}},[(offer.isFullImageBackground)?[_c('div',{staticClass:"card-image-section",style:([
              !_vm.isMobile
                ? { background: 'url(' + _vm.path+offer.image + ')' }
                : { background: 'url(' + _vm.path+offer.mobileImage + ')' },
            ])},[_c('div',{staticClass:"card-section-title",style:([
                { color: offer.color },
                _vm.isArabic ? { textAlign: 'right' } : null,
              ]),domProps:{"innerHTML":_vm._s(offer.title)}})])]:_c('div',{staticClass:"card-section",class:[offer.background]},[_c('div',{staticClass:"card-section-title",style:([
              { color: offer.color },
              _vm.isArabic ? { textAlign: 'right' } : null,
            ]),domProps:{"innerHTML":_vm._s(offer.title)}}),(offer.percentage)?_c('div',{staticStyle:{"position":"relative"}},[_c('div',{staticClass:"card-section-percentage",style:({ color: offer.color })},[_vm._v(" "+_vm._s(offer.percentage)+" ")]),_c('div',{staticClass:"card-section-percentage__icon",style:({ color: offer.percentageIconColor })},[_vm._v(" "+_vm._s(offer.percentageIcon)+" ")])]):_vm._e(),(offer.image && !_vm.isMobile)?_c('div',{class:[offer.isPaddingRequire ? 'padding-24' : '']},[_c('img',{attrs:{"src":_vm.path+offer.image,"alt":offer.title}})]):_vm._e(),(offer.mobileImage && _vm.isMobile)?_c('img',{staticStyle:{"align-self":"end"},attrs:{"src":_vm.path+offer.mobileImage,"alt":offer.title,"width":"128","height":"187"}}):_vm._e()])],2)}),0)],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }