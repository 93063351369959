<template>
    <div style="width:0;height:0;position:absolute;">

        <svg style="width:0;height:0;position:absolute;" viewBox="0 0 36 36">
            <linearGradient x1="0.00558093529%" y1="100%" x2="100%" y2="0%" id="du-icon-gradient" >
                <stop stop-color="#00205B" offset="0%"></stop>
                <stop stop-color="#753BBD" offset="33.8431693%"></stop>
                <stop stop-color="#00A9CE" offset="67.6863387%"></stop>
                <stop stop-color="#00A9CE" offset="100%"></stop>
            </linearGradient>
        </svg>

        <svg style="width:0;height:0;position:absolute;" viewBox="0 0 36 36">
            <linearGradient x1="5.5336585%" y1="107.871091%" x2="26.2809667%" y2="5.19657511%" id="du-magenta-gradient">
                <stop stop-color="#753BBD" offset="0%"></stop>
                <stop stop-color="#C724B1" offset="100%"></stop>
            </linearGradient>
        </svg>
    </div>
</template>
<script>
export default {
    name: 'Gradients'
}
</script>

