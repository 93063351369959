<template>
  <section class="topicView bg-grey">
      <div class="grid-container narrow">
        <div class="grid-x gutter-20">
          <div class="cell medium-8">
            <template v-if="topicName">
              <transition-group class="flex" name="fadeHelp" tag="div">
                <div class="card p0" v-for="(item,index) in complaintTopics" :key="index">
                  <a :href="'/'+url+'?topicid='+item.topic.id+'&'+content.language" @click="sendGA('Help & Support' , 'Popular Topics', item.topic.name, 0)">
                    <div class="grid-y">
                      <h2 class="description3">{{item.topic.name}}</h2>
                      <p class="description2 mb0 mt5">{{item.topic.description}}</p>
                    </div>

                    <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1.28957 0.709778C0.89957 1.09978 0.89957 1.72978 1.28957 2.11978L5.16957 5.99978L1.28957 9.87978C0.89957 10.2698 0.89957 10.8998 1.28957 11.2898C1.67957 11.6798 2.30957 11.6798 2.69957 11.2898L7.28957 6.69978C7.67957 6.30978 7.67957 5.67978 7.28957 5.28978L2.69957 0.699778C2.31957 0.319778 1.67957 0.319778 1.28957 0.709778Z" fill="#C724B1"/>
                    </svg>
                  </a>
                </div>
                <div class="card p0" :key="4">
                  <a @click="showChatbot()">
                    <div class="grid-y">
                      <h2 class="description3">{{somethingElseTxt}}</h2>
                      <p class="description2 mb0 mt5">{{somethingElseDescription}}</p>
                    </div>

                    <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1.28957 0.709778C0.89957 1.09978 0.89957 1.72978 1.28957 2.11978L5.16957 5.99978L1.28957 9.87978C0.89957 10.2698 0.89957 10.8998 1.28957 11.2898C1.67957 11.6798 2.30957 11.6798 2.69957 11.2898L7.28957 6.69978C7.67957 6.30978 7.67957 5.67978 7.28957 5.28978L2.69957 0.699778C2.31957 0.319778 1.67957 0.319778 1.28957 0.709778Z" fill="#C724B1"/>
                    </svg>
                  </a>
                </div>
              </transition-group>
            </template>



            <template v-else>
              <div class="flex">
                <div class="complaintTopics-box img rd-loader"></div>
                <div class="complaintTopics-box img rd-loader"></div>
                <div class="complaintTopics-box img rd-loader"></div>
                <div class="complaintTopics-box img rd-loader"></div>
              </div>
            </template>
          </div>
          <div class="cell medium-4 sidebar">
            <div class="align-center">
              <TroubleTicketForm :isSideBar="true" />
            </div>
          </div>
        </div>
        <div id="rdbothelpBox" data-toggler=".open" class="rdhelp">
          <span class="closeChat" @click="closeChat()"></span>
          <iframe sandbox="allow-modals allow-same-origin allow-scripts allow-popups allow-forms" name="webchat"
                  id="webchat" class="webchat" marginwidth="0" marginheight="0" scrolling="No" frameborder="0" hspace="0"
                  vspace="0" src="https://www.du.ae/servlet/duaediscovery/common/build/index.html?locale=en"></iframe>
        </div>
      </div>
  </section>
</template>
<script>
import apiHelp from '../../api-help'
import { Analytics } from "../../analytics";
import TroubleTicketForm from "@/components/HelpSupport/TroubleTicketForm";
//import SvgIcon from '@/components/SvgIcon'

export default {
  name: 'ComplaintTopicList',
  props: ['content'],
  components: {
    //SvgIcon
    TroubleTicketForm
  },
  data() {
    return {
      complaintTopics: [],
      topicName: "",
      language: document.getElementsByTagName('html')[0].getAttribute("dir") === 'rtl',
      url: '',
      somethingElseTxt: document.getElementsByTagName('html')[0].getAttribute("dir") === 'rtl' ? 'استفسارات أخرى':'Something else',
      somethingElseDescription: document.getElementsByTagName('html')[0].getAttribute("dir") === 'rtl' ? '':'My complaint is not related to network, broadband or billing.'
    }
  },
  mounted() {
    this.url = this.language ? 'ar/complaint_category' : 'complaint_category'
    apiHelp.getComplaintTopics().then(res => {
      this.complaintTopics = res.topicTree
      console.log("complaint topics",this.complaintTopics)
      this.topicName = res.topicTree[0].topic.name
    });

  },
  methods: {
    sendGA(eventCategory, eventAction, eventLabel, eventValue) {
      Analytics(eventCategory, eventAction, eventLabel, eventValue);
    },
    getIcon(icon){
      var newIcon = icon;
      newIcon.indexOf('ico-') > -1 ? newIcon = newIcon.split('ico-')[1].replace('.svg','') : newIcon = newIcon.split('/ico/')[1].replace('.svg','')
      return newIcon
    },
    showChatbot() {
      var _id = document.getElementById('rdbothelpBox');
      if ( _id.classList.contains('open') ) {
        _id.classList.remove('open');
      } else {
        _id.classList.add('open');
      }
    },
    closeChat(){
      var _id = document.getElementById('rdbothelpBox');
      _id.classList.remove('open');
    }
  }
}
</script>
<style lang="scss" scoped>
.tt-form.tt-form-sidebar{
  margin: 0;
}
  .card{
    border: 1px solid #DDDDDD;
    border-radius: 6px;
    background: #FFFFFF;
    margin-bottom: 24px !important;
    a{
      padding: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #333;
      &:hover{
        background: #f1f1f1;
      }
      .description2{
        color: #777;
      }
      > svg{
        [dir="rtl"] &{
          transform: scaleX(-1);
        }
      }
    }


  }
</style>