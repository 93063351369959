<template>
    <footer class="footer" v-if="dataFooter.footerTop">
        <div class="footer-top">
            <div class="grid-container">
                <div class="grid-x grid-margin-x">
                    <div class="cell">
                        <div class="footer-top-inner">
                            <div class="footer-top-links">
                                <ul class="links-list">
                                    <li v-for="(link,y) in dataFooter.footerTop.links" v-bind:key="y+'link'">
                                        <a :href="link.url" @click="sendGA(link.ga.eventCategory , link.ga.eventAction, link.ga.eventLabel, link.ga.eventValue)">
                                            {{link.title}}
                                        </a>
                                    </li>
                                </ul>
                                <ul class="links-type">
                                    <li v-for="(link,i) in dataFooter.footerTop.type" v-bind:key="i+'type'" :class="{'active': landingType === link.id ? true : false}" @click="landingType = link.id">
                                        <a :href="link.url" @click="sendGA(link.ga.eventCategory , link.ga.eventAction, link.ga.eventLabel, link.ga.eventValue)">
                                            {{link.title}}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div class="footer-top-store">
                                <p class="store-title">{{dataFooter.footerTop.download.title}}</p>
                                <ul class="store-list">
                                    <li v-for="(link,j) in dataFooter.footerTop.download.stores" v-bind:key="j+'download'">
                                        <a :href="link.url" @click="sendGA(link.ga.eventCategory , link.ga.eventAction, link.ga.eventLabel, link.ga.eventValue)">
                                            <span class="svg-bg-icon" :title="link.title" :style="{ backgroundImage: 'url(' + spriteURL + link.image + '-usage)' }"></span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-bottom">
            <div class="grid-container">
                <div class="grid-x grid-margin-x">
                    <template v-for="(info,i) in dataFooter.footerBottom">
                        <div class="cell large-2" v-bind:key="i+'footer'">
                            <p :class="{'footer-bottom-title':true, 'active': openFooterMob === info.id ? true : false}" @click="openFooterMob === info.id ? openFooterMob = '' : openFooterMob = info.id">
                                {{info.title}}
                            </p>
                            <ul :class="{'footer-bottom-list':true, 'active': openFooterMob === info.id ? true : false}">
                                <li v-for="(link,index) in info.links" v-bind:key="index+'2'">
                                    <a :href="link.url" @click="sendGA(link.ga.eventCategory , link.ga.eventAction, link.ga.eventLabel, link.ga.eventValue)">{{link.name}}</a>
                                </li>
                            </ul>
                            <template v-if="info.sublinks">
                                <p :class="{'footer-bottom-title':true, 'active': openFooterMob === info.sublinks.id ? true : false}" @click="openFooterMob === info.sublinks.id ? openFooterMob = '' : openFooterMob = info.sublinks.id">
                                    {{info.sublinks.title}}
                                </p>
                                <ul :class="{'footer-bottom-list':true, 'active': openFooterMob === info.sublinks.id ? true : false}">
                                    <li v-for="(sublink,j) in info.sublinks.links" v-bind:key="j+'3'">
                                        <a :href="sublink.url" @click="sendGA(sublink.ga.eventCategory , sublink.ga.eventAction, sublink.ga.eventLabel, sublink.ga.eventValue)">{{sublink.name}}</a>
                                    </li>
                                </ul>
                            </template>
                        </div>
                    </template>
                </div>
            </div>
        </div>
        <div class="footer-final">
            <div class="grid-container">
                <div class="grid-x grid-margin-x">
                    <div class="cell">
                        <div class="separation"></div>
                        <div class="footer-final-inner">
                            <div class="footer-final-top">
                                <div class="footer-final-logo">
                                    <img class="logo" src="@/assets/logo.png" alt="du-logo" width="50" height="50">
                                </div>
                                <div class="footer-final-social">
                                    <template v-for="(social, i) in dataFooter.footerCopy.socialLinks">
                                        <a :href="social.url" rel="noreferrer" target="_blank" v-bind:key="i+'socialLink'" @click="sendGA(social.ga.eventCategory , social.ga.eventAction, social.ga.eventLabel, social.ga.eventValue)">
                                            <span class="svg-bg-icon social" :title="social.title" data-fragment="social" :style="{ backgroundImage: 'url(' + spriteURL + social.icon + '-usage)' }">
                                            </span>
                                        </a>
                                    </template>
                                </div>
                            </div>
                            <div class="footer-final-bottom">
                                <div class="footer-final-map">
                                    <ul>
                                        <template v-for="(link, i) in dataFooter.footerCopy.links">
                                            <li v-bind:key="i+'footerLink'">
                                                <a :href="link.url" @click="sendGA(link.ga.eventCategory , link.ga.eventAction, link.ga.eventLabel, link.ga.eventValue)">{{link.title}}</a>
                                            </li>
                                        </template>
                                    </ul>
                                </div>
                                <div class="footer-final-copyright">
                                    <p>{{dataFooter.footerCopy.copyright}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>
<script>
import api from '@/api'
import { Analytics } from "@/analytics.js";
export default {
    name: 'Footer',
    data() {
        return {
            dataFooter: [],
            openFooterMob: "",
            spriteURL: process.env.BASE_URL + "img/svg-sprite-icons.svg#icon-",
            landingType: 'consumer',
        }
    },
    mounted() {
        api.footerData().then(res => { this.dataFooter = res })
    },
    methods: {
        sendGA(eventCategory, eventAction, eventLabel, eventValue) {
            Analytics(eventCategory, eventAction, eventLabel, eventValue);
        }
    }
}
</script>
<style lang="scss">
.footer {
    a:hover {
        text-decoration: underline;
    }

    &-top {
        background-color: $white;
        border-top: 1px solid $du-border-color;
        border-bottom: 1px solid $du-border-color;
        padding: 30px 0;

        &-inner,
        &-store,
        &-links {
            display: flex;
            justify-content: space-between;
            align-items: center;


            @media only screen and (max-width: 1023px) {
                flex-direction: column;
                width: 100%;
            }
        }

        &-inner {
            flex-flow: wrap;
        }

        &-store {

            @media only screen and (max-width: 1167px) {
                margin-top: 40px;
            }

            .store-title {
                margin-bottom: 0;
                margin-right: 20px;

                [dir="rtl"] & {
                    margin-right: 0;
                    margin-left: 20px;
                }

                @media only screen and (max-width: 1023px) {
                    margin-bottom: 10px;
                    margin-right: 0;
                    margin-left: 0;
                    [dir="rtl"] & {
                        margin-right: 0;
                        margin-left: 0;
                    }
                }

                
            }

            .svg-bg-icon {
                width: 110px;
                height: 32px;
                display: inline-block;
                vertical-align: middle;
            }

            ul.store-list{

                @media only screen and (max-width: 430px) {
                   display: flex;
                   justify-content: center;
                   flex-wrap: wrap;

                }


                li {
                    margin-right: 20px;

                    @media only screen and (max-width: 430px) {
                        margin-bottom: 10px;
                    }

                    [dir="rtl"] & {
                        margin-right: 0;
                        margin-left: 20px;
                    }
                }

            }

            @media only screen and (max-width: 1023px) {
                margin-top: 20px;
            }

        }

        .links-list {
            width: 100%;

            li {
                margin-right: 50px;

                [dir="rtl"] & {
                    margin-left: 50px;
                    margin-right: 0px;
                    @media only screen and (max-width: 1023px) {
                        margin-right: 0;
                        margin-left: 0;
                    }
                }

                @media only screen and (max-width: 1023px) {
                    display: block;
                    margin-bottom: 15px;
                    padding-bottom: 15px;
                    text-align: center;
                    margin-right: 0;
                    margin-left: 0;
                    border-bottom: 1px solid $du-border-color;




                    &:last-of-type {
                        border-bottom: 0;
                        padding-bottom: 0;
                        margin-bottom: 0;
                    }
                }
            }
        }

        .links-type {
            border: 1px solid $du-border-color;
            border-radius: 5px;
            padding: 5px 20px !important;
            display: flex;
            margin-left: 50px;
            margin-right: 0;
            min-width: 220px;
             [dir="rtl"] & {
                margin-left: 0px;
                margin-right: 50px;
            }
            // min-width: 230px;

            @media only screen and (max-width: 1023px) {
                margin: 20px 0;
                margin-left: 0;
                margin-right: 0;
                [dir="rtl"] & {
                    margin-left: 0px;
                    margin-right: 0px;
                }
            }

            a:hover {
                text-decoration: none;
            }

            li {
                padding-left: 0;
                padding-right: 20px;
                margin: 0;

                [dir="rtl"] & {
                    padding-right: 0;
                    padding-left: 20px;
                }

                &:last-of-type {
                    padding-right: 0;

                    [dir="rtl"] & {
                        padding-left: 0;
                        padding-right: 20px;
                    }

                }

                &.active {
                    position: relative;
                    padding-left: 20px;

                    [dir="rtl"] & {
                        padding-left: 0;
                        padding-right: 20px;
                    }

                    a {
                        font-family: $du-font-secondary-bold;
                        pointer-events: none;

                        [dir="rtl"] & {
                            font-family: $du-font-secondary-bold-ar;
                        }
                    }

                    &:before {
                        position: absolute;
                        content: "";
                        transform: rotate(45deg);
                        height: 14px;
                        width: 7px;
                        border-bottom: 2px solid $black;
                        border-right: 2px solid $black;
                        left: 0;
                        top: 2px;
                        [dir="rtl"] & {
                            right: 0;
                            left: auto;
                        }
                    }
                }
            }

        }



        ul {
            margin: 0;

            li {
                display: inline-block;

                &:last-of-type {
                    margin-right: 0;

                    [dir="rtl"] & {
                        margin-right: 0;
                        margin-left: 0;
                    }
                }

                a {
                    color: $black;
                }



            }

        }
    }

    &-bottom {
        padding: 50px 0;
        background-color: #F9F9F9;

        @media only screen and (max-width: 1023px) {
            padding-bottom: 0px;
        }

        &-title {
            font-family: $du-font-secondary-bold;
            position: relative;

            [dir="rtl"] & {
                font-family: $du-font-secondary-bold-ar;
            }

            @media only screen and (max-width: 1023px) {
                margin-bottom: 5px;
            }



            &:before {
                @media only screen and (max-width: 1023px) {

                    position: absolute;
                    content: "";
                    // font-family: $du-icon-font;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 14px;
                    height: 2px;
                    background-color: $black;
                    border-radius: 2px;

                    [dir="rtl"] & {
                        right: auto;
                        left: 0;
                    }
                }

            }

            &:after {
                @media only screen and (max-width: 1023px) {

                    position: absolute;
                    content: "";
                    // font-family: $du-icon-font;
                    top: 50%;
                    right: 6px;
                    transform: translateY(-50%);
                    width: 2px;
                    height: 14px;
                    background-color: $black;
                    border-radius: 2px;
                    transition: $transition;

                    [dir="rtl"] & {
                        left: 6px;
                        right: auto;
                    }
                }

            }


            &.active:after {
                @media only screen and (max-width: 1023px) {
                    height: 0;

                }

            }
        }

        &-list {
            @media only screen and (max-width: 1023px) {
                // height: 0;
                overflow: hidden;
                max-height: 0;
                transition: $transition;

                &.active {
                    max-height: 500px;
                }
            }
        }

        ul {
            margin: 0;

            @media only screen and (max-width: 1023px) {
                margin-bottom: 15px;
            }

            +p {
                @media only screen and (min-width: 1023px) {
                    margin-top: 30px;
                }
            }

            li {
                margin-bottom: 12px;

                a {
                    color: $black;
                }
            }
        }
    }

    &-final {
        background-color: #F9F9F9;

        &-inner {
            display: flex;
            flex-direction: column;
        }

        &-bottom,
        &-top {
            display: flex;
            flex-direction: row;
            font-size: 13px;
            justify-content: space-between;

            @media only screen and (max-width: 1023px) {
                flex-direction: column;
                text-align: center;
            }

        }

        &-logo {
            @media only screen and (max-width: 1023px) {
                margin-bottom: 27px;
                margin-top: 15px;
            }

            .logo {
                width: 50px;
                height: 50px;

                @media only screen and (max-width: 1023px) {

                    width: 72px;
                    height: 72px;
                }
            }

        }

        &-social {
            @media only screen and (max-width: 1023px) {
                margin-bottom: 18px;
            }

            a:hover {
                .svg-bg-icon.social {
                    transform: translateY(-5px);
                }
            }

            .svg-bg-icon {
                width: 164px;
                height: 32px;
                display: inline-block;
                vertical-align: middle;
                transition: $transition;

                &.social {
                    width: 44px;

                }
            }

        }

        &-map,
        &-copyright {
            @media only screen and (max-width: 1023px) {
                margin-bottom: 23px;
            }
        }

        &-map {
            ul li {
                &:first-of-type {
                    [dir="ltr"] & {
                        padding-left: 0;
                    }
                    [dir="rtl"] & {
                        padding-right: 0;
                    }
                }
            }
        }



        &-top {
            margin-bottom: 7px;

            .du-brand {
          
                font-family: $du-font-primary-bold;
                font-size: 16px;
                margin-left: 15px;
                pointer-events: none;

                color: $primary-color;
                -webkit-text-fill-color: transparent;
                background: -webkit-linear-gradient(transparent, transparent),
                    linear-gradient(-45deg, #00205B 8%, #00A9CE 70%, #00A9CE 100%);
                background-image: -o-linear-gradient(-45deg, #00205B 8%, #00A9CE 70%, #00A9CE 100%);
                -webkit-background-clip: text;

                [dir="rtl"] & {
                    margin-left: 0px;
                    margin-right: 15px;
                }
            }

        }

        &-bottom {
            margin-top: 10px;

            ul li {
                display: inline-block;
                padding: 0 13px 0;
                position: relative;

                @media only screen and (max-width: $phone-max-size) {
                    padding: 0 10px 0;
                }

                [dir="rtl"] & {
                    padding-right: 15px;
                }

                [dir="rtl"] & {
                    padding-left: 15px;
                }

                &:last-of-type {
                    &:before {
                        content: none;
                    }
                }

                &:before {
                    position: absolute;
                    content: "|";
                    top: 0;

                    [dir="ltr"] & {
                        right: -2px;
                    }

                    [dir="rtl"] & {
                        left: 5px;
                    }
                }

                a {
                    color: $black;
                }
            }
        }
    }
}
</style>