<template>
    <section class="videos">
        <div class="videos-inner">
            <div class="grid-container narrow">
                <template v-if="howToVideos.title">
                    <h2 class="headline2">{{howToVideos.title}}</h2>
                </template>
                <template v-else>
                    <h2 class="headline2 rd-loader">How to videos</h2>
                </template>
                <div class="row20 show-for-medium"></div>
            </div>
            <div class="grid-container narrow full-mobile">
                <div class="grid-x">
                    <div class="cell">
                        <template v-if="howToVideos.title">
                            <slick id="slick-addlife" :options="slickVideos" v-if="howToVideos.videos">
                                <template v-for="(video, i) in howToVideos.videos">
                                    <div class="cardsList-card" :key="i+'video'" v-if="i <= 2">
                                        <div class="videos-box" @click="openVideo(video)">
                                            <div class="videos-box-image" v-lazy:background-image="video.img">
<!--                                                <img class="videos-box-play" alt="video-du" :src="require('../../assets/play.png')">-->
                                              <svg class="videos-box-play" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M20 0C8.96 0 0 8.96 0 20C0 31.04 8.96 40 20 40C31.04 40 40 31.04 40 20C40 8.96 31.04 0 20 0ZM16 27V13C16 12.18 16.94 11.7 17.6 12.2L26.94 19.2C27.48 19.6 27.48 20.4 26.94 20.8L17.6 27.8C16.94 28.3 16 27.82 16 27Z" fill="black"/>
                                              </svg>

                                            </div>
                                            <div class="videos-box-text">
                                                <p class="description3">{{video.title}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </slick>
                        </template>
                        <template v-else>
                            <div id="slick-addlife">
                                <div class="cardsList-card rd-loader">
                                </div>
                                <div class="cardsList-card rd-loader">
                                </div>
                                <div class="cardsList-card rd-loader">
                                </div>                                
                            </div>
                        </template>
                    </div>
                    <div class="row20"></div>
                    <div class="flex-right" v-if="howToVideos.title">
                        <a :href="howToVideos.viewAllUrl" class="card-link" @click="sendGA('Help & Support' , 'How to Videos', 'View all videos', 0)">{{howToVideos.viewAll}}
                            <svg-icon icon-name="arrow"></svg-icon>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <transition name="modal">
            <div class="modal-mask" v-if="showModal">
                <div class="modal-wrapper" @click="closeVideo()">
                    <div class="modal-header">
                        <button class="modal-default-button">X</button>
                    </div>
                    <div class="modal-container">
                        <div class="modal-body">
                            <iframe width='800px' height='400px' :src="'https://www.youtube.com/embed/' + videoInfo.videoId + '?modestbranding=1&autoplay=1&controls=1&fs=0&loop=1&rel=0&showinfo=0&disablekb=1'" frameborder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' allowfullscreen></iframe>"
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </section>
</template>
<script>
import apiHelp from '../../api-help'
import { Analytics } from "@/analytics.js";
import Slick from 'vue-slick';
import SvgIcon from '@/components/SvgIcon'

export default {
    name: 'Videos',
    components: { Slick, SvgIcon },
    data() {
        return {
            howToVideos: [],
            helpURL : 'help-landing.html',
            showModal: false,
            videoInfo: "",
            slickVideos: {
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: true,
                arrows: false,
                autoplay: false,
                speed: 500,
                infinite: true,
                cssEase: "linear",
                autoplaySpeed: 4000,
                pauseOnHover: true,
                centerMode: false,
                focusOnSelect: true,
                // centerPadding: '20px',
                swipeToSlide: document.getElementsByTagName('html')[0].getAttribute('dir') === 'rtl' ? false : true,
                rtl: document.getElementsByTagName('html')[0].getAttribute('dir') === 'rtl' ? true : false,
                mobileFirst: true,
                responsive: [{
                    breakpoint: 1024,
                    settings: 'unslick'
                }]
            },
        }
    },
    mounted() {
        apiHelp.howToVideos().then(res => { this.howToVideos = res })
    },
    methods: {
        sendGA(eventCategory, eventAction, eventLabel, eventValue) {
            Analytics(eventCategory, eventAction, eventLabel, eventValue);
        },
        openVideo(video) {
            this.videoInfo = video;
            this.showModal = true;
            document.getElementsByTagName('body')[0].classList.add('modal-open');
            this.sendGA('Help & Support', 'How to Videos', this.videoInfo.title, 0);
        },
        closeVideo() {
            this.showModal = false;
            document.getElementsByTagName('body')[0].classList.remove('modal-open');
        }
    }
}
</script>
<style lang="scss">
.videos {

    .cardsList-card.rd-loader {
        min-height: 267px;
    }


    .slick-track {
        display: flex;

        .slick-slide {
            margin: 20px 10px;
            display: flex;
            height: auto;
            align-items: center;
            justify-content: center;
            transition: $transition;
            border-radius: 8px;
            background-color: $white;
            border: 1px solid $du-border-color;
            overflow: hidden;

            @media only screen and (min-width: $phone-max-size) {

                &:first-of-type {
                    [dir="ltr"] & {
                        margin-left: 0;
                    }

                    [dir="rtl"] & {
                        margin-right: 0;
                    }
                }

                &:last-of-type {
                    [dir="ltr"] & {
                        margin-right: 0;
                    }

                    [dir="rtl"] & {
                        margin-left: 0;
                    }
                }
            }

            &:hover {
                box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.15);
                border-color: transparent;

                @media only screen and (min-width: $phone-max-size) {
                    transform: translateY(-5px);
                }
            }

            @media only screen and (max-width: $phone-max-size) {
                margin-bottom: 30px;
            }

            &>div {
                //height: 100%;
                width: 100%;
            }
        }
    }

    .slick-list {
        @media only screen and (min-width: $phone-max-size) {
            padding: 0 !important;
            overflow: visible;
        }
    }

    #slick-addlife {
        @media only screen and (min-width: $phone-max-size) {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }
    }

    .cardsList-card {
        @media only screen and (min-width: $phone-max-size) {
            margin: 20px 10px;
            display: flex;
            transition: $transition;
            border-radius: 8px;
            background-color: $white;
            border: 1px solid $du-border-color;
            overflow: hidden;
            text-align: center;
            flex-basis: calc(100% / 3 - 20px);
              &:hover {
                box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.15);
                border-color: transparent;
                transform: translateY(-5px);
            }

        }
    }

    &-box {
        border-radius: 10px;
        cursor: pointer;
        transition: .3s ease-out;
        width: 100%;



        &:hover {
            p {
                color: $primary-action-color;
            }

            .videos-box-play {
                opacity: 1;
            }
        }

        p {
            margin-bottom: 0;
          font-size: 18px;
          text-align: left;
        }

        &-play {
            position: absolute;
            pointer-events: none;
            width: 40px;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            transition: .3s ease-out;
        }

        &-image {
            height: 160px;
            background-size: cover;
            border-bottom: 1px solid $du-border-color;
            position: relative;
            background-position: center;
            position: relative;
            &:before {
              content: "";
              background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
              width: 100%;
              height: 100%;
              position: absolute;
              left: 4px;
              z-index: 9;
              top: 0;
            }
        }

        &-text {
            padding: 15px;
        }


        .svg-icon {
            width: 50px;
            height: 50px;
            display: block;
            margin: 0 auto 10px;
        }
    }
}
</style>