<template>
    <div :class="[!isMobile ? 'grid-container no-padding': '']" :style="[isMobile ? {'margin-top': '20px'}: null]">
        <div class="homeinternet homeinternet-content" :style="{background: homeIntenet.background}">
            <div class="homeinternet-content__preposition-usp" v-if="isMobile">
                <div
                        class="homeinternet-content__preposition-usp__feature"
                        v-for="(feature, i) in homeIntenet.features"
                        :key="i"
                >
                    <img
                            :src="path+feature.svg.name"
                            :alt="feature.svg.name"
                            :width="feature.svg.width"
                            :height="feature.svg.height"
                    />
                    <span
                            class="homeinternet-content__preposition-usp__feature-title"
                            :style="{ color: homeIntenet.textColor }"
                    >{{ feature.title }}</span
                    >
                </div>
            </div>

            <div class="homeinternet-content__image">
                <img
                        :src="path+homeIntenet.image"
                        :alt="homeIntenet.title"
                        :style="[
          isMobile
            ? {  height: '313px', mixBlendMode: 'multiply' }
            : {  height: '400px', mixBlendMode: 'multiply' },
        ]"
                />
            </div>


            <div class="homeinternet-content__preposition">
                <div class="homeinternet-content__preposition-usp" v-if="!isMobile">
                    <div
                            class="homeinternet-content__preposition-usp__feature"
                            v-for="(feature, i) in homeIntenet.features"
                            :key="i"
                    >
                        <img
                                :src="path+feature.svg.name"
                                :alt="feature.svg.name"
                                :width="feature.svg.width"
                                :height="feature.svg.height"
                        />
                        <span
                                class="homeinternet-content__preposition-usp__feature-title"
                                :style="{ color: homeIntenet.textColor }"
                        >{{ feature.title }}</span
                        >
                    </div>
                </div>

                <div
                        class="homeinternet-content__preposition-title"
                        :style="{ color: homeIntenet.textColor }"
                >
                    {{ homeIntenet.title }}
                </div>

                <div
                        class="homeinternet-content__preposition-subtitle"
                        :style="{ color: homeIntenet.textColor }"
                >
                    {{ homeIntenet.subtitle }}
                </div>

                <div class="homeinternet-content__preposition-frame">
                    <div class="input-container">
                        <input
                                type="text"
                                ref="location"
                                id="location-placeholder"
                                :style="[isArabic ? {paddingRight: '40px'} : {paddingLeft: '40px'}]"
                                :placeholder="homeIntenet.textFieldPlaceHolder"
                                class="homeinternet-content__preposition-frame__textField"
                                v-if="homeIntenet.isTextFieldPresent"
                                @focus="loadGoogleMap"
                        />
                        <template v-if="homeIntenet.textFieldIcon">
              <span
                      class="icon"
                      :style="[{
                  backgroundImage:
                    'url(' + path+homeIntenet.textFieldIcon.name + ')',
                },
                isArabic ? {right: '10px'} : {left: '10px'}]"
              ></span>
                        </template>
                    </div>
                    <a
                            :href="getURL()"
                            class="homeinternet-content__preposition-frame__button"
                            :class="{'btn':true ,'white': homeIntenet.buttonColor === 'white' ? true : false}"
                            v-if="homeIntenet.buttonText"
                            @click="
              sendGA(
                allDevice.ga.eventCategory,
                allDevice.ga.eventAction,
                allDevice.ga.eventLabel,
                allDevice.ga.eventValue
              )
            "
                    >
            <span
                    class="homeinternet-content__preposition-frame__button-text"
                    :style="{ color: homeIntenet.buttonTextColor }"
            >{{ homeIntenet.buttonText }}</span
            >
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import api from "@/api";
import {Analytics} from "@/analytics.js";

export default {
    name: "HomeInternet",
    props: {
        path: {
            type: String,
            default: process.env.VUE_APP_PATH
        }
    },
    data: () => ({
        homeIntenet: [],
        isMobile: window.matchMedia("only screen and (max-width: 767px)").matches,
        userLocation: null,
        isArabic: document.getElementsByTagName('html')[0].getAttribute('dir') === 'rtl',
        isGoogleMapLoaded: false,
        googleMapKey: process.env.VUE_APP_GOOGLE_MAPS_API_KEY
    }),
    mounted() {
        api.homeInternet().then((response) => {
            this.homeIntenet = response;
        });

        this.$nextTick(() => {
            window.addEventListener("resize", this.checkIsMobile, {passive: true});
        });
    },
    methods: {
        getAddress(results) {
            let address = []

            results.forEach(result => {
                if (result?.types.indexOf("route") > -1) {
                    address.push(result.long_name)
                }

                if (result?.types.indexOf("sublocality_level_1") > -1) {
                    address.push(result.long_name)
                }

                if (result?.types.indexOf("administrative_area_level_1") > -1) {
                    address.push(result.long_name)
                }

                if (result?.types.indexOf("country") > -1) {
                    address.push(result.long_name)
                }
            })

            return address.length > 0 ? address.join(' ') : ""
        },
        getURL() {
            if (this.userLocation) {
                const {
                    address_components,
                    name,
                    geometry: {location},
                } = this.userLocation;

                const format_address = this.getAddress(address_components)
                const address = name + ' ' + format_address
                const lat = location.lat();
                const long = location.lng();

                console.log('url is', `${this.homeIntenet.buttonURL}?address=${address}&lat=${lat}&long=${long}`)

                return `${this.homeIntenet.buttonURL}?address=${address}&lat=${lat}&long=${long}`;
            } else {
                return this.homeIntenet.buttonURL;
            }
        },
        loadGoogleMap() {
            if (this.isGoogleMapLoaded) {
                return
            }
            const script = document.createElement("script");
            const GOOGLE_API_KEY = this.googleMapKey //this.isDevelopment ? 'AIzaSyBtrg14ywn3DR0sE8rjon2K5bDzIWqHbhU' : 'AIzaSyC3wnIfeLwRabWuCcvx8TAbgGr0FYCvccM'
            script.src =
                    `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&region=ae&libraries=places`;
            script.defer = true;
            script.async = true;
            script.onload = this.initializeAutocomplete;

            document.head.appendChild(script);
            this.isGoogleMapLoaded = true
        },
        initializeAutocomplete() {
            const autocomplete = new window.google.maps.places.Autocomplete(
                    this.$refs.location,
                    {
                        componentRestrictions: {country: "AE"},
                    }
            );
            // this.$refs.location.attr('placeholder', '')

            autocomplete.addListener("place_changed", () => {
                const place = autocomplete.getPlace();
                this.userLocation = place;
            });
        },
        checkIsMobile() {
            this.isMobile = window.matchMedia(
                    "only screen and (max-width: 767px)"
            ).matches;
        },
        sendGA(eventCategory, eventAction, eventLabel, eventValue) {
            Analytics(eventCategory, eventAction, eventLabel, eventValue);
        },
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.checkIsMobile);
    },
};
</script>

<style scoped lang="scss">

.no-padding {
    padding: 0px !important;
}

.homeinternet {
    display: flex;
    padding: 72px;
    justify-content: center;
    align-items: center;

    @media only screen and (max-width: $phone-max-size) {
        padding: 24px 20px 32px 20px;
        gap: 20px;
    }

    &-content {
        display: flex;
        // width: 970px;
        justify-content: center;
        align-items: center;
        gap: 24px;
        flex-wrap: wrap;
        border-radius: 12px;

        @media only screen and (max-width: $phone-max-size) {
            display: flex;
            width: 100%;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 20px;
            border-radius: 0px;
        }

        &__image {
            width: 50%;
            display: flex;
            align-items: center;
            justify-content: center;

            @media only screen and (max-width: $phone-max-size) {
                width: 100%;
            }
        }

        &__preposition {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            gap: 24px;
            flex: 1 0 0;
            width: 50%;

            @media only screen and (max-width: $phone-max-size) {
                flex: none;
                gap: 20px;
                width: 100%;
            }

            &-usp {
                display: flex;
                align-items: center;
                gap: 48px;
                align-self: stretch;

                @media only screen and (max-width: $phone-max-size) {
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 10px;
                    align-self: stretch;
                }

                &__feature {
                    display: flex;
                    align-items: center;
                    gap: 10px;

                    &-title {
                        font-family: $du-font-secondary-regular;
                        font-size: 17px;
                        line-height: 24px;

                        [dir="rtl"] & {
                            font-family: $du-font-secondary-regular-ar;
                        }
                    }
                }
            }

            &-title {
                font-size: 48px;
                font-family: $du-font-secondary-bold;
                line-height: 54px;

                [dir="rtl"] & {
                    font-family: $du-font-secondary-bold-ar;
                }

                @media only screen and (max-width: $phone-max-size) {
                    font-size: 36px;
                    line-height: 44px;
                }
            }

            &-subtitle {
                font-family: $du-font-secondary-medium;
                font-size: 20px;
                line-height: 28px;

                [dir="rtl"] & {
                    font-family: $du-font-secondary-medium-ar;
                }
            }

            &-frame {
                display: flex;
                align-items: flex-start;
                gap: 16px;
                align-self: stretch;

                @media only screen and (max-width: $phone-max-size) {
                    flex-direction: column;
                    gap: 20px;
                }

                &__textField {
                    display: flex;
                    padding: 10px 16px;
                    align-items: center;
                    gap: 6px;
                    align-self: stretch;
                    border-radius: 12px;
                    border: 1px solid #dddddd;
                    background: #f8f8f8;
                    width: 300px;
                    height: 56px;

                    @media only screen and (max-width: $phone-max-size) {
                        width: 100%;
                    }
                }

                &__button {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 16px 24px;
                    gap: 8px;
                    border-radius: 12px;
                    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
                    min-width: 162px !important;
                    @media only screen and (max-width: $phone-max-size) {
                        width: 100%;
                    }

                    &-text {
                        font-family: $du-font-secondary-medium;
                        font-size: 20px;
                        line-height: 24px;

                        [dir="rtl"] & {
                            font-family: $du-font-secondary-medium-ar;
                        }
                    }
                }
            }
        }
    }
}

.input-container {
    position: relative;
    display: inline-block;

    @media only screen and (max-width: $phone-max-size) {
        width: 100%;
        height: 56px;
    }
}

.icon {
    position: absolute;
    top: 40%;
    // left: 10px;
    transform: translateY(-50%);
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    background-size: contain;
    pointer-events: none;

    @media only screen and (max-width: $phone-max-size) {
        top: 50%;
    }
}

#location-placeholder {
    // padding-left: 40px;
    margin-bottom: 16px;

    @media only screen and (max-width: $phone-max-size) {
        margin-bottom: 0px;
    }
}

#location-placeholder::placeholder {
    font-size: $du-font-secondary-medium;
    font-size: 20px;
    line-height: 24px;
    color: #333;
}

.gradient {
    background: linear-gradient(60deg, #753bbd 0%, #c724b1 60%);
}
</style>
