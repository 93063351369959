<template>
  <div
    :class="[!isMobile ? 'grid-container no-padding' : '']"
    :style="[
      isMobile ? { 'margin-top': '20px' } : null,
    ]"
  >
    <div class="newsim newsim-content" :style="{ background: newSim.background }">
      <div class="newsim-content__preposition-usp" v-if="isMobile">
        <div
          class="newsim-content__preposition-usp__content"
          v-for="(feature, i) in newSim.features"
          :key="i"
        >
          <img
            :src="path+feature.svg.name"
            :alt="feature.svg.name"
            :width="24"
            :height="24"
            v-if="feature.svg"
          />
          <div
            :style="{ color: newSim.textColor }"
            class="newsim-content__preposition-usp__content-title"
          >
            {{ feature.title }}
          </div>
        </div>
      </div>

      <div class="newsim-content__image">
        <img
        :src="path+newSim.image"
        :alt="newSim.title"
        style="mix-blend-mode: multiply;"
        width="514"
        height="350"
      />
      </div>

      

      <div class="newsim-content__preposition">
        <div class="newsim-content__preposition-usp" v-if="!isMobile">
          <div
            class="newsim-content__preposition-usp__content"
            v-for="(feature, i) in newSim.features"
            :key="i"
          >
            <img
              :src="path+feature.svg.name"
              :alt="feature.svg.name"
              v-if="feature.svg"
              :width="24"
              :height="24"
            />
            <div
              :style="{ color: newSim.textColor }"
              class="newsim-content__preposition-usp__content-title"
            >
              {{ feature.title }}
            </div>
          </div>
        </div>

        <div
          class="newsim-content__preposition-title"
          :style="{ color: newSim.textColor }"
        >
          {{ newSim.title }}
        </div>

        <div
          class="newsim-content__preposition-description"
          :style="{ color: newSim.textColor }"
        >
          {{ newSim.description }}
        </div>

        <a
          class="newsim-content__preposition-button"
          :href="newSim.buttonURL"
          v-if="newSim.buttonText"
          :class="{'btn':true ,'white': newSim.buttonColor === 'white' ? true : false}"
          @click="
            sendGA(
              newSim.ga.eventCategory,
              newSim.ga.eventAction,
              newSim.ga.eventLabel,
              newSim.ga.eventValue
            )
          "
        >
          <span
            :style="{ color: newSim.buttonTextColor }"
            class="newsim-content__preposition-button_title"
            >{{ newSim.buttonText }}</span
          >
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api";
import { Analytics } from "@/analytics.js";

export default {
  name: "NewSim",
    props:{
        path: {
            type: String,
            default: process.env.VUE_APP_PATH
        }
    },
  data: () => ({
    newSim: [],
    isMobile: window.matchMedia("only screen and (max-width: 767px)").matches,
  }),
  mounted() {
    api.newSim().then((response) => {
      this.newSim = response;
    });

    this.$nextTick(() => {
      window.addEventListener("resize", this.checkIsMobile, { passive: true });
    });
  },
  methods: {
    checkIsMobile() {
      this.isMobile = window.matchMedia(
        "only screen and (max-width: 767px)"
      ).matches;
    },
    sendGA(eventCategory, eventAction, eventLabel, eventValue) {
      Analytics(eventCategory, eventAction, eventLabel, eventValue);
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.checkIsMobile);
  },
};
</script>

<style scoped lang="scss">
.no-padding {
  padding: 0px !important;
}
.newsim {
  display: flex;
  padding: 72px;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: $phone-max-size) {
    display: flex;
    padding: 24px 20px 32px 20px;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }

  &-content {
    display: flex;
    align-items: center;
    gap: 24px;
    border-radius: 12px;

    @media only screen and (max-width: $phone-max-size) {
      gap: 20px;
      flex-direction: column;
      width: 375px;
      align-items: flex-start;
      border-radius: 0px;
    }

    &__preposition {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 24px;
      flex: 1 0 0;
      width: 50%;

      @media only screen and (max-width: $phone-max-size) {
        gap: 20px;
        width: 100%;
      }

      &-usp {
        display: flex;
        align-items: flex-start;
        gap: 48px;
        flex-wrap: wrap;

        @media only screen and (max-width: $phone-max-size) {
          flex-direction: column;
          align-items: flex-start;
          gap: 10px;
        }

        &__content {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 10px;

          &-title {
            font-family: $du-font-secondary-regular;
            font-size: 17px;
            line-height: 24px;

            [dir="rtl"] & {
              font-family: $du-font-secondary-regular-ar;
            }
          }
        }
      }

      &-title {
        font-family: $du-font-secondary-bold;
        font-size: 48px;
        line-height: 54px;

        [dir="rtl"] & {
          font-family: $du-font-secondary-bold-ar;
        }

        @media only screen and (max-width: $phone-max-size) {
          font-size: 36px;
          line-height: 44px;
        }
      }

      &-description {
        font-family: $du-font-secondary-medium;
        font-size: 20px;
        line-height: 28px;
        color: #000;

        [dir="rtl"] & {
          font-family: $du-font-secondary-medium-ar;
        }
      }

      &-button {
        display: flex;
        width: 216px;
        align-items: center;
        justify-content: center;
        padding: 16px 24px;
        gap: 8px;
        border-radius: 12px;
        box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);

        @media only screen and (max-width: $phone-max-size) {
          width: 100%;
        }

        &_title {
          font-family: $du-font-secondary-medium;
          font-size: 20px;
          line-height: 24px;

          [dir="rtl"] & {
            font-family: $du-font-secondary-medium-ar;
          }
        }
      }
    }

    &__image {
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;

      @media only screen and (max-width: $phone-max-size) {
        width: 100%;
      }
    }
  }
}

.gradient {
  background: linear-gradient(60deg, #753bbd 0%, #c724b1 60%);
}
</style>
