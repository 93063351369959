<template>
  <Transition name="slide-fade">
    <section class="info-banner"
    v-if="message.show"
      :dir="{ 'rtl': isArabic, 'ltr': !isArabic }"
    >
      <div class="banner-box">
        <div class="banner-content">
          <div class="message">
            <div class="icon">
            <img src="../../assets/icons/info-white-boxed.png" alt="info">
            </div>
            <span
              >{{ message.title }}
              &nbsp;
              <a v-if="isArabic" class="link" :href="message.link">لمعرفة المزيد</a>
              <a v-else class="link" :href="message.link">Learn more</a>
            </span>
          </div>
          <div
            class="close-icon"
            @click="message.show = !message.show"
            :class="{ rtl: isArabic, ltr: !isArabic }"
          >
            <img class="inner-icon" src="../../assets/icons/white-cross.png" alt="x">
          </div>
        </div>
      </div>
    </section>
  </Transition>
</template>

<script>
import OutageInfoService from "../../services/outage-api";

export default {
  name: "infoBanner",

  data() {
    return {
      message: {
        title: "",
        link: "",
        show: false,
        isCritical: false,
      },
    };
  },
  mounted() {
    OutageInfoService.getOutageInfo().then((res) => {
      this.message = res;
    });
  },
  methods: {
  },
  computed: {
    isArabic() {
      return (
        document.getElementsByTagName("html")[0].getAttribute("dir") === "rtl"
      );
    },
  },
};
</script>

<style lang="scss">
$primaryBackground: white;
$primaryBanner: linear-gradient(
  115deg,
  #753bbd 0%,
  #00a9ce 67.21%,
  #00a9ce 100%
);
$primaryText: white;
$primaryLink: white;

.info-banner {
  // max-width: 1920px;
  width: 99%;
  margin: 0 auto;
  background: $primaryBackground;
  padding: 5px 0;
  color: $primaryText;

  .banner-box {
    background: $primaryBanner;
    height: 65px;
    border-radius: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;

    .banner-content {
      display: flex;
      max-width: 1200px;
      margin: 0 auto;
      height: 100%;
      // padding: 0 5px;
      align-items: center;
      width: 100%;
      justify-content: space-between;

      .message {
        width: 100%;
        display: flex;
        justify-content: center;
        .icon {
          width: 20px;
          height: 20px;
          margin: 0 15px;
        }
        font-weight: 600;
        padding: 0 10px;
        .link {
          text-decoration: underline;
          color: $primaryLink;
        }
      }

      .close-icon {
        width: 30px;
        height: 30px;
        cursor: pointer;

        .inner-icon, img{
          width: 12px;
          height: 12px;
        }

      }

      @media only screen and (max-width: $phone-max-size) {
        align-items: normal;
        padding: 20px 10px;
      }
    }

    @media only screen and (max-width: $phone-max-size) {
      height: fit-content;
      margin: 0 5px;
    }
  }
}
.slide-fade-enter-active {
  transition: all 0.5s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(-40px);
  opacity: 0;
}

// .critical-banner ~ .du-ntheme .du-new-nav {
//   top: 32px !important;
// }
</style>
