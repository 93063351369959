<template>
  <div
   :class="[!isMobile ? 'grid-container no-padding' : '']"
    :style="[
      isMobile ? { 'margin-top': '20px' } : null,
    ]"
  >
      <div class="quickLinks text-center quickLinks-content" :style="[
      isMobile
        ? { background: quickLinksData.mobileBackground }
        : { background: quickLinksData.background }
    ]">
        <div
          class="quickLinks_title show-for-medium"
          v-if="quickLinksData.title"
        >
          {{ quickLinksData.title }}
        </div>
        <div
          class="quickLinks_title hide-for-medium text-center"
          v-if="quickLinksData.titleMobile"
        >
          {{ quickLinksData.titleMobile }} {{ quickLinksData.titleMobile2 }}
        </div>

        <div class="quickLinks_iconcontainer">
          <div
            v-for="(link, i) in quickLinksData.content"
            :key="i"
            class="quickLinks_iconcontainer-content"
          >
            <a
              class="quickLinks_iconcontainer-product"
              :href="link.url"
              :aria-label="link.title"
              @click="
                sendGA(
                  link.ga.eventCategory,
                  link.ga.eventAction,
                  link.ga.eventLabel,
                  link.ga.eventValue
                )
              "
            >
              <!-- <svg-icon :icon-name="link.svg.name" :width="link.svg.width" :height="link.svg.height"></svg-icon> -->
              <img
                :src="path+link.svg.name"
                :alt="link.svg.name"
                :width="isMobile ? 32 : 48"
                :height="isMobile ? 32 : 48"
              />
              <span class="quickLinks_iconcontainer-product-title">{{
                link.title
              }}</span>
            </a>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import api from "@/api";
import { Analytics } from "@/analytics.js";
// import SvgIcon from '@/components/SvgIcon'

export default {
  name: "NewQuickLink",
  components: {
    //   SvgIcon
  },
    props:{
        path: {
            type: String,
            default: process.env.VUE_APP_PATH
        }
    },
  data() {
    return {
      quickLinksData: [],
      dataLength: 0,
      isMobile: window.matchMedia("only screen and (max-width: 767px)").matches,
    };
  },
  mounted() {
    api.newQuickLinks().then((res) => {
      this.quickLinksData = res;
      this.dataLength = this.quickLinksData.content.length;
    });
    this.$nextTick(() => {
      window.addEventListener("resize", this.checkIsMobile, { passive: true });
    });
  },
  created() {
    setTimeout(() => (this.elementVisible = false), 1000);
  },
  methods: {
    checkIsMobile() {
      this.isMobile = window.matchMedia(
        "only screen and (max-width: 767px)"
      ).matches;
    },
    sendGA(eventCategory, eventAction, eventLabel, eventValue) {
      Analytics(eventCategory, eventAction, eventLabel, eventValue);
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.checkIsMobile);
  },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
<style scoped lang="scss">
.no-padding {
  padding: 0px !important;
}
.quickLinks {
  &-content {
    display: flex;
    padding: 48px 0px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    border-radius: 12px;
    background: #ffffff;
    box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.02);
    flex-wrap: wrap;

    @media only screen and (max-width: $phone-max-size) {
      gap: 20px;
      border-radius: 0px;
      padding: 0px;
    }
  }

  @media only screen and (max-width: $phone-max-size) {
    padding: 24px 20px 32px 20px;
    // width: 375px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &_title {
    color: #333333;
    font-family: $du-font-secondary-bold;
    font-size: 20px;
    line-height: 24px;

    [dir="rtl"] & {
      font-family: $du-font-secondary-bold-ar;
    }
  }

  &_iconcontainer {
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 16px;
    flex-wrap: wrap;
    width: 100%;

    @media only screen and (max-width: $phone-max-size) {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      gap: 16px;
      margin-top: 0px;
    }

    &-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 16px;

      @media only screen and (max-width: $phone-max-size) {
        display: flex;
        padding: 18px 16px;
        align-items: center;
        gap: 12px;
        border-radius: 12px;
        box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.1);
        width: 159px;
      }

        @media only screen and (max-width: 373px) {
            width: 100%;
            align-items: start;
        }
    }

    &-product {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 16px;
      flex: 1 0 0;
      // width: 250px;
      cursor: pointer;
      transition: $transition;

      @media only screen and (max-width: $phone-max-size) {
        // width: 128px;
        // display: flex;
        // gap: 12px;
        // flex: 1 0 0;
        // padding: 18px 16px;
        flex-direction: row;
      }

      img {
        transition: $transition;
      }

      &:hover img {
        transform: translateY(-5px);
      }

      &-title {
        color: #333333;
        font-family: $du-font-secondary-bold;
        font-size: 17px;
        line-height: 22px;

        [dir="rtl"] & {
          font-family: $du-font-secondary-bold-ar;
        }

        @media only screen and (max-width: $phone-max-size) {
          font-size: 15px;
          line-height: 20px;
        }
      }
    }
  }
}
</style>
