import axios from "axios";
/*import BestOfferJSON from "@/data/homepage/bestOffers.json";
import LatestDeviceJSON from "@/data/homepage/latestDevice.json";
import AllDeviceJSON from "@/data/homepage/allDevice.json";
import SpecialOfferJSON from "@/data/homepage/specialOffer.json";
import HomeInternetJSON from "@/data/homepage/homeInternet.json";
import NewPackageJSON from "@/data/homepage/newPackages.json";
import SimJSON from "@/data/homepage/sim.json";
import ExtraFeatureJSON from "@/data/homepage/extraFeature.json";
import DuAppJSON from "@/data/homepage/duApp.json";
import NewQuickLink from "@/data/homepage/newQuickLink.json";*/

// const isForPreviewEnv = location.href.includes('meyslclvweb01:9000') ? true : false
// console.log(process.env.VUE_APP_PATH)
var url = process.env.VUE_APP_PATH; //"http://meyslclvweb01:9000/";
// const url = isForPreviewEnv ? "http://meyslclvweb01:9000/" : "https://www.du.ae/";

/*export const isDevelopment =
  location.hostname === "localhost" || location.hostname === "127.0.0.1";*/
var isArabic =
    document.getElementsByTagName("html")[0].getAttribute("dir") === "rtl";

export default {
    spotlight() {
        var spotlightURL = isArabic
            ? url + "/ar/dxlandingspotlight"
            : url + "/dxlandingspotlight";
        return axios.get(spotlightURL).then((response) => {
            return response.data.data;
        });
    },

    quickLinks() {
        // var quickLinksURL = isArabic ? 'dxlandingquicklinks_ar.json' : 'dxlandingquicklinks.json';
        var quickLinksURL = isArabic
            ? url + "/dxlandingquicklinks_ar"
            : url + "/dxlandingquicklinks";
        // https://run.mocky.io/v3/1de5ea7f-bdc1-41ff-ac91-72da4e929b63
        return axios.get(quickLinksURL).then((response) => {
            return response.data.data;
        });
    },

    bestOffers() {
        const bestOffersURL = isArabic
            ? `${url}/ar/dxlandingbestoffers`
            : `${url}/dxlandingbestoffers`;
        // For development purpose

        return axios.get(bestOffersURL).then((response) => {
                return response.data.data;
            });
    },

    latestDevice() {
        const latestDeviceURL = isArabic
            ? `${url}/ar/dxlandinglatestdevice`
            : `${url}/dxlandinglatestdevice`;

        return axios.get(latestDeviceURL).then((response) => {
                return response.data.data;
            });
    },

    allDevice() {
        const allDeviceURL = isArabic
            ? `${url}/ar/dxlandingalldevice`
            : `${url}/dxlandingalldevice`;

        return axios.get(allDeviceURL).then((response) => {
                return response.data.data;
            });
    },

    specialOffer() {
        const specialOfferURL = isArabic
            ? `${url}/ar/dxlandingspecialoffer`
            : `${url}/dxlandingspecialoffer`;

        return axios.get(specialOfferURL).then((response) => {
                return response.data.data;
            });
    },

    homeInternet() {
        const homeInternetURL = isArabic
            ? `${url}/ar/dxlandinghomeinternet`
            : `${url}/dxlandinghomeinternet`;

        return axios.get(homeInternetURL).then((response) => {
                return response.data.data;
            });
    },

    newPackages() {
        const newPackageURL = isArabic
            ? `${url}/ar/dxlandingpackages`
            : `${url}/dxlandingpackages`;

        return axios.get(newPackageURL).then((response) => {
                return response.data.data;
            });
    },

    newSim() {
        const newSimURL = isArabic
            ? `${url}/ar/dxlandingsim`
            : `${url}/dxlandingsim`;

        return axios.get(newSimURL).then((response) => {
                return response.data.data;
            });
    },

    extraFeatures() {
        const extraFeatureURL = isArabic
            ? `${url}/ar/dxlandingfeatures`
            : `${url}/dxlandingfeatures`;

        return axios.get(extraFeatureURL).then((response) => {
                return response.data.data;
            });
    },

    duApp() {
        const duAppURL = isArabic
            ? `${url}/ar/dxlandingappandrating`
            : `${url}/dxlandingappandrating`;

        return axios.get(duAppURL).then((response) => {
                return response.data.data;
            });
    },

    newQuickLinks() {
        // var quickLinksURL = isArabic ? 'dxlandingquicklinks_ar.json' : 'dxlandingquicklinks.json';
        const quickLinksURL = isArabic
            ? url + "/ar/dxlandingnewquicklinks"
            : url + "/dxlandingnewquicklinks";

        return axios.get(quickLinksURL).then((response) => {
                return response.data.data;
            });
    },

    helpData() {
        var helpDataURL = isArabic
            ? url + "/ar/dxlandinghelpsupport"
            : url + "/dxlandinghelpsupport";
        return axios.get(helpDataURL).then((response) => {
            return response.data.data;
        });
    },

    footerData() {
        var footerDataURL = isArabic
            ? url + "/ar/dxlandingfooter"
            : url + "/dxlandingfooter";
        return axios.get(footerDataURL).then((response) => {
            return response.data;
        });
    },

    /*shopCards() {
        var shopCardsURL = isArabic
            ? url + "ar/dxlandingdeviceplancards"
            : url + "dxlandingdeviceplancards";
        return axios.get(shopCardsURL).then((response) => {
            return response.data.data;
        });
    },

    planSuggestion() {
        var planSuggestionURL = isArabic
            ? url + "ar/dxlandingplans"
            : url + "dxlandingplans";
        return axios.get(planSuggestionURL).then((response) => {
            return response.data.data;
        });
    },

    bannerData() {
        var bannerDataURL = isArabic
            ? url + "ar/dxlandingbanner"
            : url + "dxlandingbanner";
        return axios.get(bannerDataURL).then((response) => {
            return response.data.data;
        });
    },

    customerLinksData() {
        var customerLinksDataURL = isArabic
            ? url + "ar/dxlandingcustomerlinks"
            : url + "dxlandingcustomerlinks";
        return axios.get(customerLinksDataURL).then((response) => {
            return response.data.data;
        });
    },

    addLifeData() {
        var addLifeDataURL = isArabic
            ? url + "ar/dxlandingaddlife"
            : url + "dxlandingaddlife";
        return axios.get(addLifeDataURL).then((response) => {
            return response.data.data;
        });
    },

    reviewData() {
        let _reviewsURL = 'https://www.du.ae/servlet/duaediscovery/common/images/positive-review/reviews.json'
        if (isArabic) {
            _reviewsURL = 'https://www.du.ae/servlet/duaediscovery/common/images/positive-review/reviews-ar.json'
        }
        return axios.get(_reviewsURL).then(response => {
            return response.data;
        })
    },*/
};