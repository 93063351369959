<template>
    <section class="topicView grey-bg">
        <div class="topicView-inner">
            <div class="grid-container narrow">
                <div class="grid-x ">
                    <div class="cell small-12" v-if="getArticle.name && content">
                        <div class="breadcrumb">
                            <ul class="breadcrumb-list">
                                <li class="breadcrumb-item"><a :href="content.helpURL" class="link du-magenta">{{this.helpText}}</a></li>
                                <li class="breadcrumb-item"><a :href="content.topicsURL+getArticle.topicBreadcrumb[0].topic[0].id+content.language" class="link du-magenta">{{getArticle.topicBreadcrumb[0].topic[0].name}}</a></li>
                                <li class="breadcrumb-item"><a>{{getArticle.name}}</a></li>
                            </ul>
                        </div>
                      <div class="grid-x gutter-20">
                      <div class="cell medium-8">
                        <div class="align-center card">
                          <p class="headline3">{{getArticle.name}}</p>
                          <div class="row20"></div>
                          <div class="topicView-content" v-html="getContent(getArticle.content)"></div>

                        </div>
                        <div class="feedback">
                          <div class="grid-container narrow">
                            <div class="grid-x gutter-20">
                              <div class="action-grid cell " :class="chatBotAllowed ? 'large-8': 'large-12'">
                                <div class="grid-x gutter-20">
                                  <div class="cell small-12 large-7">
                                    <h5 class="subtitle1">{{answerTitle }}</h5>
                                    <div class="ctas">
                                      <span>{{thumbsUpCount}} {{thumbsText}}</span>
                                      <a href="#" @click.prevent="articleThumbsup($event)" class="icon icon-like"><img alt="like" :src="require('../../assets/icons/icon-like.png')"> </a>

                                      <a href="#" @click.prevent="articleThumbsdown($event)" class="icon icon-unlike"><img alt="unlike" :src="require('../../assets/icons/icon-unlike.png')"></a>
                                    </div>
                                    <div :class="{hide: !feedbackForm.isVisible}">
                                      <div class="feedback-form" :class="{hide: !feedbackForm.isForm}">
                                        <form name="fdForm" method="post" >
                                          <p class="body1">{{feedbackForm.title}}</p>
                                          <fieldset class="large-5 cell card">
                                            <h5 class="subtitle1">{{reasonTitleTxt}}</h5>
                                            <div class="card-list" v-for="(radioValue, index) in feedbackReason" :key="index">
                                              <input  type="radio" name="radioValue" @change="radioSelect(index)" :value="index" :id="'reason'+index" required>
                                              <label :for="'reason'+index">{{radioValue}}</label>
                                            </div>
                                            <div class="group" :class="showTextArea?'':'hide'">
                                              <textarea  v-model="message" type="text" @input="detailReason($event.target.value)" name="otherFeedback" class="control txt1" id="formfeedbackid" placeholder="" value="" required></textarea>
                                              <!--                              <label>{{ this.feedbackForm.fieldLabel }}</label>-->
                                              <p :class="{help: true, 'text-danger': remaining==0}">{{instruction}}</p>
                                            </div>
                                          </fieldset>
                                          <fieldset class="group action" v-if="feedbackForm.isForm">
                                            <input name="submitFeedback" class="btn btn-filled btn-sm" :class="formIsValid ? '':'disabled'" @click="submitFeedback()" type="button" :value="feedbackForm.btnText">
                                          </fieldset>
                                          <div class="cell small-12" v-else>
                                            <div class="text-center lds-ring-container">
                                              <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
                                            </div>
                                          </div>
                                        </form>
                                      </div>
                                      <div class="thankyou-msg" :class="{hide: feedbackForm.isForm}" v-if="!showLoader">
                                        <p class="headline5">{{ feedbackForm.successMsg }}</p>
                                      </div>
                                      <div class="cell small-12" v-else>
                                        <div class="text-center lds-ring-container" style="min-height: 50px">
                                          <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="cell small-12 large-5">
                                    <h5 class="subtitle1">{{ shareTitle }}</h5>
                                    <div class="ctas share-this">
                                      <a href="#" @click.prevent="shareThis()" class="icon small icon-link"><img alt="like" :src="require('../../assets/icons/icon-share.png')"></a>
                                      <a href="#" @click.prevent="sharefb()" class="icon small icon-facebook"><img alt="facebook" :src="require('../../assets/icons/icon-fb.png')"></a>
                                      <a href="#" @click.prevent="sharetw()" class="icon small icon-twitter"><img alt="twitter" :src="require('../../assets/icons/icon-tw.png')"></a>
                                      <a href="#" @click.prevent="sharewhatsapp()" class="icon small icon-whatsapp hide-for-medium" ><img alt="whatsapp" :src="require('../../assets/icons/icon-whatsapp.png')"></a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="cell large-4 chat-card" :class="isRtl ? 'pl0':'pr0'"  v-if="chatBotAllowed">
                                <div class="card" style="margin-bottom:0 !important">
                                  <h5 class="subtitle3 margin-bottom-10">{{contactTitle }}</h5>
                                  <button type="button" class="btn border btn-sm" style="display: inline-block" @click="showChatbot()">{{chatBoxBtnTxt}}</button>
                                </div>
                              </div>
                            </div>
                          </div>
<!--                                        <div class="grid-container narrow">-->
<!--                                          <div class="success-confirm" :class="{hide: thumbsupSuccess == false}">-->
<!--                                            <div class="grid-x">-->
<!--                                              <div class="cell">-->
<!--                                                {{ this.successText }}-->
<!--                                              </div>-->
<!--                                            </div>-->
<!--                                          </div>-->
<!--                                          <div class="success-confirm" :class="{hide: !this.feedbackForm.isVisible}">-->
<!--                                            <div class="grid-x">-->
<!--                                              <div class="cell">-->
<!--                                                <p class="headline5">{{ this.contactTitle }}</p>-->
<!--                                                <a onclick="dataLayer.push({ 'event':'eventTracker', 'eventCategory':'help_support', 'eventAction':'Contact Us Thumbs Up', 'eventLabel': articleId })" href="http://www.du.ae/personal/helpandsupport/contact-us" class="contact"><span>{{ this.contactText }}</span></a>-->
<!--                                              </div>-->
<!--                                            </div>-->
<!--                                          </div>-->
<!--                                        </div>-->
                        </div>
                      </div>
                      <div class="cell medium-4 sidebar">
                        <div class="align-center" v-if="id=='PROD-72951' || id=='PROD-41420'">
                          <TroubleTicketForm :isSideBar="true" />
                        </div>
                        <div class="card" v-if="quickPayAllowed">
                          <UsefulLinks :title="title" :description="description" :icon="icon" :button="button" :link="link" />
                        </div>
                        <div class="card" v-if="!contextualHelp">
                          <p class="subtitle1 margin-bottom-10">{{relatedArticlesTitle}}</p>
                          <ul class="article-list" v-if="getRelatedArticles">
                            <li v-for="(item, i) in relatedArtLength" :key="i">
                              <a :href="content.articleURL+item.alternateId+content.language"> {{item.name}}
                                <img class="chevron" src="https://www.du.ae/servlet/duaediscovery/common/km/common/static/helpandsupport/img/chevron-right.svg" />
                              </a>
                            </li>
                          </ul>
                        </div>


                      </div>
                      </div>
                    </div>
                    <div class="cell small-12" v-else>
                        <div class="text-center lds-ring-container">
                            <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
      <div id="rdbothelpBox" data-toggler=".open" class="rdhelp">
        <span class="closeChat" @click="closeChat()"></span>
        <iframe sandbox="allow-modals allow-same-origin allow-scripts allow-popups allow-forms" name="webchat"
                id="webchat" class="webchat" marginwidth="0" marginheight="0" scrolling="No" frameborder="0" hspace="0"
                vspace="0" src="https://www.du.ae/servlet/duaediscovery/common/build/index.html?locale=en"></iframe>
      </div>
      <div class="survey-iframe" id="survey-form" style="display:none">
        <div class="iframe-container">
          <button class="close-button" @click="closeSurvey()" data-close aria-label="Close reveal" type="button">
            <span aria-hidden="true">&times;</span>
          </button>
          <iframe v-if="showSurvey" :src="'https://surveys.du.ae/EFM/se/38D19C33097F2238?source='+this.isMobile+'-du.ae-'+isBusiness+'-Support-ThumbsUp-Article-'+this.id" ></iframe>
        </div>
      </div>
    </section>
</template>

<script>
import $ from 'jquery';
import apiHelp from '../../api-help'
import { Analytics } from "@/analytics.js"
import axios from "axios";
import TroubleTicketForm from "@/components/HelpSupport/TroubleTicketForm";
import UsefulLinks from "@/components/UsefulLinksBox";

const ChatBotArticles = [
  "PROD-38881",
  "PROD-68615",
  "PROD-50257",
  "PROD-56462",
  "PROD-56459",
  "PROD-65453",
  "PROD-47334",
  "PROD-78501",
  "PROD-78502",
  "PROD-78503",
  "PROD-2678",
  "PROD-69262",
  "PROD-73379",
  "PROD-16742",
  "PROD-85545",
  "PROD-68620",
  "PROD-72951",
  "PROD-81282",
  "PROD-85198",
  "PROD-85199",
  "PROD-85200",
  "PROD-85201"
]
const quickPay = ["PROD-65453",
  "PROD-47334",
  "PROD-78501",
  "PROD-78502",
  "PROD-78501",
    ]

export default {
    name: 'ArticleDetails',
  components: {
      TroubleTicketForm,
      UsefulLinks
  },
  props: ['content'],
    data() {
        return {
          showSurvey: false,
          id: new URL(location.href).searchParams.get('artid'),
          contextualHelp: new URL(location.href).searchParams.get('context') === "help",
          getArticle: [],
          subTopics: [],
          getRelatedArticles: [],
          getArticleName: "",
          thumbsUpCount:0,
          isActive: 0,
          thumbsText: document.getElementsByTagName('html')[0].getAttribute('dir') === 'rtl' ? "وجدوا ذلك مفيداً" : "found this useful",
          helpText: document.getElementsByTagName('html')[0].getAttribute('dir') === 'rtl' ? "المساعدة" : "Help",
          successText: document.getElementsByTagName('html')[0].getAttribute('dir') === 'rtl' ? "نشكرك على ملاحظاتك" : "Thank you for your feedback.",
          answerTitle: document.getElementsByTagName('html')[0].getAttribute('dir') === 'rtl' ? "هل كان هذا مفيدا؟" : "Was this article helpful?",
          shareTitle: document.getElementsByTagName('html')[0].getAttribute('dir') === 'rtl' ? "المشاركة" : "Share via",
          thumbsupSuccess: false,
          chatBoxBtnTxt: document.getElementsByTagName('html')[0].getAttribute('dir') === 'rtl' ? "دردش مع بلو" : "Chat with Blu",
          contactTitle: document.getElementsByTagName('html')[0].getAttribute('dir') === 'rtl' ? "لا زلت بحاجة الى مساعدة؟" : "Need further assistance?",
          contactText: document.getElementsByTagName('html')[0].getAttribute('dir') === 'rtl' ? "اتصل بنا" : "Contact us",
          articleId:"",
          isMobile: window.matchMedia("only screen and (max-width: 767px)").matches ? 'mobile':'desktop',
          isRtl : document.getElementsByTagName('html')[0].getAttribute('dir') === 'rtl',
          isBusiness : location.href.toLowerCase().indexOf("business" || "businesstest") > -1?'Business':'Personal',
          showLoader:false,
          reasonTitleTxt: document.getElementsByTagName('html')[0].getAttribute('dir') === 'rtl' ? "أخبرنا ما الذي يجب فعله لتحسين الجواب":"Why wasn't this article helpful?",
          relatedArticlesTitle: document.getElementsByTagName('html')[0].getAttribute('dir') === 'rtl' ? "مقالات ذات صلة":"Related Articles",
          feedbackForm: {
            isVisible: false,
            isForm: true,
            title: document.getElementsByTagName('html')[0].getAttribute('dir') === 'rtl' ? "!نعتذر كون المقال لم يساعدك" : "We are sorry to hear that. Please tell us how to improve this page.",
            fieldLabel: document.getElementsByTagName('html')[0].getAttribute('dir') === 'rtl' ? "أخبرنا ما الذي يجب فعله لتحسين الجواب" : "We are sorry that you didn't find the article helpful. Please tell us how we can improve it. Write your feedback here.",
            btnText: document.getElementsByTagName('html')[0].getAttribute('dir') === 'rtl' ? "أرسل رأيك" : "Submit feedback",
            successMsg: document.getElementsByTagName('html')[0].getAttribute('dir') === 'rtl' ? "شكراً. تم أرسال اقتراحك" : "Thanks for your feedback. We’ll start looking into it.",
          },
          feedbackReason: document.getElementsByTagName('html')[0].getAttribute('dir') === 'rtl' ?
              [
                "المعلومات غير كافية",
                "لم أجد ما أبحث عنه",
                "لم أفهم المقال",
                "لم أجد حل لمشكلتي",
                "سبب آخر"
              ]:[
              "Not enough information.",
              "This isn't what I was looking for.",
              "The article is confusing.",
              "The article didn't solve the problem.",
              "Other"
            ],
          formIsValid: false,
          showTextArea: false,
          limit: 100,
          message:'',
          title:document.getElementsByTagName('html')[0].getAttribute('dir') === 'rtl' ?"الدفع السريع":"Quick Pay",
          description:document.getElementsByTagName('html')[0].getAttribute('dir') === 'rtl' ?"قم بدفع فواتيرك للدفع الآجل بآمان دون تسجيل الدخول.":"Pay your postpaid bills securely with no login.",
          icon:"quickPay.svg",
          button:document.getElementsByTagName('html')[0].getAttribute('dir') === 'rtl' ?"دفع الفاتورة":"Pay bill",
          chatBotAllowed: false,
          quickPayAllowed: false,
          link: document.getElementsByTagName('html')[0].getAttribute('dir') === 'rtl' ? "https://myaccount.du.ae/webapp/ar/quick-pay":"https://myaccount.du.ae/webapp/en/quick-pay"
        }
    },

    mounted() {

        if (this.id) {
          apiHelp.getArticle(this.id).then(res => {
            this.getArticle = res.article[0]
            this.getArticleName = res.article[0].name
            apiHelp.getSearchResult(this.getArticleName).then(response => {
              this.getRelatedArticles = response;
            });
          })
            var _this = this;
            setTimeout(function () {
              _this.responsiveTable();
            }, 500)
          }

        apiHelp.positiveCount().then(res => {
          res.forEach(id => {
            if(this.id === id.prod_article_id){
              this.thumbsUpCount = id.count
            }
          })
        })
        ChatBotArticles.forEach((item) => {
          if(item === this.id){
            this.chatBotAllowed = true
          }
      })
      quickPay.forEach((item) => {
        if(item === this.id){
          this.quickPayAllowed = true
        }
      })

      console.log('RTL', this.isRtl)


    },
  computed:{
    instructionEn: function(){
      return this.message===''?'Describe your feedback in '+this.limit+' characters': 'remaining '+this.remaining+' characters'
    },
    instructionAr: function() {
       return this.message===''? 'ساعدنا في معرفة ذلك في '+this.limit+' حرف': this.remaining+' حرف متبقي'
    },
    instruction: function() {
      return this.isRtl ? this.instructionAr : this.instructionEn
    },
    remaining: function() {
      return this.limit-this.message.length;
    },
    relatedArtLength() {
      if(this.getRelatedArticles.article){
        return this.getRelatedArticles.article.slice(1, 6)
      }
      return false

    }
  },
    methods: {
      sendGA(eventCategory, eventAction, eventLabel, eventValue) {
        Analytics(eventCategory, eventAction, eventLabel, eventValue);
      },
      getContent(content) {
        return content.split('[profile:&lt;Consumer&gt;]').pop().split('&lt;/profile&gt;')[0].split('[profile:&lt;chatbot&gt;]')[0].split('[profile:&lt;Consumer&gt;,&lt;Enterprise&gt;]').pop()
      },
      getArticleid() {
        return this.id;
      },
      getLocale() {
        let isArabic = document.getElementsByTagName('html')[0].getAttribute('dir') === 'rtl';
        return isArabic ? "ar-SA" : "en-US";
      },
      getPortalid() {
        let isArabic = document.getElementsByTagName('html')[0].getAttribute('dir') === 'rtl';
        let portalID = "201600000001018";
        let portalID2 = "201600000001020";
        return isArabic ? portalID2 : portalID;
      },
      closeSurvey() {
        var surveyForm = document.getElementById("survey-form");
        surveyForm.style.display = "none";

      },
      articleThumbsup(e) {

        var _data = {
          'event': 'eventTracker',
          'eventCategory': 'Help & Support',
          'eventAction': 'Useful Icons – Thumbs Up',
          'eventLabel': this.id
        };

        if (typeof window.dataLayer != "undefined") {
          window.dataLayer.push(_data);
        }
        var surveyForm = document.getElementById("survey-form");
        this.showSurvey = true;
        surveyForm.style.display = "flex";
        if (e.currentTarget.classList.contains('disabled') || e.currentTarget.classList.contains('active')) {
          return false;
        }
        let varLocale = this.getLocale();
        e.currentTarget.classList.add('active')

        this.sendGA('help_support', 'article_detail', 'helpful-' + this.getArticleid(), 0);
        document.querySelector('.icon-unlike').classList.add('disabled');

        axios.put("https://help.du.ae/system/ws/v11/ss/article/rating/" + this.getArticleid() + "?$lang=" + varLocale + '&usertype=customer&score=1&portalId=' + this.getPortalid(), {
          'portalId': this.getPortalid(),
          'score': 1,
          'usertype': 'customer'
        }, {
          headers: {
            'X-egain-session': this.getCookie('egainsessionmain')
          }
        }).then(() => {
          // console.log('success'+ response);
          this.thumbsupSuccess = true;
        })
            .catch((error) => {
              console.log('something went wrong in thumbs up article: ' + error);
            })


      },
      radioSelect(e) {
        const totalRadioBtns = this.feedbackReason.length
        const selectValue = e + 1
        if (totalRadioBtns == selectValue) {
          this.showTextArea = true
          this.formIsValid = false
        } else {
          this.showTextArea = false
          this.formIsValid = true
        }

      },
      detailReason(value) {
        this.message = this.message.substr(0, this.limit)
        this.formIsValid = false
        if (value.length > 10) {
          this.formIsValid = true
        }
      },
      articleThumbsdown(e) {
        var _data = {
          'event': 'eventTracker',
          'eventCategory': 'Help & Support',
          'eventAction': 'Useful Icons – Thumbs Down',
          'eventLabel': this.id
        };

        if (typeof window.dataLayer != "undefined") {
          window.dataLayer.push(_data);
        }
        if (e.currentTarget.classList.contains('active')) {
          return false;
        }
        let varLocale = this.getLocale();
        e.currentTarget.classList.add('active')

        this.sendGA('help_support', 'article_detail', 'unhelpful-' + this.getArticleid(), 0);


        axios.put("https://help.du.ae/system/ws/v11/ss/article/rating/" + this.getArticleid() + "?$lang=" + varLocale + "&usertype=customer&score=0&portalId=" + this.getPortalid(), {
          'portalId': this.getPortalid(),
          'score': 0,
          'usertype': 'customer'
        }, {
          headers: {
            'X-egain-session': this.getCookie('egainsessionmain')
            //'Access-Control-Allow-Origin': '*'
          }
        }).then(() => {
          // console.log('success'+ response);

          this.feedbackForm.isVisible = true;
          this.thumbsupSuccess = false;
          let iconLike = document.querySelector('.icon-like');
          if (!iconLike.classList.contains('active')) {
            document.querySelector('.icon-like').classList.add('disabled');
          }

          setTimeout(function () {
            var $form = document.querySelector('.feedback-form');
            $form.querySelector('.txt1').focus();
          }, 50);


        })
            .catch((error) => {
              console.log('something went wrong in thumbs down article: ' + error);
            })

      },
      submitFeedback() {
        this.showLoader = true
        const selectedReason = document.querySelector('input[name="radioValue"]:checked').nextElementSibling.innerText
        const feedbackDetails = document.getElementById('formfeedbackid').value



        const artFeedback = selectedReason +' - '+ feedbackDetails
        console.log(artFeedback)
        this.articleSuggestMain(artFeedback);
        var _data = {
          'event': 'eventTracker',
          'eventCategory': 'Help & Support',
          'eventAction': 'Send Feedback Thumbs Up',
          'eventLabel': this.id - document.getElementById('formfeedbackid').value
        };

        if (typeof window.dataLayer != "undefined") {
          window.dataLayer.push(_data);
        }

      },
      articleSuggestMain(artfeedback) {

        axios.get("https://help.du.ae/system/ws/v11/ss/article/" + this.getArticleid() + "?portalId=" + this.getPortalid() + "&usertype=customer", {
          'portalId': this.getPortalid(),
          'score': 0,
          'usertype': 'customer'
        }, {
          headers: {
            'X-egain-session': this.getCookie('egainsessionmain')
            //'Access-Control-Allow-Origin': '*'
          }
        }).then((response) => {
          this.articleSuggest(response.data.article[0].name, artfeedback);
        })
            .catch((error) => {
              console.log('something screwed up in suggest article: ' + error);
            })
      },
      articleSuggest(artname, artfeedback) {
        //console.log(artname,artfeedback);

        axios.post("https://help.du.ae/system/ws/v11/ss/suggestion?portalId=" + this.getPortalid() + "&$lang=" + this.getLocale() + "&article.name=" + artname + "-" + this.getArticleid() + "&article.description=Missing Important information-Answer is incorrect or inaccurate&article.content=" + artfeedback + "&usertype=customer", {
          'portalId': this.getPortalid(),
          '$lang': this.getLocale(),
          'article.name': artname + "-" + this.getArticleid(),
          'article.description': "Missing Important information-Answer is incorrect or inaccurate",
          'article.content': artfeedback,
          'usertype': 'customer'
        }, {
          headers: {
            'Accept': 'application/json',
            'X-egain-session': this.getCookie('egainsessionmain')
            //'Access-Control-Allow-Origin': '*'
          }
        }).then(() => {
          this.feedbackForm.isForm = false;
          this.showLoader = false
        })
            .catch((error) => {
              console.log('something screwed up in recently viewed article: ' + error);
            })

      },
      shareThis() {
        var _data = {
          'event': 'eventTracker',
          'eventCategory': 'Help & Support',
          'eventAction': 'Useful Icons – Thumbs Up',
          'eventLabel': 'copylink - ' + this.id
        };

        if (typeof window.dataLayer != "undefined") {
          window.dataLayer.push(_data);
        }
        this.sendGA('help_support', 'share', 'share_article', 0);
        navigator.clipboard.writeText(window.location.href);
      },
      sharefb() {
        var _data = {
          'event': 'eventTracker',
          'eventCategory': 'Help & Support',
          'eventAction': 'Useful Icons – Thumbs Up',
          'eventLabel': 'Facebook - ' + this.id
        };

        if (typeof window.dataLayer != "undefined") {
          window.dataLayer.push(_data);
        }
        this.sendGA('help_support', 'home', 'facebook', 0);
        var url = "https://www.facebook.com/sharer/sharer.php?u=" + window.location.href;
        window.open(url, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600');
      },
      sharetw() {
        var _data = {
          'event': 'eventTracker',
          'eventCategory': 'Help & Support',
          'eventAction': 'Useful Icons – Thumbs Up',
          'eventLabel': 'Twitter - ' + this.id
        };

        if (typeof window.dataLayer != "undefined") {
          window.dataLayer.push(_data);
        }
        this.sendGA('help_support', 'home', 'twitter', 0);
        var url = "https://twitter.com/intent/tweet?url=" + window.location.href + "&text='Checkout this article!";
        window.open(url, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600');
      },
      sharewhatsapp() {
        this.sendGA('help_support', 'home', 'whatsapp', 0);
        var url = "whatsapp://send?text=Checkout this article! " + window.location.href;
        window.open(url, '_blank');
      },
      getCookie(cname) {
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for (var i = 0; i < ca.length; i++) {
          var c = ca[i];
          while (c.charAt(0) == ' ') {
            c = c.substring(1);
          }
          if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
          }
        }
        return "";
      },

      setCookie(cname, cvalue) {
        var d = new Date();
        d.setTime(d.getTime() + (5 * 60 * 1000));
        var expires = "expires=" + d.toGMTString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
      },

      responsiveTable() {
        var $table = $('table');

        if (!$table.closest('.table-resp').length) {//adding wrapper
          $table.wrap('<div class="table-wrapper"><div class="table-resp"></div></div>');
        }
      },
      showChatbot() {
        var _id = document.getElementById('rdbothelpBox');
        if ( _id.classList.contains('open') ) {
          _id.classList.remove('open');
        } else {
          _id.classList.add('open');
        }
      },
      closeChat(){
        var _id = document.getElementById('rdbothelpBox');
        _id.classList.remove('open');
      }
    }
}
</script>
<style lang="scss">
.topicView {
  &.bg-grey{
    background: #F8F8F8
  }
  .gutter-20{
    margin-left: -20px;
    margin-right: -20px;
    .cell{
      padding-left: 20px;
      padding-right: 20px;
    }
  }
  .sidebar{
    .card{
      margin-bottom: 30px;
      ul{
        padding: 0 !important;
        &.article-list li a{
          padding: 15px 0;
          font-size: 15px;
        }
      }

    }
  }
  .card {
    ul:not(.article-list) {
      [dir="ltr"] & {
        padding-left: 20px;
      }

      [dir="rtl"] & {
        padding-right: 20px;
      }
    }
  }
    .grid-container {
        display: flex;
        position: relative;
    }



    &-content {
        img {
            width: auto !important;
            height: auto !important;
            max-width: 100%;
        }

        a,
        a>span {
            color: $primary-action-color !important;
            transition: .3s ease-out;

            &:hover {
                text-decoration: underline;
            }
        }

        ul, li {
            list-style: revert;
            [dir="ltr"] & {
                padding-left: revert;
            }

            [dir="rtl"] & {
               padding-right: revert;
            }
        }

        table {
            border: 0px;
            display: inline-block;
            vertical-align: top;
            margin-bottom: 20px;
            height: 100% !important;

            [dir="ltr"] & {
                margin-right: 60px;
            }

            [dir="rtl"] & {
                margin-left: 60px;
            }

            &:last-of-type {
                [dir="ltr"] & {
                    margin-right: 0px;
                }

                [dir="rtl"] & {
                    margin-left: 0px;
                }
            }

            td {
                border: 1px solid $du-border-color;
                padding: 20px 15px;
            }
        }

      .table-wrapper {
        position: relative;

        .table-resp {
          overflow-x: auto;
        }

        .table-resp {
          tbody tr {
            background: rgba(0, 169, 206, 0.05);

            &:nth-child(even) {
              background: rgba(0, 169, 206, 0.1);
            }
          }
          thead {
            background: transparent;
          }

        }

        table {
          border-collapse: inherit;
          border-spacing: 0;
          margin: 0;
          background-color: #fff;
          max-width: 80%;
          min-width: 80% !important;
          width: 100% !important;
          margin-bottom: 20px;
          @media screen and (max-width: 1023px){
            width: 100% !important;
            background: transparent;
          }

          thead, tbody {
            @media screen and (max-width: 1023px){
              border: 0;
              background: transparent;
            }
          }

          thead {
            th {
              border-bottom: 1px solid $du-border-color;
              font-size: 16px;
              font-family: $du-font-secondary-bold;
              padding: 1rem 10px;
              vertical-align: top;
              color: #777;
              @media screen and (min-width: 1024px){
                padding: 1rem 10px;
              }
              &:first-of-type {
                @media screen and (min-width: 1024px){
                  [dir="ltr"] & {
                    padding-left: 1.5rem;
                  }
                  [dir="rtl"] & {
                    padding-right: 1.5rem;
                  }
                }
              }
              &:last-of-type {
                @media screen and (min-width: 1024px){
                  [dir="ltr"] & {
                    padding-left: 1.5rem;
                  }
                  [dir="rtl"] & {
                    padding-right: 1.5rem;
                  }
                }
              }
            }
          }

          tbody {
            tr {
              &:first-of-type {
                td, th {
                  border-top: 0;
                }
              }
            }
            th {
              color: #777;
              font-family: $du-font-secondary-bold;
            }
            td, th {
              border-top: 1px solid $du-border-color;
              font-size: 16px;
              font-family: $du-font-secondary-regular;
              padding: 1rem 10px;
              border-left: 0;
              border-right: 0;
              border-bottom: 0;
              width: auto !important;
              [dir="ltr"] & {
                text-align: left;
              }
              [dir="rtl"] & {
                text-align: right;
              }
              vertical-align: top;
              min-width: 100px;

              &:first-child {
                [dir="ltr"] & {
                  padding-left: 1.5rem;
                }
                [dir="rtl"] & {
                  padding-right: 1.5rem;
                }
              }

              &:last-child {
                [dir="ltr"] & {
                  padding-left: 1.5rem;
                }
                [dir="rtl"] & {
                  padding-right: 1.5rem;
                }
              }

            }
          }


        }


      }
    }

  .feedback {
    //background: #f7f7f7;
    padding: 0;
    max-width: 1266px;
    margin: 24px auto 0;
    .grid-container {
    }
    .chat-card{
      @media only screen and (max-width: $phone-max-size) {
        padding: 0 !important;
        margin-top:20px;
      }
    }
    .feedback-inner {
      border-bottom: #ddd solid 1px;
      width: 100%;
      padding: 1.25rem;
      @media only screen and (min-width: $phone-max-size) {
        padding: 1.5rem;
      }
    }
    .headline5 {
      margin-bottom: 10px;
    }
    .action-grid {
      background: #eee;
      border-radius: 6px;
      padding: 20px;
    }
    .success-confirm {
      padding: 1.25rem;
      @media only screen and (min-width: $phone-max-size) {
        padding: 1.5rem;
      }

      .contact {
        display: block;
        position: relative;
        padding: 15px 20px;
        padding-right: 50px;
        border: #00A9CE solid 1px;
        border-radius: 12px;
        background: #fff;
        font-family: $du-font-secondary-regular;
        font-size: 1rem;
        line-height: 1em;
        color: #00A9CE;
      }
    }

    .ctas {
      display: flex;
      align-items: center;
      span{
        margin-right: 10px;
        [dir="rtl"] & {
          margin-right: 0;
          margin-left:10px;
        }
      }
      a {
        display: inline-block;
        vertical-align: top;
        padding: 0;
        &.disabled img {
          opacity: .4;
        }

      }
      img {
        max-width: 40px;
        margin-right: 10px;
        border-radius: 50%;
        [dir="rtl"] & {
          margin-right: 0;
          margin-left: 10px;
        }
        small{
          display: block;
          text-align: center;
          padding-right: 10px;
          margin-top: 3px;
          color: #333;
        }
      }
    }

    .feedback-form {
      padding: 30px 0;
      min-width: 400px;
      p.body1 {
        font-size: 1rem;
        margin-bottom: 10px;
      }
      .group {
        position: relative;
        border: 0;
        padding: 0;
        margin: 20px 0 0;
        textarea {
          border: 1px solid #ddd;
          border-radius: 4px;
          width: 100%;
          min-height: 100px;
          padding: .5rem;
          :focus{
            border-color: #00A9CE
          }
        }
        label {
          color: #333;
          font-size: 18px;
          position: absolute;
          top: 9px;
          left: 10px;
          [dir="rtl"] & {
            left: auto;
            right: 10px;
          }
          transition: all .2s ease-in;
        }
        textarea:focus ~ label, textarea:valid ~ label {
          top: -20px;
          font-size: 14px;
          color: #09c;
          left: 0;
          [dir="rtl"] & {
            left: auto;
            right: 0;
          }
        }
        .btn {
          border: 0;
          cursor: pointer;
          &.disabled {
            opacity: 0.4;
          }
        }

      }
    }
    .thankyou-msg {
      padding-top: 20px;
      border-top: 1px solid #ddd;
      margin-top: 20px;
    }
  }
  .survey-iframe{
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    justify-content:center;
    align-items: center;
    background: rgba(0,0,0,0.8);
    z-index: 9999;
    .iframe-container{
      //max-width:900px;
      //height: auto;
      position: relative;
      overflow: hidden;
      max-width: 900px;
      width: 100%;
      height: auto;
      min-height: 660px;
      //padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */

      button.close-button {
        top: 0;
        position: absolute;
        right: 0;
        z-index: 9;
        span {
          margin: 6px;
          font-size: 33px;
        }
      }
      iframe{
        //width:900px;
        //height: 520px;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        border: 0;
        border-radius: 10px;
        //@media only screen and (max-width: $phone-max-size) {
        //  width:360px;
        //  height: 520px;
        //}
      }
    }


  }

}
</style>